import { useMutation, useQuery } from '@apollo/client';
import {
	Dispatch,
	FC,
	SetStateAction,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { AppContext } from '../../App';
import { ContainerView } from '../../components/Container/Container.view';
import { COLORS } from '../../components/styleguide/colors';
import { TypoHeadline3 } from '../../components/styleguide/typography';
import { ROUTER_PATH } from '../../router/routes';
import { OverviewOfferInfo } from './components/OverviewOfferInfo/OverviewOfferInfo';
import { OverviewOrderButton } from './components/OverviewOrderButton.tsx/OverviewOrderButton';
import { OverviewProductGroup } from './components/OverviewProductGroup/OverviewProductGroup';
import { OverviewTotalPayment } from './components/OverviewTotalPayment/OverviewTotalPayment';
import {
	GET_OFFER_QUOTE_MUTATION,
	IGetOfferQuoteMutationResponse,
	IOfferQuoteMutationResponse,
} from '../../graphql/mutations/offer';
import { Spinner } from '../../components/Spinner/Spinner';
import { ReactComponent as PlusIcon } from '../../assets/icons/round-plus.svg';
import { OverviewPageHeader } from './components/OverviewPageHeader/OverviewPageHeader';
import { useTranslation } from 'react-i18next';
import { LANG } from '../../models/ILang';
import { useCheckout } from '../../hooks/useCheckout';
import { useCheckSession } from '../../hooks/useCheckSession';
import {
	IGetCustomerQueryResponse,
	GET_CUSTOMER_QUERY,
	IAddressesQueryResponse,
} from '../../graphql/queries/customer';
import { IBillingState, TAddressForm } from '../../models/IUser';
import {
	GET_CUSTOMER_CART,
	IGetCustomerCartQueryResponse,
} from '../../graphql/queries/cart';

export const OverviewContainerView = styled(ContainerView)`
	max-width: 800px;

	.overview__shop-more-container {
		padding-top: 35px;
		padding-bottom: 60px;
		border-top: 1.5px solid ${COLORS.disabledColor};

		& > div {
			width: fit-content;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 15px;
			text-transform: uppercase;
			cursor: pointer;
		}
	}
`;

interface IOverviewPageContext {
	billing: IBillingState;
	setBilling: Dispatch<SetStateAction<IBillingState>>;
	email?: string;
}

export const OverviewPageContext = createContext<IOverviewPageContext>(
	{} as IOverviewPageContext,
);

export const OverviewPage: FC = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { t, i18n } = useTranslation();
	const current_lang = i18n.language as LANG;
	const { isLogged, requestedDeliveryDate } = useContext(AppContext);
	const checkout = useCheckout();
	const checkSession = useCheckSession();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [billing, setBilling] = useState<IBillingState>({
		billingAddress: {
			default_billing: true,
			default_shipping: false,
			firstname: '',
			lastname: '',
			company: '',
			department: '',
			street: '',
			postcode: '',
			city: '',
			country_code: '',
			email: '',
		},
		deliveryAddress: {
			default_billing: false,
			default_shipping: true,
			firstname: '',
			lastname: '',
			company: '',
			department: '',
			street: '',
			postcode: '',
			city: '',
			country_code: '',
			email: '',
		},
		addressMatch: true,
		poNumber: '',
	});

	const userQuery = useQuery<IGetCustomerQueryResponse>(GET_CUSTOMER_QUERY, {
		fetchPolicy: 'cache-and-network',
		onCompleted(data) {
			const customer = data.customer;
			const addresses = data.customer.addresses;
			const defaultBillingAddress = addresses.find(
				item => item.default_billing,
			);
			const defaultShippingAddress = addresses.find(
				item => item.default_shipping,
			);
			const getAddress = (
				address: IAddressesQueryResponse | undefined,
			): TAddressForm => {
				return address
					? {
							...address,
							street: address.street[0],
							email: customer.email,
						}
					: billing.billingAddress;
			};

			setBilling(b => ({
				...b,
				billingAddress: getAddress(defaultBillingAddress),
				deliveryAddress: getAddress(defaultShippingAddress),
			}));
		},
	});

	const offerId = searchParams.get('offer');
	const isFullOverview = searchParams.get('type');

	const cartQuery = useQuery<IGetCustomerCartQueryResponse>(
		GET_CUSTOMER_CART,
		{
			fetchPolicy: 'cache-and-network',
			onCompleted(data) {
				setIsLoading(false);
				!data.customerCart.selected_payment_method.code &&
					!isFullOverview &&
					!offerId &&
					navigate(current_lang + ROUTER_PATH.billing);
			},
			onError(error) {
				console.error('GET_CUSTOMER_CART error:>>', error);
				setIsLoading(false);
			},
		},
	);

	const [getOfferQuoteMutation, offerQuoteQuery] =
		useMutation<IGetOfferQuoteMutationResponse>(GET_OFFER_QUOTE_MUTATION, {
			variables: { offerId },
			onError(error) {
				console.error('getOfferQuoteMutation error:>>', error);
			},
		});

	useEffect(() => {
		!isLogged && navigate(current_lang);
		offerId && getOfferQuoteMutation();
	}, [isLogged, current_lang, offerId]);

	const cart = cartQuery.data?.customerCart;
	const offerQuote = offerQuoteQuery.data?.getOfferQuote;

	const handleOfferPurchase = (offer: IOfferQuoteMutationResponse): void => {
		checkout.checkout({
			cartId: offer.cart.id,
			shippable: true,
			billing,
			requestedDeliveryDateforOrder: requestedDeliveryDate.value,
			isContractOrder: offer.is_contract_offer,
		});
	};

	const handlePurchaseButton = (): void => {
		setIsLoading(true);

		if (offerQuote) {
			checkSession(() => handleOfferPurchase(offerQuote));
			return;
		}

		if (isFullOverview === 'full') {
			checkout.checkout({
				cartId: cart?.id,
				shippable: true,
				billing,
				requestedDeliveryDateforOrder: requestedDeliveryDate.value,
			});
			return;
		}

		checkout.placeOrderOverviewPage(cart?.id, requestedDeliveryDate.value);
	};

	if (offerQuoteQuery.loading || userQuery.loading || cartQuery.loading)
		return <Spinner />;

	const items = offerQuote?.cart.items
		? offerQuote.cart.items
		: cart?.items ?? [];

	const totalPrice = offerQuote?.cart.prices
		? offerQuote.cart.prices
		: cart?.prices;

	return (
		<OverviewPageContext.Provider
			value={{
				billing,
				setBilling,
				email: userQuery.data?.customer.email,
			}}
		>
			<OverviewContainerView>
				<OverviewPageHeader offerQuote={offerQuote} />

				<OverviewProductGroup
					isLoading={isLoading}
					items={items}
					offerQuote={offerQuote}
				/>

				{!!totalPrice && (
					<OverviewTotalPayment
						items={items}
						offer={offerQuote}
						totalPrice={totalPrice}
						paymentType={''}
					/>
				)}

				{!offerId && (
					<div className="overview__shop-more-container">
						<div>
							<PlusIcon
								width={22}
								height={22}
								stroke={COLORS.primaryColor}
							/>
							<TypoHeadline3
								onClick={() =>
									navigate(
										current_lang + ROUTER_PATH.products,
									)
								}
							>
								{t(
									'order_overview_page_add_more_products_typo',
								)}
							</TypoHeadline3>
						</div>
					</div>
				)}

				{(!!offerId || !!isFullOverview) && <OverviewOfferInfo />}

				<OverviewOrderButton
					isLoading={isLoading || checkout.loading}
					disabled={
						offerQuote
							? !offerQuote.cart.items.length
							: !cart?.items.length
					}
					onClick={handlePurchaseButton}
				>
					{t('order_overview_page_order_button')}
				</OverviewOrderButton>
			</OverviewContainerView>
		</OverviewPageContext.Provider>
	);
};
