import { FC } from 'react';
import styled from 'styled-components';
import { PriceView } from '../../../../components/styleguide/typography';
import {
	ICartPricesResponse,
	ICustomerCartItemQueryResponse,
} from '../../../../models/ICart';
import { formatNumber2Price } from '../../../../utils/price';
import { useTranslation } from 'react-i18next';
import {
	IOfferQuoteItemMutationResponse,
	IOfferQuoteMutationResponse,
} from '../../../../graphql/mutations/offer';

export const OverviewTotalPaymentContainerView = styled.div`
	padding: 20px 0;
	display: grid;
	grid-template-columns: auto auto;
	justify-content: end;
	grid-column-gap: 20px;
	grid-row-gap: 15px;

	.overview__totalPayment {
		&-container {
			display: flex;
			justify-content: flex-end;
			gap: 25px;
		}

		&-cost {
			text-align: end;
		}

		&-descr {
			font-family: Arial, Helvetica, sans-serif;
			font-weight: 400;
		}

		&-type {
			margin-top: 10px;
		}
	}
`;

interface IOverviewTotalPaymentProps {
	items: ICustomerCartItemQueryResponse[] | IOfferQuoteItemMutationResponse[];
	offer?: IOfferQuoteMutationResponse;
	// offer?: IOfferQuote;
	totalPrice: ICartPricesResponse;
	paymentType: string;
}

export const OverviewTotalPayment: FC<IOverviewTotalPaymentProps> = ({
	items,
	offer,
	totalPrice,
	paymentType,
}) => {
	const { t } = useTranslation();

	const getTaxPercent = (): number => {
		let percent = 0;

		items.some(item => {
			item.prices.tax_percent ? (percent = item.prices.tax_percent) : 0;

			return !!item.prices.tax_percent;
		});

		return percent;
	};

	return (
		<OverviewTotalPaymentContainerView className="overview__totalPayment">
			<div className="overview__totalPayment-descr">
				{t('total_netto_typo')}
			</div>
			{!offer && (
				<PriceView className="overview__totalPayment-cost">
					{formatNumber2Price(totalPrice.subtotal_excluding_tax)}
				</PriceView>
			)}

			{!offer && !!items.length && (
				<>
					<div className="overview__totalPayment-descr">
						{t('vat_typo')} {getTaxPercent()}%
					</div>
					<PriceView className="overview__totalPayment-cost">
						{formatNumber2Price({
							value: totalPrice.total_tax_amount,
							currency:
								totalPrice.subtotal_excluding_tax.currency,
						})}
					</PriceView>
					<div className="overview__totalPayment-descr">
						{t('total_brutto_typo')}
					</div>
					<PriceView className="overview__totalPayment-cost">
						{formatNumber2Price(totalPrice.grand_total)}
					</PriceView>
				</>
			)}

			{!!offer && !!items.length && (
				<>
					<PriceView>
						{formatNumber2Price(
							offer.cart.prices.subtotal_excluding_tax,
						)}
					</PriceView>
					<div className="overview__totalPayment-descr">
						{t('vat_typo')} {getTaxPercent()}%
					</div>
					<PriceView className="overview__totalPayment-cost">
						{formatNumber2Price({
							value: offer.cart.prices.total_tax_amount,
							currency: offer.cart.prices.grand_total.currency,
						})}
					</PriceView>
					<div className="overview__totalPayment-descr">
						{t('total_brutto_typo')}
					</div>
					<PriceView className="overview__totalPayment-cost">
						{formatNumber2Price(offer.cart.prices.grand_total)}
					</PriceView>
				</>
			)}

			{paymentType && (
				<div className="overview__totalPayment-type">{paymentType}</div>
			)}
		</OverviewTotalPaymentContainerView>
	);
};
