import { useContext } from 'react';
import { AppContext, COOKIE_ENTRIES } from '../App';
import { MESSAGE_TYPE } from '../pages/MessagePage/MessagePage';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { LANG } from '../models/ILang';

type TUseCheckSessionFn = (
	callback: () => void,
	options?: {
		expiredSessionCallback?: () => void;
	},
) => void;

export const useCheckSession = (): TUseCheckSessionFn => {
	const [cookies] = useCookies();

	const { navigateToMessagePage, authorize } = useContext(AppContext);

	const { t, i18n } = useTranslation();

	const current_lang = i18n.language as LANG;

	const checkFn: TUseCheckSessionFn = (callback, options) => {
		if (
			!cookies[COOKIE_ENTRIES.access_token] &&
			!cookies[COOKIE_ENTRIES.session_lifetime]
		) {
			!!options?.expiredSessionCallback &&
				options.expiredSessionCallback();

			navigateToMessagePage(current_lang, {
				type: MESSAGE_TYPE.error,
				title: t('modal_messages_failure_expired_session'),
				btnDescr: 'Homepage',
				redirectUrl: current_lang,
			});
		}

		cookies[COOKIE_ENTRIES.access_token] &&
			!cookies[COOKIE_ENTRIES.session_lifetime] &&
			authorize(cookies[COOKIE_ENTRIES.access_token]).then(() =>
				callback(),
			);

		cookies[COOKIE_ENTRIES.session_lifetime] && callback();
	};

	return checkFn;
};
