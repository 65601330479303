import { FC, MouseEvent, useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AppContext, AppSettingsContext } from '../../App';
import { LANG } from '../../models/ILang';
import { ROUTER_PATH } from '../../router/routes';
import { AnchorView } from '../Anchor/AnchorView';
import { ContainerView } from '../Container/Container.view';
import { Dropdown } from '../Dropdown/Dropdown';
import { COLORS } from '../styleguide/colors';
import { GRID_BREAKPOINTS } from '../styleguide/variables';
import { Basket } from './Basket';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
	IGetCustomerCartQueryResponse,
	GET_CUSTOMER_CART,
} from '../../graphql/queries/cart';
import {
	IGetCustomerQueryResponse,
	GET_CUSTOMER_QUERY,
} from '../../graphql/queries/customer';
import {
	GET_BANNER_LIST_QUERY,
	IGetBannerListResponse,
} from '../../graphql/queries/banners';
import { BannerList } from './BannerList';
import { NotificationList } from '../Notifications/NotificationList';

const HeaderContainerView = styled(ContainerView)`
	padding-top: 12px;
	margin-bottom: 40px;

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		margin-bottom: 60px;
	}

	@media (min-width: ${GRID_BREAKPOINTS.$md}px) {
		margin-bottom: 80px;
	}

	.header {
		&__name-container {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			gap: 15px;

			@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
				flex-direction: row;
				justify-content: flex-end;
				align-items: center;
			}

			& > div {
				display: flex;
				align-items: center;
				gap: 15px;
			}
		}

		&__lang-container {
			min-width: 75px;
			display: flex;
			align-items: center;
			gap: 5px;
			cursor: pointer;
		}

		&__lang-dropdown {
			.dropdown__title::before {
				content: '';
				background-image: url("data:image/svg+xml,%3Csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.55023 15.7714C13.2897 15.7714 16.3212 12.7399 16.3212 9.00043C16.3212 5.26094 13.2897 2.22949 9.55023 2.22949C5.81075 2.22949 2.7793 5.26094 2.7793 9.00043C2.7793 12.7399 5.81075 15.7714 9.55023 15.7714Z' stroke='%23062D64' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/%3E%3Cpath d='M2.7793 9H16.3212' stroke='%23062D64' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/%3E%3Cpath d='M9.55017 2.22949C11.2438 4.08361 12.2062 6.48979 12.2585 9.00043C12.2062 11.5111 11.2438 13.9172 9.55017 15.7714C7.85657 13.9172 6.8941 11.5111 6.8418 9.00043C6.8941 6.48979 7.85657 4.08361 9.55017 2.22949V2.22949Z' stroke='%23062D64' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E");
				width: 19px;
				height: 18px;
			}
		}

		&__shopping-cart-mobile {
			display: flex;

			.cart-icon {
				padding: 10px;
			}

			.dropdown {
				padding: 7px;
			}

			@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
				display: none;
			}
		}

		&__banner-list {
			padding-top: 30px;
			display: flex;
			flex-direction: column;
			gap: 5px;
		}

		&__banner-item {
			padding: 15px 20px;
			background-color: ${COLORS.primaryColor};
			color: ${COLORS.secondaryColor};
		}
	}
`;

const HeaderLogoWrapperView = styled.a`
	height: fit-content;
	grid-area: logo;
	width: 100px;
	display: flex;
	// align-items: center;
	cursor: pointer;

	@media (min-width: ${GRID_BREAKPOINTS.$md}px) {
		width: 158px;
	}

	svg {
		width: 159px;
		height: 53px;
		max-width: 100%;
		max-height: 100%;
	}
`;

// const HeaderLangContainerView = styled.div`
// 	grid-area: lang;
// 	justify-self: end;
// 	display: flex;
// 	align-items: center;
// `;

// const HeaderLangOptionView = styled.div`
// 	text-align: center;
// `;

const HeaderLoggedInMenuContainerView = styled.div`
	display: none;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	gap: 20px;
	text-transform: uppercase;
	padding-top: 70px;

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		display: flex;
	}

	& > div {
		display: flex;
		gap: 25px;
	}
`;

const HeaderLoggedMenuItemView = styled(AnchorView)`
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 17px;
`;

interface IHeaderProps {
	changeLang: (lang: LANG) => void;
}

export const Header: FC<IHeaderProps> = ({ changeLang }) => {
	const { t, i18n } = useTranslation();

	const { isLogged, logout, setOpenedCart } = useContext(AppContext);

	const navigate = useNavigate();

	const location = useLocation();

	const { productId } = useParams();

	const current_lang = i18n.language as LANG;

	const banners = useQuery<IGetBannerListResponse>(GET_BANNER_LIST_QUERY, {
		fetchPolicy: 'cache-and-network',
		onError(error) {
			console.error('GET_BANNER_LIST_QUERY error:>>', error);
		},
	});

	const appSettings = useContext(AppSettingsContext);

	const [getCustomerQuery, user] = useLazyQuery<IGetCustomerQueryResponse>(
		GET_CUSTOMER_QUERY,
		{
			fetchPolicy: 'cache-and-network',
			onError(error) {
				console.error('header GET_CUSTOMER_QUERY error:>>', error);
			},
		},
	);

	const [getCustomerCartQuery, cart] =
		useLazyQuery<IGetCustomerCartQueryResponse>(GET_CUSTOMER_CART, {
			fetchPolicy: 'cache-and-network',
			onError(error) {
				console.error('header GET_CUSTOMER_CART error:>>', error);
			},
		});

	useEffect(() => {
		if (isLogged) {
			getCustomerQuery();

			getCustomerCartQuery();
		}
	}, [isLogged]);

	const handleChangeLang = (lang: LANG): void => {
		lang !== current_lang && changeLang(lang);
	};

	const handleMenuClick = (
		e: MouseEvent<HTMLAnchorElement>,
		url: string,
	): void => {
		e.preventDefault();

		navigate(url);
	};

	const isToProductsListLink =
		productId ||
		location.pathname.includes(ROUTER_PATH.billing) ||
		location.pathname.includes(ROUTER_PATH.overview) ||
		location.pathname.includes(ROUTER_PATH.orderHistory);

	return (
		<HeaderContainerView className="header">
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'space-between',
					alignItems: 'center',
					columnGap: 15,
				}}
			>
				<HeaderLogoWrapperView
					href={
						isLogged
							? current_lang + ROUTER_PATH.dashboard
							: current_lang
					}
					onClick={e => {
						e.preventDefault();

						if (isLogged)
							navigate(current_lang + ROUTER_PATH.dashboard);
					}}
				>
					<svg
						viewBox="0 0 159 54"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M16.9671 0.349731H36.9078L39.7065 27.4621H39.8814C40.5811 25.1882 52.1257 0.349731 52.1257 0.349731H71.8915L72.2414 53.525H53.3502L55.2743 24.3136C54.5746 26.0628 41.9805 53.525 41.9805 53.525H30.086C30.086 53.525 26.0629 26.5875 26.2378 24.3136C25.713 26.4126 18.7163 53.525 18.7163 53.525H0L16.9671 0.349731Z"
							fill={COLORS.primaryColor}
						/>
						<path
							d="M109.499 0.349731L80.4628 53.6999H75.5651L75.3901 32.5348L89.3836 0.349731H109.499Z"
							fill={COLORS.secondaryColor}
						/>
						<path
							d="M113.697 0.349731H132.239L129.615 17.6667H124.017L118.245 53.6999H98.8291L104.601 17.1419L113.697 0.349731Z"
							fill={COLORS.primaryColor}
						/>
						<path
							d="M139.76 4.19807L139.41 7.17168H144.308L143.783 11.0199H138.711L138.361 13.8186H143.608L143.084 17.6668H133.288L135.912 0.52478H145.707L145.183 4.19807H139.76Z"
							fill={COLORS.primaryColor}
						/>
						<path
							d="M158.301 4.89772C158.126 4.7228 157.601 4.37296 156.202 4.37296C153.403 4.37296 151.304 6.47199 150.78 9.09577C150.43 11.7195 151.829 13.8186 154.628 13.8186C155.852 13.8186 156.552 13.4687 156.727 13.2938L156.027 17.8417C155.502 17.8417 154.978 18.1915 153.753 18.0166C151.304 18.0166 149.205 17.142 147.631 15.5678C146.057 13.8186 145.532 11.5446 145.882 9.09577C146.232 6.82182 147.456 4.54788 149.38 2.7987C151.304 1.04951 153.928 0 156.377 0C157.601 0 158.301 0.174919 158.651 0.349838L158.301 4.89772Z"
							fill={COLORS.primaryColor}
						/>
					</svg>
				</HeaderLogoWrapperView>

				{appSettings?.language_switcher_enabled && (
					<div className="header__lang-container">
						<Dropdown
							className="header__lang-dropdown"
							style={{
								containerStyle: {
									textTransform: 'initial',
									height: 'auto',
									borderBottom: 'none',
								},
								titleStyle: {
									whiteSpace: 'initial',
									textAlign: 'right',
									padding: 0,
								},
								optionsContainerStyle: {
									textAlign: 'center',
								},
							}}
							selected={current_lang?.slice(1).toUpperCase()}
						>
							{Object.values(LANG).map(lang => (
								<div
									key={lang}
									onClick={() => handleChangeLang(lang)}
								>
									{lang.slice(1).toUpperCase()}
								</div>
							))}
						</Dropdown>
					</div>
				)}

				<div className="header__name-container">
					<AnchorView
						className="header__name-container-mvtec"
						style={{ textTransform: 'uppercase' }}
						href={'https://www.mvtec.com/'}
						target="_blank"
						rel="noopener noreferrer"
					>
						{t('to_mvtec_typo')}
					</AnchorView>
					{isLogged &&
						!location.pathname.endsWith(current_lang) &&
						!!user.data?.customer && (
							<div>
								{user.data.customer.firstname &&
									user.data.customer.lastname && (
										<Dropdown
											className="header__name-container-dropdown"
											style={{
												containerStyle: {
													textTransform: 'initial',
													height: 'auto',
													flex: 1,
												},
												titleStyle: {
													overflow: 'auto',
													whiteSpace: 'initial',
													textAlign: 'right',
													padding: 0,
												},
											}}
											selected={`${t('logged_as_typo')}
										${user.data.customer.firstname}
										${user.data.customer.lastname}`}
										>
											<span onClick={logout}>Logout</span>
										</Dropdown>
									)}

								<div className="header__shopping-cart-mobile header__name-container-cart">
									<Basket
										quantity={
											cart.data?.customerCart.items
												.length ?? 0
										}
										onClick={() => setOpenedCart(true)}
									/>
									{(appSettings?.commitments_enabled ||
										appSettings?.offers_enabled) && (
										<NotificationList />
									)}
								</div>
							</div>
						)}
				</div>
			</div>

			{isLogged && (
				<HeaderLoggedInMenuContainerView>
					<div>
						{!location.pathname.includes(ROUTER_PATH.dashboard) && (
							<HeaderLoggedMenuItemView
								href={
									isToProductsListLink
										? current_lang + ROUTER_PATH.products
										: current_lang + ROUTER_PATH.dashboard
								}
								onClick={e => {
									e.preventDefault();

									handleMenuClick(
										e,
										isToProductsListLink
											? current_lang +
													ROUTER_PATH.products
											: current_lang +
													ROUTER_PATH.dashboard,
									);
								}}
							>
								<svg
									width="9"
									height="16"
									viewBox="0 0 9 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M8 1.5L2 8.38235L8 14.5"
										stroke={COLORS.primaryColor}
										strokeWidth="2"
									/>
								</svg>
								{isToProductsListLink
									? t('products_overview_typo')
									: 'My mvtec'}
							</HeaderLoggedMenuItemView>
						)}
					</div>
					<div>
						<HeaderLoggedMenuItemView
							href={current_lang + ROUTER_PATH.products}
							onClick={e => {
								e.preventDefault();

								navigate(current_lang + ROUTER_PATH.products);
							}}
						>
							<svg
								width="17"
								height="20"
								viewBox="0 0 17 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M16 13.3333V6.66663C15.9997 6.37435 15.9225 6.0873 15.7763 5.83426C15.63 5.58122 15.4198 5.37109 15.1667 5.22496L9.33333 1.89163C9.07997 1.74535 8.79256 1.66833 8.5 1.66833C8.20744 1.66833 7.92003 1.74535 7.66667 1.89163L1.83333 5.22496C1.58022 5.37109 1.36998 5.58122 1.22372 5.83426C1.07745 6.0873 1.0003 6.37435 1 6.66663V13.3333C1.0003 13.6256 1.07745 13.9126 1.22372 14.1657C1.36998 14.4187 1.58022 14.6288 1.83333 14.775L7.66667 18.1083C7.92003 18.2546 8.20744 18.3316 8.5 18.3316C8.79256 18.3316 9.07997 18.2546 9.33333 18.1083L15.1667 14.775C15.4198 14.6288 15.63 14.4187 15.7763 14.1657C15.9225 13.9126 15.9997 13.6256 16 13.3333Z"
									stroke={COLORS.primaryColor}
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M1.2251 5.80005L8.5001 10.0084L15.7751 5.80005"
									stroke={COLORS.primaryColor}
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M8.5 18.4V10"
									stroke={COLORS.primaryColor}
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							{t('to_products_typo')}
						</HeaderLoggedMenuItemView>
						{isLogged && (
							<>
								<HeaderLoggedMenuItemView
									className="header__shopping-cart"
									onClick={() => setOpenedCart(true)}
								>
									<Basket
										quantity={
											cart.data?.customerCart.items
												.length ?? 0
										}
									/>
									{t('shopping_cart_typo')}
								</HeaderLoggedMenuItemView>
								<HeaderLoggedMenuItemView
									href={
										current_lang + ROUTER_PATH.orderHistory
									}
									onClick={e => {
										e.preventDefault();

										navigate(
											current_lang +
												ROUTER_PATH.orderHistory,
										);
									}}
								>
									<svg
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M9.99984 18.3333C14.6022 18.3333 18.3332 14.6023 18.3332 9.99996C18.3332 5.39759 14.6022 1.66663 9.99984 1.66663C5.39746 1.66663 1.6665 5.39759 1.6665 9.99996C1.6665 14.6023 5.39746 18.3333 9.99984 18.3333Z"
											stroke={COLORS.primaryColor}
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M10 5V10L13.3333 11.6667"
											stroke={COLORS.primaryColor}
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
									{t('order_history_typo')}
								</HeaderLoggedMenuItemView>
							</>
						)}
						{(appSettings?.commitments_enabled ||
							appSettings?.offers_enabled) && (
							<HeaderLoggedMenuItemView>
								<NotificationList />
							</HeaderLoggedMenuItemView>
						)}
					</div>
				</HeaderLoggedInMenuContainerView>
			)}

			{!!banners.data?.getBanners.length && (
				<BannerList banners={banners.data.getBanners} />
			)}
		</HeaderContainerView>
	);
};
