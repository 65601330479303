import { ICartPricesResponse, IPrice, ITotalCartPrice } from '../models/ICart';

export const formatNumber2Price = (
	price: IPrice,
	// lang: LANG,
	// country_code: string = ALLOWED_COUNTRY_CODES.germany,
): string => {
	const locales = 'de-DE';

	return new Intl.NumberFormat(locales, {
		style: 'currency',
		currency: price.currency || 'EUR',
	}).format(price.value);
};

export const convertCartPricesResponse = (
	prices: ICartPricesResponse,
): ITotalCartPrice => ({
	subTotalCartPriceExclTax: prices.subtotal_excluding_tax,
	totalCartTax: {
		value: prices.total_tax_amount,
		currency: prices.grand_total.currency,
	},
	grandTotalCartPrice: prices.grand_total,
});
