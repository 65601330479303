import React, { ReactNode } from 'react';
import { AppSettingsContext } from '../../App';
import useAppSettings from '../../hooks/useAppSettings';

interface AppSettingsProviderProps {
	children: ReactNode;
}

const AppSettingsProvider: React.FC<AppSettingsProviderProps> = ({
	children,
}) => {
	const appSettings = useAppSettings();

	return (
		<AppSettingsContext.Provider value={appSettings}>
			{children}
		</AppSettingsContext.Provider>
	);
};

export default AppSettingsProvider;
