import { gql } from '@apollo/client';
import { IProduct, IProductDetailed } from '../../models/IProduct';

// export const GET_PRODUCTS_BY_SKU_QUERY = gql`
// query GetProductsById($sku: [String!]!) {
// 		products(
// 			filter: { sku: { in: $sku } }
// 		) {
// 			items {
// 				uid
// 				product_type
// 				name
// 				sku
// 				application
//             	version
// 				dongle_sku
//               	sap_service_description
// 				product_tech_headline
//               	product_tech_description
// 				description {
// 					html
// 				}
// 				image {
// 					url
// 					label
// 				}
// 				categories {
// 					uid
// 					url_key
// 				}
// 				# price_range {
// 				# 	minimum_price {
// 				# 		regular_price {
// 				# 			value
// 				# 			currency
// 				# 		}
// 				# 	}
// 				# }
// 			}
// 		}
// 	}
// `;

export interface IGetProductBySkuQueryResponse {
	products: {
		items: IProductDetailed[];
	};
}

export const GET_PRODUCT_BY_SKU_QUERY = gql`
	query GetProductById($sku: String!) {
		products(filter: { sku: { eq: $sku } }) {
			items {
				product_type
				name
				sku
				application
				dongle_sku
				exclude_mac_address
              	# sap_service_description
				product_tech_headline
              	product_tech_description
				product_tech_image
				product_activation_headline
				product_activation_description
				product_pdf
				info_description
				image {
					url
					label
				}
			}
		}
	}
`;

export interface IGetCategoryListQueryResponse {
	categoryList: {
		// children_count: number;
		children: {
			products: {
				total_count: number;
			}
			uid: string;
			name: string;
			position: number;
			// level: number;
			// path: string;
			// url_path: string;
			// url_key: string;
			// children: {
			// 	uid: string;
			// 	level: number;
			// 	name: string;
			// 	path: string;
			// 	url_path: string;
			// 	url_key: string;
			// 	position: number;
			// };
		}[];
	}[];
}

export const GET_CATEGORY_LIST_QUERY = gql`
	query {
		categoryList {
			children_count
			children {
				products {
					total_count
				}
				uid
				name
				position
				# level
				# path
				# url_path
				# url_key
				# children {
				# 	uid
				# 	level
				# 	name
				# 	path
				# 	url_path
				# 	url_key
				# 	position
				# }
			}
		}
	}
`;

// export interface IGetCategoriesQueryResponse {
// 	categories: {
// 		total_count: number;
// 		items: {
// 			uid: string;
// 			level: string;
// 			name: string;
// 			path: string;
// 			children_count: string;
// 			children: {
// 				uid: string;
// 				level: string;
// 				name: string;
// 				path: string;
// 				children_count: string;
// 				children: {
// 					uid: string;
// 					level: string;
// 					name: string;
// 					path: string;
// 				};
// 			};
// 		}[];
// 		page_info: {
// 			current_page: number;
// 			page_size: number;
// 			total_pages: number;
// 		};
// 	};
// }

// export const GET_CATEGORIES_QUERY = gql`
// 	query {
// 		categories(
// 				filters: {
// 					ids: {in: ["3", "9", "12", "21", "38", "39"]}
// 					parent_id: {in: ["2"]}
// 				}
// 				pageSize: 1000
// 				currentPage: 2
// 			) {
// 				total_count
// 				items {
// 					uid
// 					level
// 					name
// 					path
// 					children_count
// 					children {
// 						uid
// 						level
// 						name
// 						path
// 						children_count
// 						children {
// 							uid
// 							level
// 							name
// 							path
// 						}
// 					}
// 				}
// 				page_info {
// 					current_page
// 					page_size
// 					total_pages
// 				}
// 			}
// 	}
// `;

export interface IGetProductsByCategotiesQueryResponse {
	products: {
		total_count: number;
		items: IProduct[];
		page_info: {
			page_size: number;
			current_page: number;
		};
	};
}

export const GET_PRODUCTS_BY_CATEGORIES = gql`
	query GetProductsByCategories(
			$categoryUids: [String!]!,
			$applicationUids: [String!]!,
			$versionUids: [String!]!
		) {
		products(
			filter: {
				category_uid: { in: $categoryUids }
				application: { in: $applicationUids } 
				version: { in: $versionUids } 
			}
			pageSize: 10000
		) {
			total_count
			items {
				uid
				name
				sku
				product_type
				application
            	version
				dongle_sku
				exclude_mac_address
				image {
					url
					label
				}
				categories {
					uid
					url_key
					position
				}
				position_in_categories {
					category_uid
					position
				}
				# price_range {
				# 	minimum_price {
				# 		regular_price {
				# 			value
				# 			currency
				# 		}
				# 	}
				# }
			}
			page_info {
				page_size
				current_page
			}
		}
	}
`;

export interface IGetFiltersQueryResponse {
	customAttributeMetadata: {
		items: {
			attribute_code: string;
			attribute_options: {
				value: string;
				label: string;
				products_count: number;
			}[];
			// attribute_type: string;
			// entity_type: string;
			// input_type: string;
			// storefront_properties: {
			// 	use_in_product_listing: boolean;
			// 	use_in_layered_navigation: string;
			// 	use_in_search_results_layered_navigation: boolean;
			// 	visible_on_catalog_pages: boolean;
			// 	position: number;
			// };
		}[];
	};
}

export const GET_FILTERS_QUERY = gql`
	{
		customAttributeMetadata(
			attributes: [
				{
					attribute_code: "application"
					entity_type: "catalog_product"
				},
				{
					attribute_code: "version"
					entity_type: "catalog_product"
				}
			]
		) {
			items {
				attribute_code
				attribute_options {
					value
					label
					products_count
				}
				# attribute_type
				# entity_type
				# input_type
				# storefront_properties {
				# 	use_in_product_listing
				# 	use_in_layered_navigation
				# 	use_in_search_results_layered_navigation
				# 	visible_on_catalog_pages
				# 	position
				# }
			}
		}
	}
`;
