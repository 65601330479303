import { useEffect, useState } from 'react';
import { getLangFromPath, isAllowedCountry } from '../utils/helpers';
import { useCookies } from 'react-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import { COOKIE_ENTRIES, LOCAL_STORAGE_ENTRIES } from '../App';
import {
	ApolloError,
	QueryResult,
	useApolloClient,
	useLazyQuery,
	useMutation,
} from '@apollo/client';
import {
	IGetCustomerQueryResponse,
	GET_CUSTOMER_QUERY,
	IInitCustomerQueryResponse,
	INIT_CUSTOMER_QUERY,
} from '../graphql/queries/customer';
import {
	CREATE_CUSTOMER_TOKEN_MUTATION,
	ICreateCustomerTokenResponse,
	IGenerateCustomerTokenData,
} from '../graphql/mutations/customer';
import {
	IGetCustomerCartQueryResponse,
	GET_CUSTOMER_CART,
} from '../graphql/queries/cart';
import { LANG } from '../models/ILang';
import { ROUTER_PATH } from '../router/routes';
import {
	IMessagePageParams,
	MESSAGE_TYPE,
} from '../pages/MessagePage/MessagePage';
import { useAuth } from '../auth/auth';
import axios from 'axios';
import {
	GET_TRANSLATIONS,
	IGetTranslationsQueryResponse,
} from '../graphql/queries/translations';
import i18n from '../i18n';
import { initReactI18next } from 'react-i18next';
import {
	GET_COMMITMENT_LIST_QUERY,
	GET_OFFER_LIST_QUERY,
	IGetCommitmentListQueryResponse,
	IGetOfferListQueryResponse,
} from '../graphql/queries/notifications';
import {
	GET_CATEGORY_LIST_QUERY,
	GET_FILTERS_QUERY,
} from '../graphql/queries/product';

export type TAuthorize = (
	access_token: string,
	options?: {
		forceAuth?: boolean;
	},
) => Promise<string>;

interface IUseInitReturn {
	isLoading: boolean;
	isLogged: boolean;
	logout: () => void;
	navigateToMessagePage: (lang: LANG, params: IMessagePageParams) => void;
	authorize: TAuthorize;
	changeLanguage: (lng: LANG) => void;
}

export const useInit = (): IUseInitReturn => {
	const [isLogged, setIsLogged] = useState<boolean>(false);

	const [isLoading, setIsLoading] = useState<boolean>(true);

	const navigate = useNavigate();

	const location = useLocation();

	const [cookies, setCookie] = useCookies();

	const client = useApolloClient();

	const oAuth2User = useAuth();

	const initLang = location.pathname.slice(0, 3) as LANG;

	const navigateToMessagePage = (
		lang: LANG,
		params: IMessagePageParams,
	): void =>
		navigate(lang + ROUTER_PATH.message, {
			state: params,
		});

	const [createCustomerTokenMutation] =
		useMutation<ICreateCustomerTokenResponse>(
			CREATE_CUSTOMER_TOKEN_MUTATION,
		);

	const [forceCreateCustomerTokenQuery] =
		useLazyQuery<IInitCustomerQueryResponse>(INIT_CUSTOMER_QUERY);

	const [getTranslationQuery] = useLazyQuery<IGetTranslationsQueryResponse>(
		GET_TRANSLATIONS,
		{
			onCompleted(data) {
				const translations: any = {};

				Object.values(LANG).map(lang => {
					const filtered: { [k: string]: string } = {};

					data.getTranslations.forEach(item => {
						if (item.store_code === lang.slice(1).toUpperCase()) {
							filtered[item.short_key.toLowerCase().trim()] =
								item.translation.trim();
						}
					});

					translations[lang] = {
						translation: filtered,
					};
				});

				i18n.use(initReactI18next).init({
					fallbackLng: LANG.de,
					debug: false,
					lng: initLang,
					resources: translations,
				});
			},
			onError(error) {
				console.error('getTranslationQuery error:>>', error);
			},
		},
	);

	const [customerDataLoadQuery] =
		useLazyQuery<IGetCustomerQueryResponse>(GET_CUSTOMER_QUERY);

	const [getCustomerCartQuery] = useLazyQuery<IGetCustomerCartQueryResponse>(
		GET_CUSTOMER_CART,
		{
			onError(error) {
				setIsLoading(false);

				console.error('GET_CUSTOMER_CART error:>>', error);
			},
		},
	);

	const getCustomer = (): Promise<QueryResult> => {
		return customerDataLoadQuery({
			fetchPolicy: 'network-only',
			onCompleted() {
				setIsLogged(true);

				setIsLoading(false);
			},
		});
	};

	const [getCommitmentsList] = useLazyQuery<IGetCommitmentListQueryResponse>(
		GET_COMMITMENT_LIST_QUERY,
		{
			onError(error) {
				console.error('GET_COMMITMENTS_LIST_QUERY error:>>', error);
			},
			fetchPolicy: 'cache-and-network',
		},
	);

	const [getOfferList] = useLazyQuery<IGetOfferListQueryResponse>(
		GET_OFFER_LIST_QUERY,
		{
			onError(error) {
				console.error('GET_OFFER_LIST_QUERY error:>>', error);
			},
			// pollInterval: 5000,
			fetchPolicy: 'cache-and-network',
		},
	);

	const initQuery = async (): Promise<unknown> => {
		return await Promise.all([
			getCustomerCartQuery(),
			getCustomer(),
			getCommitmentsList(),
			getOfferList(),
			getTranslationQuery(),
		]);
	};

	const authorize: TAuthorize = (access_token, options) => {
		const onCompleted = (data: IGenerateCustomerTokenData): void => {
			const isSapCustomer = data.is_sap_customer;

			const countryCode = data.country_code;

			const hasCatalogs = data.has_catalogs;

			const hasInternalDistributors = data.has_internal_distributors;

			const sessionLifetime = data.session_lifetime;

			setCookie(COOKIE_ENTRIES.is_sap_customer, isSapCustomer, {
				path: '/',
			});

			setCookie(COOKIE_ENTRIES.country_code, countryCode, {
				path: '/',
			});

			setCookie(COOKIE_ENTRIES.has_catalogs, hasCatalogs, {
				path: '/',
			});

			setCookie(
				COOKIE_ENTRIES.has_internal_distributors,
				hasInternalDistributors,
				{
					path: '/',
				},
			);

			setCookie(
				COOKIE_ENTRIES.session_lifetime, // magento bearer lifetime
				sessionLifetime,
				{
					path: '/',
					expires: new Date(
						new Date().getTime() -
							120000 + // give 2 min window
							sessionLifetime * 1000,
					),
				},
			);

			setIsLoading(false);

			if (
				!isSapCustomer ||
				!isAllowedCountry(countryCode) ||
				!hasCatalogs ||
				!hasInternalDistributors
			) {
				setIsLoading(false);

				navigate(initLang + ROUTER_PATH.dashboard);

				console.error(
					'sap customer:>>',
					isSapCustomer,
					'not allowed country:>>',
					countryCode,
					'catalogs:>>',
					hasCatalogs,
					'distributors:>>',
					hasInternalDistributors,
				);
			}

			const bearer = data.token;

			localStorage.setItem(LOCAL_STORAGE_ENTRIES.mvt_auth_token, bearer);
		};

		const onError = (error: ApolloError): void => {
			console.error('createCustomerTokenMutation error:>>', error);

			setIsLoading(false);

			navigateToMessagePage(initLang, {
				type: MESSAGE_TYPE.error,
				title:
					// ? `Leider ist der Zugang zum Webshop im Moment nicht möglich. Bitte versuchen Sie es zu einem späteren Zeitpunkt nochmal oder kontaktieren Sie uns bitte unter folgender E-Mail Adresse <a href="mailto:${process.env.REACT_APP_SUPPORT_EMAIL}"> E-Mail Adresse</a>.`
					error.message.toLowerCase() === 'internal server error'
						? 'modal_messages_failure_internal_server_error'
						: error.message,
				btnDescr: 'Homepage',
				redirectUrl: initLang,
			});
		};

		return new Promise(resolve => {
			if (options?.forceAuth) {
				forceCreateCustomerTokenQuery({
					fetchPolicy: 'network-only',
					onCompleted(data) {
						onCompleted(data.initCustomer);

						initQuery()
							.then(() => {
								navigate(initLang + ROUTER_PATH.dashboard);
							})
							.catch(error => {
								console.error('initial-1 error:>>', error);
							})
							.finally(() => setIsLoading(false));

						resolve(data.initCustomer.token);
					},
					onError,
				});
			} else {
				createCustomerTokenMutation({
					variables: {
						access_token,
					},
					fetchPolicy: 'network-only',
					onCompleted(data) {
						onCompleted(data.generateM2CustomerToken);

						resolve(data.generateM2CustomerToken.token);
					},
					onError,
				});
			}
		});
	};

	useEffect(() => {
		(location.pathname === '/' ||
			!Object.values(LANG).includes(
				getLangFromPath(location.pathname),
			)) &&
			(window.location.href = LANG.de);

		location.pathname.endsWith('/') &&
			navigate(location.pathname.replace(/\/$/, ''));

		!cookies.access_token &&
			localStorage.removeItem(LOCAL_STORAGE_ENTRIES.mvt_auth_token);

		if (
			cookies.access_token &&
			cookies[COOKIE_ENTRIES.session_lifetime] &&
			localStorage.getItem(LOCAL_STORAGE_ENTRIES.mvt_auth_token)
		) {
			initQuery()
				.then(() => {
					setIsLoading(false);

					location.pathname === initLang &&
						navigate(initLang + ROUTER_PATH.dashboard);
				})
				.catch(error => {
					console.error('initial-1 error:>>', error);

					setIsLoading(false);
				});
		}

		if (cookies.access_token && !cookies[COOKIE_ENTRIES.session_lifetime]) {
			authorize(cookies[COOKIE_ENTRIES.access_token] as string).then(
				bearer => {
					bearer &&
						initQuery()
							.then(() => {
								setIsLoading(false);

								location.pathname === initLang &&
									navigate(initLang + ROUTER_PATH.dashboard);
							})
							.catch(error => {
								console.error('initial-2 error:>>', error);

								setIsLoading(false);

								navigateToMessagePage(initLang, {
									type: MESSAGE_TYPE.error,
									title: error.message,
									btnDescr: 'Homepage',
									redirectUrl: initLang,
								});
							});
				},
			);
		}

		if (location.pathname === initLang + ROUTER_PATH.mvloginRedirect) {
			oAuth2User.handleCallback(
				async (access_token?: string, expires_at?: number) => {
					axios({
						method: 'GET',
						url: process.env.REACT_APP_MVLOGIN_CHECK_TOKEN,
						headers: {
							authorization: access_token
								? 'Bearer ' + access_token
								: '',
						},
					})
						.then(() => {
							const access_token_expires =
								(expires_at as number) * 1000;

							// mvlogin access_token:
							access_token &&
								setCookie(
									COOKIE_ENTRIES.access_token,
									access_token,
									{
										path: '/',
										expires: new Date(access_token_expires),
									},
								);

							access_token &&
								authorize(access_token as string).then(
									bearer => {
										bearer &&
											initQuery()
												.then(() => {
													setIsLoading(false);

													navigate(
														initLang +
															ROUTER_PATH.dashboard,
													);
												})
												.catch(error => {
													console.error(
														'initial-3 error:>>',
														error,
													);

													setIsLoading(false);

													navigateToMessagePage(
														initLang,
														{
															type: MESSAGE_TYPE.error,
															title: error.message,
															btnDescr:
																'Homepage',
															redirectUrl:
																initLang,
														},
													);
												});
									},
								);
						})
						.catch(error => {
							console.error(
								'MVLOGIN_CHECK_TOKEN error:>>',
								error,
							);

							setIsLoading(false);
						});
				},
			);
		}

		if (
			!cookies.access_token ||
			!cookies[COOKIE_ENTRIES.session_lifetime] ||
			!localStorage.getItem(LOCAL_STORAGE_ENTRIES.mvt_auth_token)
		) {
			getTranslationQuery().finally(() => setIsLoading(false));
		}
	}, []);

	useEffect(() => {
		isLogged &&
			location.pathname === initLang &&
			navigate(initLang + ROUTER_PATH.dashboard);
	}, [isLogged]);

	const logout = (): void => {
		localStorage.removeItem(LOCAL_STORAGE_ENTRIES.mvt_auth_token);

		setCookie(cookies[COOKIE_ENTRIES.access_token], null, {
			path: '/',
			expires: new Date(),
		});

		setCookie(cookies[COOKIE_ENTRIES.has_internal_distributors], null, {
			path: '/',
			expires: new Date(),
		});

		setCookie(cookies[COOKIE_ENTRIES.session_lifetime], null, {
			path: '/',
			expires: new Date(),
		});

		setIsLogged(false);

		oAuth2User.logout();
	};

	const changeLanguage = (lng: LANG): void => {
		i18n.changeLanguage(lng);

		const newPathname = location.pathname.replace(/\/\w{2}/i, lng);

		navigate(newPathname, { replace: true });

		client.refetchQueries({
			include: [
				GET_CUSTOMER_CART,
				GET_CATEGORY_LIST_QUERY,
				GET_FILTERS_QUERY,
			],
		});
	};

	return {
		isLoading,
		isLogged,
		authorize,
		logout,
		navigateToMessagePage,
		changeLanguage,
	};
};
