import { FC, ReactNode, useId, useState } from 'react';
import styled from 'styled-components';
import { Modal } from '../Modal/Modal';
import { COLORS } from '../styleguide/colors';
import { TypoBody, TypoHeadline2 } from '../styleguide/typography';
import { Button } from '../Buttons/Buttons';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { GRID_BREAKPOINTS } from '../styleguide/variables';
import { formatDateToString } from '../../utils/helpers';

const CookieBannerContainer = styled.div`
	display: grid;
	gap: 20px;

	.cookie-consent {
		&__checkbox-group {
			margin: 0 auto;
			display: grid;
			grid-template-columns: auto auto;
			gap: 0.9375rem;

			@media (min-width: ${GRID_BREAKPOINTS.$xs}px) {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
			}
		}

		&__description {
			font-family: Arial, Helvetica, sans-serif;
			font-weight: 400;
			text-align: center;
		}

		&__button-group {
			display: flex;
			justify-content: center;
			flex-direction: column;
			gap: 10px;

			@media (min-width: ${GRID_BREAKPOINTS.$xs}px) {
				flex-direction: row;
			}

			button {
				background-color: ${COLORS.blueColor};
			}
		}
	}
`;

const CheckboxContainer = styled.div`
	&:has(> input:checked:disabled) {
		opacity: 0.6;
	}

	input {
		display: none;
	}

	label {
		display: flex;
		align-items: center;
		gap: 5px;

		&::before {
			content: '';
			width: 15px;
			height: 15px;
			border: 0.125rem solid ${COLORS.blueColor};
			border-radius: 0.3125rem;
		}

		div {
			font-family: Arial, Helvetica, sans-serif;
		}
	}

	input:checked {
		& + label::before {
			background-color: ${COLORS.blueColor};
			box-shadow: inset 0 0 0 0.1875rem #fff;
		}

		&:disabled + label::before {
			border-color: ${COLORS.disabledColor};
			background-color: ${COLORS.disabledColor};
			box-shadow: inset 0 0 0 0.1875rem #fff;
		}
	}
`;

const Checkbox: FC<{
	children: ReactNode;
	checked: boolean;
	disabled?: boolean;
	onChange?: (v: boolean) => void;
}> = ({ children, checked, disabled, onChange }) => {
	const id = useId();

	return (
		<CheckboxContainer>
			<input
				id={id}
				type="checkbox"
				checked={checked}
				disabled={disabled}
				onChange={e => onChange && onChange(e.target.checked)}
			/>

			<label htmlFor={id}>
				<TypoBody>{children}</TypoBody>
			</label>
		</CheckboxContainer>
	);
};

interface ICookieState {
	essential: boolean;
	analytics: boolean;
	marketing: boolean;
	external_content: boolean;
}

export const CookieBanner: FC = () => {
	const [checked, setChecked] = useState<ICookieState>({
		essential: true,
		analytics: false,
		marketing: false,
		external_content: false,
	});

	const { t } = useTranslation();

	const [cookies, setCookie] = useCookies();

	const setConsent = (obj: ICookieState): void => {
		const cookieString = Object.entries(obj).reduce(
			(acc, [key, value]) => {
				return acc + `${key}:${value};`;
			},
			`consent_date:${formatDateToString(new Date())};`,
		);

		setCookie('cookie_consent', cookieString, {
			path: '/',
			expires: new Date('12.10.2030'),
		});
	};

	const handleAcceptAll = (): void => {
		setConsent({
			essential: true,
			analytics: true,
			marketing: true,
			external_content: true,
		});
	};

	const handleAcceptEssential = (): void => {
		setConsent({
			essential: true,
			analytics: false,
			marketing: false,
			external_content: false,
		});
	};

	const handleSaveAndClose = (): void => {
		setConsent(checked);
	};

	return (
		<Modal
			show={!cookies['cookie_consent']}
			style={{
				modalLayout: {
					display: 'flex',
					alignItems: 'flex-end',
				},
				modalClose: {
					display: 'none',
				},
				modalContainer: {
					marginBottom: 0,
					padding: 25,
					border: `10px solid ${COLORS.blueColor}`,
					borderBottom: 'none',
				},
				modelContentContainer: {
					paddingBottom: 0,
				},
			}}
		>
			<CookieBannerContainer className="cookie-consent">
				<TypoHeadline2>{t('cookie_settings')}</TypoHeadline2>
				<div className="cookie-consent__checkbox-group">
					<Checkbox
						checked={checked.essential}
						disabled={true}
						onChange={() =>
							setChecked(ch => ({
								...ch,
								essential: !ch.essential,
							}))
						}
					>
						{t('cookie_essential')}
					</Checkbox>
					<Checkbox
						checked={checked.analytics}
						onChange={() =>
							setChecked(ch => ({
								...ch,
								analytics: !ch.analytics,
							}))
						}
					>
						{t('cookie_analytics')}
					</Checkbox>
					<Checkbox
						checked={checked.marketing}
						onChange={() =>
							setChecked(ch => ({
								...ch,
								marketing: !ch.marketing,
							}))
						}
					>
						{t('cookie_marketing')}
					</Checkbox>
					<Checkbox
						checked={checked.external_content}
						onChange={() =>
							setChecked(ch => ({
								...ch,
								external_content: !ch.external_content,
							}))
						}
					>
						{t('cookie_external_content')}
					</Checkbox>
				</div>
				<TypoBody
					className="cookie-consent__description"
					dangerouslySetInnerHTML={{
						__html: t('cookie_consent_description'),
					}}
				/>

				<div className="cookie-consent__button-group">
					<Button onClick={handleAcceptAll}>
						{t('cookie_accept_all')}
					</Button>
					<Button onClick={handleAcceptEssential}>
						{t('cookie_accept_only_essential')}
					</Button>
					<Button onClick={handleSaveAndClose}>
						{t('cookie_save_and_close')}
					</Button>
				</div>
				<TypoBody
					className="cookie-consent__description"
					dangerouslySetInnerHTML={{
						__html: t('cookie_get_more_info_about_used_cookies'),
					}}
				/>
			</CookieBannerContainer>
		</Modal>
	);
};
