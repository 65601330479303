import { CSSProperties, FC, ReactNode } from 'react';
import styled from 'styled-components';
import { LANG } from '../../models/ILang';
import { COLORS } from '../styleguide/colors';
import { Tooltip } from '../Tooltip/Tooltip';
import { ReactComponent as CheckedDisabledCheckoxIcon } from '../../assets/icons/checked-disabled-checkobox.svg';
import { ReactComponent as XCrossIcon } from '../../assets/icons/x-cross.svg';
import { ReactComponent as RadioCircleIcon } from '../../assets/icons/radio-circle.svg';

const SwitchContainerView = styled.div`
	width: fit-content;
	align-items: center;
	gap: 5px;
	font-family: 'Arial';
	color: ${COLORS.primaryColor};
	font-weight: 700;

	.switch__box {
		width: 25px;
		height: 25px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	svg.checked-disabled-checkbox {
		circle {
			fill: ${COLORS.secondaryColor};
		}

		path {
			stroke: ${COLORS.primaryColor};
		}
	}
`;

export type InputValue = string | number | LANG;

export type SwitchValue = string | number | boolean | LANG;

interface ISwitchProps {
	children: ReactNode;
	type?: 'radio' | 'checkbox';
	id?: string;
	checked: boolean;
	disabled?: boolean;
	className?: string;
	onChange?: (checked: boolean) => void;
	style?: CSSProperties;
	tooltip?: string;
}

export const Switch: FC<ISwitchProps> = ({
	children,
	checked,
	disabled,
	className,
	onChange,
	type = 'checkbox',
	id,
	style,
	tooltip,
}) => {
	const handleClick = (): void => {
		!disabled && !!onChange && onChange(!checked);
	};

	return (
		<SwitchContainerView
			className={['switch', className].join(' ').trim()}
			id={id}
			onClick={handleClick}
			style={{
				display: 'flex',
				cursor: !disabled ? 'pointer' : '',
				opacity: disabled ? 0.3 : 1,
				...style,
			}}
		>
			{/* TODO: Inconsistency. When disabled and checked, this always shows a radio, even if checkbox is configured. Is this correct? See storybook for demonstration */}
			<div
				className="switch__box"
				style={{
					border:
						disabled && checked
							? 'none'
							: `1px solid ${COLORS.secondaryColor}`,
					borderRadius: type === 'radio' ? 100 : '',
				}}
			>
				{disabled && checked && (
					<CheckedDisabledCheckoxIcon className="checked-disabled-checkbox" />
				)}
				{!disabled &&
					checked &&
					(type === 'checkbox' ? (
						<XCrossIcon fill={COLORS.primaryColor} />
					) : (
						<RadioCircleIcon fill={COLORS.primaryColor} />
					))}
			</div>
			{children}
			{tooltip && <Tooltip>{tooltip}</Tooltip>}
		</SwitchContainerView>
	);
};
