import { IProduct, IProductDetailed } from './IProduct';

export interface IOperationSystem {
	title: 'Windows' | 'Linux' | 'OS X' | 'Other';
	value: string;
}

export const operationSystems: Record<string, IOperationSystem> = {
	windows: {
		title: 'Windows',
		value: '101',
	},
	linux: {
		title: 'Linux',
		value: '102',
	},
	macos: {
		title: 'OS X',
		value: '103',
	},
	other: {
		title: 'Other',
		value: '104',
	},
};

export interface IPrice {
	value: number;
	currency: string;
}

export interface ICartPricesResponse {
	grand_total: IPrice;
	total_tax_amount: number;
	subtotal_excluding_tax: IPrice;
	tax_percent: number;

	// row_total: IPrice;

	// row_total_including_tax: IPrice;
	// discount_amount: number;
	// discount_percent: number;
	// fixed_product_taxes: [];
}

export interface ITotalCartPrice {
	subTotalCartPriceExclTax: IPrice;
	totalCartTax: IPrice;
	grandTotalCartPrice: IPrice;
}

export interface ICartItem {
	cartItemUid: string;
	product: IProduct;
	dongleValues: string[];
	// withDongleNumber: {
	// 	values: string[];
	// };
	// withMacAddress: {
	// 	values: string[];
	// };
	dongle_sku: string;
	OS: string | Partial<null>;
	quantity: number;
	totalItemPrice: {
		totalPrice: IPrice;
		taxPercent: number;
		totalPriceWithTax: IPrice;
		discountAmount?: number;
	};
}

export interface IAdditional {
	dongle_sku: string;
	host_id: string[];
	os: string | null;
}

export interface ICustomerCartItemQueryResponse {
	uid: string;
	quantity: number;
	additional: IAdditional;
	prices: {
		price: IPrice;
		row_total: IPrice;
		tax_percent: number;
		row_total_including_tax: IPrice;
		discount_amount?: number;
	};
	product: IProductDetailed;
}

export type TShipping = {
	carrier_code: string;
	method_code: string;
	method_title: string;
	amount: IPrice;
};

export interface IAvailablePaymentMethod {
	code: string;
	title: string;
}

export interface ICartItemPayload {
	quantity: number;
	sku: string;
	additional: IAdditional;
}

export interface ICartUpdateItemPayload extends ICartItemPayload {
	cartItemUid: string;
}

export interface ICSVItem {
	sku: string;
	file: File;
}
