import { ChangeEvent, FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '../styleguide/colors';

const CounterContainerView = styled.div`
	display: flex;
	gap: 5px;

	input {
		width: 55px;
		border: none;
		border-bottom: 2px solid ${COLORS.primaryColor};
		outline: none;
		font-family: 'Arial';
		font-size: 18px;
		font-weight: 400;
		text-align: center;
		-moz-appearance: textfield;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`;

const CounterTitleView = styled.div`
	margin-bottom: 13px;
	text-transform: uppercase;
`;

interface ICounterCircleViewProps {
	minus?: boolean;
	plus?: boolean;
	disabled?: boolean;
}

const CounterCircleView = styled.div<ICounterCircleViewProps>`
	width: 27.5px;
	height: 27.5px;
	border-radius: 100%;
	border: 1.5px solid
		${({ disabled }) =>
			disabled ? COLORS.disabledPrimaryColor : COLORS.primaryColor};
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	position: relative;

	&::before,
	&::after {
		content: '';
		display: block;
		width: 13px;
		height: 1.5px;
		border-radius: 5px;
		background-color: ${({ disabled }) =>
			disabled ? COLORS.disabledPrimaryColor : COLORS.primaryColor};
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&::after {
		display: ${({ plus }) => (plus ? 'block' : 'none')};
		width: 1.5px;
		height: 13px;
	}
`;

interface ICounterProps {
	className?: string;
	title?: string | null;
	value: number;
	max?: number;
	min?: number;
	onChange: (n: number) => void;
}

export const Counter: FC<ICounterProps> = ({
	className,
	title,
	value = 1,
	max = null,
	min = 1,
	onChange,
}) => {
	const [inputValue, setInputValue] = useState<string>('');

	useEffect(() => {
		setInputValue(value.toString());
	}, [value]);

	const handleInput = (e: ChangeEvent<HTMLInputElement>): void => {
		onChange(+e.target.value);
	};

	const handleMinus = (): void => {
		onChange(value - 1);
	};

	const handlePlus = (): void => {
		onChange(value + 1);
	};

	return (
		<div className={['counter', className].join(' ').trim()}>
			{!!title && <CounterTitleView>{title}</CounterTitleView>}
			<CounterContainerView>
				<CounterCircleView
					minus
					disabled={value <= min}
					onClick={handleMinus}
				/>
				<input
					type="number"
					value={inputValue}
					onChange={handleInput}
				/>
				<CounterCircleView
					plus
					disabled={max != null && value >= max}
					onClick={handlePlus}
				/>
			</CounterContainerView>
		</div>
	);
};
