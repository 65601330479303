import { useLazyQuery } from '@apollo/client';
import {
	FC,
	useContext,
	useEffect,
	useMemo,
	useState,
	useTransition,
} from 'react';
import { ContainerView } from '../../components/Container/Container.view';
import { Spinner } from '../../components/Spinner/Spinner';
import { TypoHeadline3 } from '../../components/styleguide/typography';
import {
	GET_DETAILS_OF_ORDER_QUERY,
	GET_OPEN_POSITIONS,
	GET_SAP_ORDER_HISTORY_QUERY,
	IGetDetailsOfOrderQueryResponse,
	IGetSapOrderHistoryQueryResponse,
	OpenPosition,
	OpenPositionResponse,
} from '../../graphql/queries/customer';
import { IOrder, ISapOrder } from '../../models/IOrder';
import { formatDateToString } from '../../utils/helpers';
import { Searchbar } from '../../components/Searchbar/Searchbar';
import { TabContainerView, TabItemView } from '../../components/Tabs/Tabs';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../../components/Dropdown/Dropdown';
import { Input } from '../../components/Input/Input';
import WebshopOrderList from './components/WebshopOrderList';
import OrderArchiveList from './components/OrderArchiveList';
import OpenPositionList from './components/OpenPositionList';

import styles from './OrderHistoryPage.module.scss';
import { AppSettingsContext } from '../../App';

interface IDateFilter {
	from: string;
	to: string;
}

interface IOrderState<T> {
	orders: T[];
	searchValue: string;
}

enum Tabs {
	WebshopOrders,
	OrderArchive,
	OpenPositions,
}

export const OrderHistoryPage: FC = () => {
	const { t } = useTranslation();

	const appSettings = useContext(AppSettingsContext);

	const [isPending, startTransition] = useTransition();

	const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.WebshopOrders);

	const [dateFilter, setDateFilter] = useState<IDateFilter>({
		from: '',
		to: '',
	});

	const [shopOrders, setShopOrders] = useState<IOrderState<IOrder>>({
		orders: [],
		searchValue: '',
	});

	const [sapOrders, setSapOrders] = useState<IOrderState<ISapOrder>>({
		orders: [],
		searchValue: '',
	});

	const [openPositions, setOpenPositions] = useState<
		IOrderState<OpenPosition>
	>({
		orders: [],
		searchValue: '',
	});

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [fetchOrdersHistory] = useLazyQuery<IGetDetailsOfOrderQueryResponse>(
		GET_DETAILS_OF_ORDER_QUERY,
		{
			onCompleted(data) {
				const customerOrders: IOrder[] = data.customer.orders.items;
				const orders = customerOrders
					.slice()
					.sort(
						(a, b) =>
							new Date(b.order_date).getTime() -
							new Date(a.order_date).getTime(),
					);
				setShopOrders(so => ({ ...so, orders }));
				setIsLoading(false);
			},
			onError(error) {
				console.error('GET_DETAILS_OF_ORDER_QUERY error:>>', error);
			},
			fetchPolicy: 'cache-and-network',
		},
	);

	const [fetchSapOrdersHistory] =
		useLazyQuery<IGetSapOrderHistoryQueryResponse>(
			GET_SAP_ORDER_HISTORY_QUERY,
			{
				onCompleted(data) {
					const ordersList: ISapOrder[] = data.getDonglesList;
					const orders = ordersList
						.slice()
						.sort(
							(a, b) =>
								new Date(b.sell_date).getTime() -
								new Date(a.sell_date).getTime(),
						);
					setSapOrders(so => ({ ...so, orders }));
					setIsLoading(false);
				},
				onError(error) {
					console.error(
						'GET_SAP_ORDER_HISTORY_QUERY error:>>',
						error,
					);
					setIsLoading(false);
				},
				fetchPolicy: 'cache-and-network',
			},
		);

	const [fetchOpenPositions] = useLazyQuery<OpenPositionResponse>(
		GET_OPEN_POSITIONS,
		{
			onCompleted(data) {
				const orders = data.open_positions
					.slice()
					.sort(
						(a, b) =>
							new Date(b.dueDate).getTime() -
							new Date(a.dueDate).getTime(),
					);
				setOpenPositions(op => ({ ...op, orders }));
				setIsLoading(false);
			},
			onError(error) {
				console.error('GET_OPEN_POSITIONS error:>>', error);
				setIsLoading(false);
			},
			fetchPolicy: 'cache-and-network',
		},
	);

	useEffect(() => {
		setShopOrders(so => ({
			...so,
			searchValue: '',
		}));

		setSapOrders(so => ({
			...so,
			searchValue: '',
		}));

		appSettings?.open_positions_enabled &&
			setOpenPositions(so => ({
				...so,
				searchValue: '',
			}));

		setDateFilter({ from: '', to: '' });

		(!sapOrders.orders.length ||
			!shopOrders.orders.length ||
			!openPositions.orders.length) &&
			setIsLoading(true);

		switch (currentTab) {
			case Tabs.WebshopOrders:
				!shopOrders.orders.length && fetchOrdersHistory();
				!!shopOrders.orders.length && setIsLoading(false);
				break;
			case Tabs.OrderArchive:
				!sapOrders.orders.length && fetchSapOrdersHistory();
				!!sapOrders.orders.length && setIsLoading(false);
				break;
			case Tabs.OpenPositions:
				!openPositions.orders.length && fetchOpenPositions();
				!!openPositions.orders.length && setIsLoading(false);
				break;
		}
	}, [currentTab]);

	const handleTabs = (selectedTab: Tabs): void => {
		startTransition(() => {
			setCurrentTab(selectedTab);
		});
	};

	const handleInputChange = (value: string): void => {
		switch (currentTab) {
			case Tabs.WebshopOrders:
				startTransition(() => {
					setShopOrders(so => ({
						...so,
						searchValue: value,
					}));
				});
				break;
			case Tabs.OrderArchive:
				startTransition(() => {
					setSapOrders(so => ({
						...so,
						searchValue: value,
					}));
				});
				break;
			case Tabs.OpenPositions:
				startTransition(() => {
					setOpenPositions(so => ({
						...so,
						searchValue: value,
					}));
				});
				break;
		}
	};

	const handleDateFilter = (value: string, type: 'from' | 'to'): void => {
		setDateFilter(df => ({
			...df,
			[type]: value,
		}));
	};

	// type guard for IOrder
	const isIOrder = (
		orders: IOrder[] | ISapOrder[] | OpenPosition[],
	): orders is IOrder[] =>
		!!(
			(
				orders.length &&
				Object.prototype.hasOwnProperty.call(orders[0], 'sap_order_id')
			) // TODO: make this nice again
		);

	// type guard for ISapOrder
	const isISapOrder = (
		orders: IOrder[] | ISapOrder[] | OpenPosition[],
	): orders is ISapOrder[] =>
		!!(
			(
				orders.length &&
				Object.prototype.hasOwnProperty.call(orders[0], 'sell_date')
			) // TODO: make this nice again
		);

	// type guard for OpenPosition
	const isOpenPosition = (
		orders: IOrder[] | ISapOrder[] | OpenPosition[],
	): orders is OpenPosition[] => {
		return !!(
			(
				orders.length &&
				Object.prototype.hasOwnProperty.call(
					orders[0],
					'DocumentNumber',
				)
			) // TODO: make this nice again
		);
	};

	const checkDateFilter = (
		orderDateTime: number,
		filter: IDateFilter,
	): boolean => {
		if (filter.from && !filter.to) {
			return orderDateTime >= new Date(filter.from).setHours(0, 0, 0, 0);
		}
		if (!filter.from && filter.to) {
			return orderDateTime <= new Date(filter.to).setHours(0, 0, 0, 0);
		}
		return (
			orderDateTime >= new Date(filter.from).setHours(0, 0, 0, 0) &&
			orderDateTime <= new Date(filter.to).setHours(0, 0, 0, 0)
		);
	};

	const filterOrdersByDate = useMemo(() => {
		return (
			orders: IOrder[] | ISapOrder[] | OpenPosition[],
		): IOrder[] | ISapOrder[] | OpenPosition[] => {
			if (!dateFilter.from && !dateFilter.to) {
				return orders;
			}

			if (isIOrder(orders)) {
				return orders.filter(order => {
					const orderDateTime = new Date(order.order_date).setHours(
						0,
						0,
						0,
						0,
					);
					return checkDateFilter(orderDateTime, dateFilter);
				});
			}
			if (isISapOrder(orders)) {
				return orders.filter(order => {
					const orderDateTime = new Date(order.sell_date).setHours(
						0,
						0,
						0,
						0,
					);
					return checkDateFilter(orderDateTime, dateFilter);
				});
			}
			if (isOpenPosition(orders)) {
				return orders.filter(order => {
					const orderDateTime = new Date(order.dueDate).setHours(
						0,
						0,
						0,
						0,
					);
					return checkDateFilter(orderDateTime, dateFilter);
				});
			}
			return orders;
		};
	}, [
		shopOrders.orders,
		sapOrders.orders,
		openPositions.orders,
		dateFilter.from,
		dateFilter.to,
	]);

	const searchOrders = useMemo(
		() =>
			(
				orders: IOrder[] | ISapOrder[] | OpenPosition[],
			): IOrder[] | ISapOrder[] | OpenPosition[] => {
				if (isISapOrder(orders) && sapOrders.searchValue) {
					return orders.filter(
						item =>
							item.sales_order_name
								.toLowerCase()
								.includes(
									sapOrders.searchValue.toLowerCase(),
								) ||
							item.dongle_id
								.toLowerCase()
								.includes(sapOrders.searchValue.toLowerCase()),
					);
				}

				if (isIOrder(orders) && shopOrders.searchValue) {
					return orders.filter(item =>
						item.items.find(fnd =>
							fnd.product_name
								.toLowerCase()
								.includes(shopOrders.searchValue.toLowerCase()),
						),
					);
				}

				if (isOpenPosition(orders) && openPositions.searchValue) {
					return orders.filter(item =>
						item.invoiceNumber
							.toLowerCase()
							.includes(openPositions.searchValue.toLowerCase()),
					);
				}

				return orders;
			},
		[
			shopOrders.orders,
			sapOrders.orders,
			openPositions.orders,
			shopOrders.searchValue,
			sapOrders.searchValue,
			openPositions.searchValue,
		],
	);

	const filterOrders = (
		orders: IOrder[] | ISapOrder[] | OpenPosition[],
	): IOrder[] | ISapOrder[] | OpenPosition[] =>
		searchOrders(filterOrdersByDate(orders));

	const filteredOrders = useMemo(
		() => filterOrders(shopOrders.orders) as IOrder[],
		[shopOrders.orders, dateFilter, shopOrders.searchValue],
	);

	const filteredSapOrders = useMemo(
		() => filterOrders(sapOrders.orders) as ISapOrder[],
		[sapOrders.orders, dateFilter, sapOrders.searchValue],
	);

	const filteredOpenPositions = useMemo(
		() => filterOrders(openPositions.orders) as OpenPosition[],
		[openPositions.orders, dateFilter, openPositions.searchValue],
	);

	function getSelectedTabSearchValue(tab: Tabs): string {
		switch (tab) {
			case Tabs.OrderArchive:
				return sapOrders.searchValue;
			case Tabs.WebshopOrders:
				return shopOrders.searchValue;
			case Tabs.OpenPositions:
				return openPositions.searchValue;
		}
	}

	function getDateFilterString(): string | undefined {
		if (!dateFilter.from && !dateFilter.to) {
			return undefined;
		}
		const fromDate = formatDateToString(new Date(dateFilter.from));
		const toDate = formatDateToString(new Date(dateFilter.to));
		return `${fromDate} — ${toDate}`;
	}

	return (
		<ContainerView className={styles.history__group}>
			<div className={styles['history__tab-group']}>
				<div className={styles.history__filters}>
					<Searchbar
						className={styles.history__searchbar}
						label={t('order_history_fliter_search_input_label')}
						value={getSelectedTabSearchValue(currentTab)}
						onChange={value => handleInputChange(value)}
					/>
					<div className={styles['dropdown-group']}>
						{currentTab == Tabs.WebshopOrders && (
							<Dropdown
								description={t(
									'order_history_fliter_date_label',
								)}
								selected={getDateFilterString()}
								style={{
									titleStyle: {
										fontFamily:
											'Arial, Helvetica, sans-serif',
									},
									optionsContainerStyle: {
										minWidth: 'min-content',
										left: 'auto',
									},
								}}
							>
								<div>
									<div
										className={styles.datepicker}
										onClick={e => {
											e.stopPropagation();
										}}
									>
										<Input
											className={styles.datepicker__input}
											type="date"
											value={dateFilter.from}
											onChange={v =>
												handleDateFilter(v, 'from')
											}
											style={{
												opacity: dateFilter.from
													? 1
													: 0,
											}}
										/>
										<div
											className={styles.datepicker__label}
											style={{
												opacity: dateFilter.from
													? 0
													: 1,
											}}
										>
											{t('from_typo')}
										</div>
									</div>

									<div
										className={styles.datepicker}
										onClick={e => {
											e.stopPropagation();
										}}
									>
										<Input
											className={styles.datepicker__input}
											type="date"
											value={dateFilter.to}
											onChange={v =>
												handleDateFilter(v, 'to')
											}
											style={{
												opacity: dateFilter.to ? 1 : 0,
											}}
										/>
										<div
											className={styles.datepicker__label}
											style={{
												opacity: dateFilter.to ? 0 : 1,
											}}
										>
											{t('to_typo')}
										</div>
									</div>
								</div>
							</Dropdown>
						)}
					</div>
				</div>
				<TabContainerView className={styles['history__tab-group']}>
					<div>
						<TabItemView
							onClick={() => handleTabs(Tabs.WebshopOrders)}
							active={currentTab == Tabs.WebshopOrders}
						>
							<TypoHeadline3>
								{t('webshop_orders_typo')}
							</TypoHeadline3>
						</TabItemView>
						<TabItemView
							onClick={() => handleTabs(Tabs.OrderArchive)}
							active={currentTab == Tabs.OrderArchive}
						>
							<TypoHeadline3>
								{t('order_archive_typo')}
							</TypoHeadline3>
						</TabItemView>
						{appSettings?.open_positions_enabled && (
							<TabItemView
								onClick={() => handleTabs(Tabs.OpenPositions)}
								active={currentTab == Tabs.OpenPositions}
							>
								<TypoHeadline3>
									{t('open_positions_typo')}
								</TypoHeadline3>
							</TabItemView>
						)}
					</div>
					<hr />
				</TabContainerView>

				{isLoading || isPending ? (
					// TODO: position of spinner is broken!
					<div className={styles['history__spinner-container']}>
						<Spinner
							style={{
								marginTop: '25%',
								position: 'relative',
							}}
						/>
					</div>
				) : (
					<>
						{currentTab == Tabs.WebshopOrders && (
							<WebshopOrderList filteredOrders={filteredOrders} />
						)}
						{currentTab == Tabs.OrderArchive && (
							<OrderArchiveList
								filteredSapOrders={filteredSapOrders}
							/>
						)}
						{currentTab == Tabs.OpenPositions && (
							<OpenPositionList
								filteredOpenPositions={filteredOpenPositions}
							/>
						)}
					</>
				)}
			</div>
		</ContainerView>
	);
};
