import { BillingPage } from '../pages/BillingPage/BillingPage';
import { Dashboard } from '../pages/Dashboard/Dashboard';
import { EntrancePage } from '../pages/EntrancePage/EntrancePage';
import { Error404 } from '../pages/Error404/Error404';
import { OrderHistoryPage } from '../pages/OrderHistoryPage/OrderHistoryPage';
import { OverviewPage } from '../pages/OverviewPage/OverviewPage';
import { ProductDetailPage } from '../pages/ProductDetailPage/ProductDetailPage';
import { ProductListPage } from '../pages/ProductListPage/ProductListPage';
import { MessagePage } from '../pages/MessagePage/MessagePage';
import { MVLoginRedirectPage } from '../pages/MVLoginRedirectPage/MVLoginRedirectPage';
import { ForceAuthRedirectPage } from '../pages/ForceAuthRedirectPage/ForceAuthRedirectPage';

export enum ROUTER_PATH {
	dashboard = '/dashboard',
	products = '/products',
	productDetails = '/products/:productId',
	billing = '/billing',
	overview = '/overview',
	orderHistory = '/history',
	message = '/message',
	mvloginRedirect = '/mvlogin-redirect',
	forceAuth = '/force-auth',
}

export const notLoggedRoutes = [
	{ path: '/:lang' + ROUTER_PATH.dashboard, element: Dashboard },
	{
		path: '/:lang' + ROUTER_PATH.message,
		element: MessagePage,
	},
	{
		path: '/:lang' + ROUTER_PATH.mvloginRedirect,
		element: MVLoginRedirectPage,
	},
	{
		path: '/:lang' + ROUTER_PATH.forceAuth,
		element: ForceAuthRedirectPage,
	},
	{
		path: '*',
		element: EntrancePage,
	},
];

export const routes = [
	{ path: '/:lang' + ROUTER_PATH.dashboard, element: Dashboard },
	{ path: '/:lang' + ROUTER_PATH.products, element: ProductListPage },
	{ path: '/:lang' + ROUTER_PATH.productDetails, element: ProductDetailPage },
	{ path: '/:lang' + ROUTER_PATH.billing, element: BillingPage },
	{ path: '/:lang' + ROUTER_PATH.overview, element: OverviewPage },
	{ path: '/:lang' + ROUTER_PATH.orderHistory, element: OrderHistoryPage },
	{ path: '/:lang' + ROUTER_PATH.message, element: MessagePage },
	{
		path: '/:lang' + ROUTER_PATH.mvloginRedirect,
		element: MVLoginRedirectPage,
	},
	{
		path: '/:lang' + ROUTER_PATH.forceAuth,
		element: ForceAuthRedirectPage,
	},
	{ path: '*', element: Error404 },
];
