import { FC, useState } from 'react';
import styled from 'styled-components';
// import { ReactComponent as PencilIcon } from '../../../assets/icons/pencil.svg';
import { MVTHeadline2 } from '../../../../components/styleguide/typography';
import { BillingNoticeView } from '../../../BillingPage/BillingPage';
import { GRID_BREAKPOINTS } from '../../../../components/styleguide/variables';
import {
	GET_COUNTRIES_QUERY,
	IGetCountriesQueryResponse,
} from '../../../../graphql/queries/cart';
import { useQuery } from '@apollo/client';
import { OverviewOfferForm } from './OverviewOfferForm/OverviewOfferForm';
import { Switch } from '../../../../components/Switch/Switch';
import { useTranslation } from 'react-i18next';
import { RequestDeliveryDate } from '../../../../components/RequestDeliveryDate/RequestDeliveryDate';

const OverviewOfferInfoContainerView = styled.div`
	display: grid;
	gap: 35px;
	margin-bottom: 60px;

	& > div,
	.overview__offer-addresses-container > div {
		display: grid;
		gap: 20px;
	}

	.overview {
		&__offer-addresses-container {
			display: grid;
			grid-template-columns: 1fr;
			align-items: start;
			gap: 25px;

			@media screen and (min-width: ${GRID_BREAKPOINTS.$sm}px) {
				grid-template-columns: 1fr 1fr;
			}
		}
	}
`;

export enum OFFER_FORM_TYPES {
	billingAddress = 'billingAddress',
	deliveryAddress = 'deliveryAddress',
}

interface IOfferState {
	isDesiredDate: boolean;
	isDistributor: boolean;
}

export const OverviewOfferInfo: FC = () => {
	const { t } = useTranslation();

	const [offerState, setOfferState] = useState<IOfferState>({
		isDesiredDate: false,
		isDistributor: false,
	});

	const countries = useQuery<IGetCountriesQueryResponse>(
		GET_COUNTRIES_QUERY,
		{
			fetchPolicy: 'cache-and-network',
		},
	);

	return (
		<OverviewOfferInfoContainerView className="overview__offer">
			<div className="overview__offer-addresses-container">
				<div>
					<MVTHeadline2>
						{t('order_overview_page_offer_billing_headline')}
					</MVTHeadline2>
					<OverviewOfferForm
						countries={countries.data?.countries ?? []}
						formType={OFFER_FORM_TYPES.billingAddress}
					/>
				</div>
				<div>
					<MVTHeadline2>
						{t('order_overview_page_offer_delivery_headline')}
					</MVTHeadline2>
					<OverviewOfferForm
						countries={countries.data?.countries ?? []}
						formType={OFFER_FORM_TYPES.deliveryAddress}
					/>
				</div>
			</div>

			<div className="payment">
				<MVTHeadline2>
					{t('billing_payment_payment_headline')}
				</MVTHeadline2>
				<BillingNoticeView>
					{t('billing_payment_payment_description')}
				</BillingNoticeView>
				<Switch checked disabled>
					{t('billing_form_invoice_typo')}
				</Switch>
			</div>

			<div className="overview__offer-shipment">
				<MVTHeadline2>
					{t('order_overview_page_offer_shipment_headline')}
				</MVTHeadline2>
				<BillingNoticeView
					dangerouslySetInnerHTML={{
						__html: t(
							'order_overview_page_offer_shipment_description',
						),
					}}
				/>
			</div>

			<RequestDeliveryDate
				checked={offerState.isDesiredDate}
				onChange={() =>
					setOfferState(state => ({
						...state,
						isDesiredDate: !state.isDesiredDate,
					}))
				}
			/>
			{offerState.isDistributor && (
				<div className="overview__offer-licensee-info">
					<MVTHeadline2>
						{t('order_overview_page_offer_licensee_headline')}
					</MVTHeadline2>
					<BillingNoticeView
						dangerouslySetInnerHTML={{
							__html: t(
								'order_overview_page_offer_licensee_description',
							),
						}}
					/>
				</div>
			)}
		</OverviewOfferInfoContainerView>
	);
};
