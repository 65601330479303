import { ReactElement } from 'react';
import {
	MVTHeadline2,
	PositionHeadLine,
	PositionView,
} from '../../../components/styleguide/typography';
import { t } from 'i18next';
import styles from '../OrderHistoryPage.module.scss';
import { OpenPosition } from '../../../graphql/queries/customer';
import { formatDateToString } from '../../../utils/helpers';
import { formatNumber2Price } from '../../../utils/price';

type OrderArchiveListProps = {
	filteredOpenPositions: OpenPosition[];
};

const emptyListMessage = (
	<div className="history__empty">
		<MVTHeadline2>{t('order_history_page_no_data_typo')}</MVTHeadline2>
	</div>
);

export default function OpenPositionList({
	filteredOpenPositions,
}: OrderArchiveListProps): ReactElement {
	const checkDueData = (date: Date): ReactElement => {
		const today = new Date();
		const overdue = today > date;
		return (
			<PositionView
				className={
					overdue ? styles.history__open_positions_overdue : undefined
				}
			>
				{formatDateToString(date)}
			</PositionView>
		);
	};

	return (
		<div className={styles.history__group}>
			<div
				className={`${styles.history__open_positions} ${styles.position_data}`}
			>
				<PositionHeadLine>
					{t('open_positions_due_date')}
				</PositionHeadLine>
				<PositionHeadLine>
					{t('open_positions_invoice_number')}
				</PositionHeadLine>
				<PositionHeadLine>
					{t('open_positions_amount')}
				</PositionHeadLine>
			</div>
			{filteredOpenPositions.length
				? filteredOpenPositions.map(order => (
						<div
							key={order.invoiceNumber}
							className={styles.position__container}
						>
							<div
								className={`${styles.history__open_positions} ${styles.position_data}`}
							>
								{checkDueData(new Date(order.dueDate))}

								<PositionView>
									{order.invoiceNumber}
								</PositionView>

								<PositionView>
									{formatNumber2Price(order.amount)}
								</PositionView>
							</div>
						</div>
					))
				: emptyListMessage}
		</div>
	);
}
