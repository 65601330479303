import {
	CSSProperties,
	FC,
	MouseEvent,
	KeyboardEvent,
	ReactNode,
	useEffect,
	useId,
	useRef,
	useState,
} from 'react';
import styled from 'styled-components';
import { LANG } from '../../models/ILang';
import { Input } from '../Input/Input';
import { COLORS } from '../styleguide/colors';
import { SwitchValue, InputValue } from '../Switch/Switch';
import { ReactComponent as ChevronIcon } from '../../assets/icons/chevron-up.svg';

const DropdownView = styled.div`
	/* height: 45px; */
	display: grid;
	align-content: end;
	background-color: ${COLORS.white};
	font-family: 'Futura';
	font-size: 18px;
	font-weight: 700;
	line-height: 25px;
	text-transform: uppercase;
	border-bottom: 1px solid ${COLORS.primaryColor};
	position: relative;
	cursor: pointer;

	.chevron {
		transform: rotate(180deg);
	}

	.dropdown__options {
		padding: 5px 0;
		background-color: ${COLORS.white};
		border: 1px solid ${COLORS.primaryColor};
		cursor: auto;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 14;
		overflow-y: auto;
		opacity: 0;
		pointer-events: none;
		transition: 0.25s ease;

		& > * {
			display: block;
			cursor: pointer;
			padding: 5px 15px;
		}

		& > .switch {
			width: 100%;
			justify-content: space-between;
			flex-direction: row-reverse;
			font-family: Futura;
		}
	}

	&:is(.dropdown_active) {
		.dropdown__options {
			opacity: 1;
			pointer-events: all;
			top: calc(100% + 3px);
		}

		.chevron {
			transform: rotate(0);
		}
	}
`;

const DropdownTitleView = styled.div`
	padding: 3px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 5px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	span {
		flex: 1;
	}

	svg {
		transition: 0.4s all;
	}
`;

interface IDropdownProps {
	items?: {
		title: string;
		value: SwitchValue | InputValue;
		tooltip?: string;
	}[];
	children: ReactNode;
	onChange?: (
		value: SwitchValue | InputValue | LANG | undefined | null,
	) => void;
	onOpenEffect?: () => void;
	onCloseEffect?: (fn: () => void) => void;
	description?: SwitchValue | InputValue | JSX.Element | null;
	selected?: SwitchValue | InputValue | undefined | null;
	isMultiple?: boolean;
	className?: string;
	style?: {
		containerStyle?: CSSProperties;
		titleStyle?: CSSProperties;
		chevronStyle?: CSSProperties;
		optionsContainerStyle?: CSSProperties;
	};
	inputSearch?: {
		inputLabel?: string | null;
		onChangeInput: (value: string) => void;
		required?: boolean;
	};
	isInvalid?: boolean;
}

enum DROPDOWN_CLASSES {
	dropdown = 'dropdown',
	dropdown_active = 'dropdown_active',
}

export const Dropdown: FC<IDropdownProps> = ({
	items,
	children,
	onChange,
	onOpenEffect,
	description,
	selected,
	isMultiple,
	className,
	style,
	inputSearch,
	isInvalid,
	onCloseEffect,
}) => {
	const dropdownId = useId();

	const ddRef = useRef<HTMLDivElement>(null);

	const [selectedOption, setSelectedOption] = useState<
		SwitchValue | InputValue | undefined | null
	>(selected);

	const [inputValue, setInputValue] = useState('');

	const handleCloseAll = (): void => {
		document
			.querySelectorAll(`.${DROPDOWN_CLASSES.dropdown}`)
			.forEach(dd =>
				dd.classList.remove(DROPDOWN_CLASSES.dropdown_active),
			);
	};

	useEffect(() => {
		if (!inputSearch) {
			document.addEventListener('click', handleCloseAll);

			return () => {
				document.removeEventListener('click', handleCloseAll);
			};
		}
	}, []);

	useEffect(() => {
		setSelectedOption(selected);

		inputSearch &&
			selected &&
			typeof selected === 'string' &&
			setInputValue(selected);
	}, [selected]);

	useEffect(() => {
		onChange && onChange(selectedOption);

		const closeFn = (): void =>
			ddRef.current?.classList.remove(DROPDOWN_CLASSES.dropdown_active);

		!onCloseEffect && closeFn();

		onCloseEffect && onCloseEffect(closeFn);
	}, [selectedOption]);

	const onOpen = (): void => {
		const classList = ddRef.current?.classList;

		const isActive = classList?.contains(DROPDOWN_CLASSES.dropdown_active);

		if (isActive) {
			classList?.remove(DROPDOWN_CLASSES.dropdown_active);
		} else {
			handleCloseAll();

			classList?.add(DROPDOWN_CLASSES.dropdown_active);

			onOpenEffect && onOpenEffect();
		}
	};

	const handleClick = (e: MouseEvent<HTMLDivElement>): void => {
		e.stopPropagation();

		onOpen();
	};

	const handleFocus = (): void => onOpen();

	const handleTabKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
		e.key === 'Tab' && handleCloseAll();
	};

	const invalidClass = isInvalid ? '.invalid-input' : undefined;

	return (
		<DropdownView
			ref={ddRef}
			className={[
				DROPDOWN_CLASSES.dropdown,
				dropdownId,
				invalidClass,
				className,
			]
				.join(' ')
				.trim()}
			style={style?.containerStyle}
			onClick={e => e.stopPropagation()}
		>
			{inputSearch ? (
				<Input
					type="text"
					label={inputSearch.inputLabel}
					required={inputSearch.required}
					value={inputValue}
					onChange={value => {
						setInputValue(value);

						inputSearch.onChangeInput(value);
					}}
					onFocus={handleFocus}
					onKeyDown={handleTabKeyDown}
					labelStyle={{ textTransform: 'capitalize' }}
					isInvalid={isInvalid}
				/>
			) : (
				<DropdownTitleView
					className={`${DROPDOWN_CLASSES.dropdown}__title ${dropdownId}`}
					style={{
						color: isInvalid
							? COLORS.errorColor
							: !selectedOption
								? COLORS.textGray
								: COLORS.primaryColor,
						...style?.titleStyle,
					}}
					onClick={handleClick}
				>
					<span>
						{isMultiple
							? description
							: (items &&
									items.find(
										item => item.value === selectedOption,
									)?.title) ||
								selectedOption ||
								description}
					</span>
					<ChevronIcon
						className="chevron"
						style={style?.chevronStyle}
					/>
				</DropdownTitleView>
			)}
			<div
				className={`${DROPDOWN_CLASSES.dropdown}__options ${dropdownId}`}
				style={{
					minWidth: 'fit-content',
					...style?.optionsContainerStyle,
				}}
				onClick={handleCloseAll}
			>
				{children}
			</div>
		</DropdownView>
	);
};
