import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ICustomerCartItemQueryResponse } from '../../../../models/ICart';
import { OverviewCartItem } from '../../../../components/OverviewCartItem/OverviewCartItem';
import { ROUTER_PATH } from '../../../../router/routes';
import i18n from '../../../../i18n';
import {
	IOfferQuoteItemMutationResponse,
	IOfferQuoteMutationResponse,
} from '../../../../graphql/mutations/offer';

export const OverviewProductGroupView = styled.div`
	display: flex;
	flex-direction: column;
	gap: 25px;
	padding: 20px 0;

	.overview {
		&__discount {
			padding: 0;
			padding-bottom: 20px;
		}
	}
`;

interface IOverviewProductGroupProps {
	isLoading: boolean;
	items: ICustomerCartItemQueryResponse[] | IOfferQuoteItemMutationResponse[];
	offerQuote?: IOfferQuoteMutationResponse;
	// items: ICartItem[];
	// offerQuote?: IOfferQuote;
	// switchHandlers: {
	// 	onRemove: (cartItemUid: string) => void;
	// 	onAdd: (cartItemUid: string) => void;
	// };
}

export const OverviewProductGroup: FC<IOverviewProductGroupProps> = ({
	isLoading,
	items,
	offerQuote,
	// switchHandlers,
}) => {
	const navigate = useNavigate();

	const current_lang = i18n.language;

	const handleEdit = (sku: string): void => {
		!isLoading && navigate(current_lang + ROUTER_PATH.products + '/' + sku);
	};

	// OFFER SWITCH LOGIC:
	// const { onRemove, onAdd } = switchHandlers;

	// const isChecked = (uid: string): boolean => {
	// 	console.log('items:>>', items);

	// 	console.log('oqi:>>', offerQuote?.cart.items);

	// 	return !!offerQuote?.cart.items.find(item => item.cartItemUid === uid);
	// };

	// const handleItemSwitch = (cartItemUid: string): void => {
	// 	console.log('ch:>>', isChecked(cartItemUid));

	// 	if (isChecked(cartItemUid)) {
	// 		onRemove(cartItemUid);
	// 	} else {
	// 		onAdd(cartItemUid);
	// 	}
	// };

	return (
		<OverviewProductGroupView className="overview">
			{!!items.length &&
				items.map(item => (
					<OverviewCartItem
						key={item.product.sku}
						item={item}
						onEdit={
							!offerQuote
								? () => handleEdit(item.product.sku)
								: undefined
						}
					/>
				))}

			{/* <Switch
				key={item.product.sku}
				className="overview__switch"
				checked={isChecked(item.cartItemUid)}
				type={SWITCH_TYPES.radio}
				onChange={() => {
					handleItemSwitch(item.cartItemUid);
					// isChecked(item.cartItemUid);
				}}
				style={{
					width: '100%',
					alignItems: 'flex-start',
					gap: 15,
				}}
			>
				<OverviewCartItem
					item={item}
					onEdit={() => !isLoading && handleEdit(item.product.sku)}
				/>
			</Switch> */}
		</OverviewProductGroupView>
	);
};
