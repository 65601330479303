import { useQuery } from '@apollo/client';
import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AppContext } from '../../App';
import { Button } from '../../components/Buttons/Buttons';
import { ContainerView } from '../../components/Container/Container.view';
import {
	CountryInput,
	ICountry,
} from '../../components/CountryInput/CountryInput';
import { Input } from '../../components/Input/Input';
import { Spinner } from '../../components/Spinner/Spinner';
import { COLORS } from '../../components/styleguide/colors';
import { MVTHeadline2 } from '../../components/styleguide/typography';
import { GRID_BREAKPOINTS } from '../../components/styleguide/variables';
import { Switch } from '../../components/Switch/Switch';
import {
	GET_COUNTRIES_QUERY,
	GET_CUSTOMER_CART,
	IGetCountriesQueryResponse,
	IGetCustomerCartQueryResponse,
} from '../../graphql/queries/cart';
import { PRODUCT_TYPES } from '../../models/IProduct';
import { IBillingForms, IBillingState, TAddressForm } from '../../models/IUser';
import { ROUTER_PATH } from '../../router/routes';
import {
	isInvalidEmail,
	scrollToFirstInvalidControl,
} from '../../utils/helpers';
import { useTranslation } from 'react-i18next';
import { LANG } from '../../models/ILang';
import {
	IGetCustomerQueryResponse,
	GET_CUSTOMER_QUERY,
	IAddressesQueryResponse,
} from '../../graphql/queries/customer';
import { useCheckout } from '../../hooks/useCheckout';
import { RequestDeliveryDate } from '../../components/RequestDeliveryDate/RequestDeliveryDate';

const BillingContainerView = styled.div`
	display: grid;
	gap: 35px;
	width: 100%;

	@media (min-width: ${GRID_BREAKPOINTS.$xs}px) {
		max-width: 80%;
		margin: 0 auto;
	}

	@media (min-width: ${GRID_BREAKPOINTS.$md}px) {
		max-width: 60%;
		margin: 0 auto;
	}

	& > div {
		display: grid;
		gap: 10px;
	}

	.billing__button-container {
		justify-content: center;
	}

	.mvt-button {
		width: 100%;
		margin: 0 auto;

		@media (min-width: ${GRID_BREAKPOINTS.$xs}px) {
			max-width: 350px;
		}
	}
`;

const BillingFormView = styled.form`
	display: flex;
	flex-direction: column;
	gap: 35px;
	width: 100%;

	& > div {
		display: grid;
		gap: 10px;
	}
`;

export const BillingNoticeView = styled.div`
	font-family: Arial, Helvetica, sans-serif;
	font-size: 18px;
	font-weight: 400;

	& > a {
		color: ${COLORS.primaryColor};
		font-weight: 700;
		text-decoration: none;
	}
`;

type TFormTypes = keyof IBillingForms;

type TInputs = keyof TAddressForm;

type TFormErrorsState = Record<TFormTypes, TInputs[]>;

interface IFormState {
	billing: IBillingState;
	// shippingMethod: TShipping;
	formErrors: TFormErrorsState;
}

export const BillingPage: FC = () => {
	const navigate = useNavigate();

	const { t, i18n } = useTranslation();

	const current_lang = i18n.language as LANG;

	const { isLogged } = useContext(AppContext);

	const { billingPageCheckout, loading, availableShippingMethods } =
		useCheckout();

	const [checkoutStep, setCheckoutStep] = useState(2);

	const [isDesiredDate, setIsDesiredDate] = useState<boolean>(false);

	const [form, setForm] = useState<IFormState>({
		billing: {
			billingAddress: {
				default_billing: true,
				default_shipping: false,
				firstname: '',
				lastname: '',
				company: '',
				department: '',
				street: '',
				postcode: '',
				city: '',
				country_code: '',
				email: '',
			},
			deliveryAddress: {
				default_billing: false,
				default_shipping: true,
				firstname: '',
				lastname: '',
				company: '',
				department: '',
				street: '',
				postcode: '',
				city: '',
				country_code: '',
				email: '',
			},
			addressMatch: true,
			poNumber: '',
		},
		formErrors: {
			billingAddress: [],
			deliveryAddress: [],
		},
	});

	const countriesQuery = useQuery<IGetCountriesQueryResponse>(
		GET_COUNTRIES_QUERY,
		{
			onError(error) {
				console.error('GET_COUNTRIES_QUERY error:>>', error);
			},
		},
	);

	const customerQuery = useQuery<IGetCustomerQueryResponse>(
		GET_CUSTOMER_QUERY,
		{
			onCompleted(data) {
				const customer = data.customer;

				const addresses = data.customer.addresses;

				const defaultBillingAddress = addresses.find(
					item => item.default_billing,
				);

				const defaultShippingAddress = addresses.find(
					item => item.default_shipping,
				);

				const getAddress = (
					address: IAddressesQueryResponse | undefined,
				): TAddressForm => {
					if (address)
						return {
							...address,
							street: address.street[0],
							email: customer.email,
						};

					return form.billing.billingAddress;
				};

				setForm(f => ({
					...f,
					billing: {
						...f.billing,
						billingAddress: getAddress(defaultBillingAddress),
						deliveryAddress: getAddress(defaultShippingAddress),
					},
				}));
			},
			onError(error) {
				console.error('GET_CUSTOMER_QUERY error:>>', error);
			},
			fetchPolicy: 'cache-and-network',
		},
	);

	const cartQuery = useQuery<IGetCustomerCartQueryResponse>(
		GET_CUSTOMER_CART,
		{
			fetchPolicy: 'cache-and-network',
			onError(error) {
				console.error('GET_CUSTOMER_CART error:>>', error);
			},
		},
	);

	useEffect(() => {
		isLogged &&
			!cartQuery.loading &&
			!cartQuery.data?.customerCart.items.length &&
			navigate(current_lang + ROUTER_PATH.products);
	}, [cartQuery.loading]);

	useEffect(() => {
		if (
			form.formErrors.billingAddress.length ||
			form.formErrors.deliveryAddress.length
		) {
			scrollToFirstInvalidControl('.invalid-input');
		}
	}, [form.formErrors.billingAddress, form.formErrors.deliveryAddress]);

	const validateForm = (): TFormErrorsState => {
		const errors: TFormErrorsState = {
			billingAddress: [],
			deliveryAddress: [],
		};

		const checkedForms = Object.keys(errors) as TFormTypes[];

		checkedForms.forEach(formType => {
			Object.keys(form.billing[formType]).forEach(item => {
				const input = item as TInputs;

				if (input === 'email' && form.billing[formType][input]) {
					isInvalidEmail(form.billing[formType][input] as string) &&
						errors[formType].push('email');
				}

				if (input !== 'email') {
					!form.billing[formType][input] &&
						errors[formType].push(input);
				}
			});
		});

		setForm(f => ({ ...f, formErrors: errors }));

		return errors;
	};

	const handleContinue = (): void => {
		const cartId = cartQuery.data?.customerCart.id;

		if (cartId) {
			const sameAddress = form.billing.addressMatch;

			const billingPageCheckoutProcess = billingPageCheckout({
				cartId,
				shippable: true,
				billing: {
					...form.billing,
					deliveryAddress: {
						default_billing: false,
						default_shipping: true,
						firstname: sameAddress
							? form.billing.billingAddress.firstname
							: form.billing.deliveryAddress.firstname,
						lastname: sameAddress
							? form.billing.billingAddress.lastname
							: form.billing.deliveryAddress.lastname,
						company: sameAddress
							? form.billing.billingAddress.company
							: form.billing.deliveryAddress.company,
						department: sameAddress
							? form.billing.billingAddress.department
							: form.billing.deliveryAddress.department,
						street: sameAddress
							? form.billing.billingAddress.street
							: form.billing.deliveryAddress.street,
						city: sameAddress
							? form.billing.billingAddress.city
							: form.billing.deliveryAddress.city,
						postcode: sameAddress
							? form.billing.billingAddress.postcode
							: form.billing.deliveryAddress.postcode,
						country_code: sameAddress
							? form.billing.billingAddress.country_code
							: form.billing.deliveryAddress.country_code,
						email: form.billing.deliveryAddress.email,
					},
				},
			});

			if (checkoutStep === 2) {
				const errors = validateForm();

				if (
					!errors.billingAddress.length &&
					!errors.deliveryAddress.length
				) {
					// PART 1
					billingPageCheckoutProcess.addressesCheckout(() => {
						setCheckoutStep(3);
					});
				}
			} else {
				// PART 2
				billingPageCheckoutProcess.methodsCheckout(() =>
					navigate(current_lang + ROUTER_PATH.overview),
				);
			}
		}
	};

	const handleInput = (
		value: string | ICountry,
		formType: TFormTypes,
		inputName: TInputs,
	): void => {
		setForm(f => ({
			...f,
			billing: {
				...f.billing,
				[formType]: {
					...f.billing[formType],
					[inputName]: value,
				},
			},
			formErrors: {
				...f.formErrors,
				[formType]: f.formErrors[formType].filter(
					error => error !== inputName,
				),
			},
		}));
	};

	const isInvalid = (errors: TInputs[], value: TInputs): boolean =>
		errors.includes(value);

	if (
		loading ||
		customerQuery.loading ||
		cartQuery.loading ||
		countriesQuery.loading
	) {
		return <Spinner />;
	}

	return (
		<ContainerView>
			<BillingContainerView className="billing">
				{checkoutStep === 2 && (
					<BillingFormView className="billing__form">
						<div>
							<MVTHeadline2>
								{t('biling_form_billing_form_headline')}
							</MVTHeadline2>
							<BillingNoticeView>
								{t('biling_form_billing_form_description')}
							</BillingNoticeView>
						</div>
						<div className="billing__form-input-group">
							<Input
								label={t(
									'billing_form_input_labels_firstname_typo',
								)}
								required
								value={form.billing.billingAddress.firstname}
								onChange={v =>
									handleInput(
										v,
										'billingAddress',
										'firstname',
									)
								}
								isInvalid={isInvalid(
									form.formErrors.billingAddress,
									'firstname',
								)}
							/>
							<Input
								label={t(
									'billing_form_input_labels_lastname_typo',
								)}
								required
								value={form.billing.billingAddress.lastname}
								onChange={v =>
									handleInput(v, 'billingAddress', 'lastname')
								}
								isInvalid={isInvalid(
									form.formErrors.billingAddress,
									'lastname',
								)}
							/>
							<Input
								label={t(
									'billing_form_input_labels_company_typo',
								)}
								required
								value={form.billing.billingAddress.company}
								onChange={v =>
									handleInput(v, 'billingAddress', 'company')
								}
								isInvalid={isInvalid(
									form.formErrors.billingAddress,
									'company',
								)}
							/>
							<Input
								label={t(
									'billing_form_input_labels_department_typo',
								)}
								tooltip="Geben Sie hier optional Ihre Abteilung an."
								value={form.billing.billingAddress.department}
								onChange={v =>
									handleInput(
										v,
										'billingAddress',
										'department',
									)
								}
							/>
							<Input
								label={t(
									'billing_form_input_labels_address_typo',
								)}
								required
								value={form.billing.billingAddress.street}
								onChange={v =>
									handleInput(v, 'billingAddress', 'street')
								}
								isInvalid={isInvalid(
									form.formErrors.billingAddress,
									'street',
								)}
							/>
							<Input
								label={t(
									'billing_form_input_labels_postcode_typo',
								)}
								required
								value={form.billing.billingAddress.postcode}
								onChange={v =>
									handleInput(v, 'billingAddress', 'postcode')
								}
								isInvalid={isInvalid(
									form.formErrors.billingAddress,
									'postcode',
								)}
							/>
							<Input
								label={t('billing_form_input_labels_city_typo')}
								required
								value={form.billing.billingAddress.city}
								onChange={v =>
									handleInput(v, 'billingAddress', 'city')
								}
								isInvalid={isInvalid(
									form.formErrors.billingAddress,
									'city',
								)}
							/>
							<CountryInput
								label={t(
									'billing_form_input_labels_country_typo',
								)}
								required
								selected={
									form.billing.billingAddress.country_code
								}
								countryList={
									countriesQuery.data?.countries ?? []
								}
								onChange={v =>
									handleInput(
										v.two_letter_abbreviation,
										'billingAddress',
										'country_code',
									)
								}
								isInvalid={isInvalid(
									form.formErrors.billingAddress,
									'country_code',
								)}
							/>
							<Input
								label={t(
									'billing_form_input_labels_email_invoice_typo',
								)}
								value={form.billing.billingAddress.email}
								onChange={v =>
									handleInput(v, 'billingAddress', 'email')
								}
								isInvalid={isInvalid(
									form.formErrors.billingAddress,
									'email',
								)}
							/>
							<Input
								label={t(
									'billing_form_input_labels_email_lisence_typo',
								)}
								value={form.billing.deliveryAddress.email}
								onChange={v =>
									handleInput(v, 'deliveryAddress', 'email')
								}
								isInvalid={isInvalid(
									form.formErrors.deliveryAddress,
									'email',
								)}
							/>
							<Switch
								type="checkbox"
								checked={form.billing.addressMatch}
								onChange={ch =>
									setForm(f => ({
										...f,
										billing: {
											...f.billing,
											addressMatch: ch,
										},
									}))
								}
							>
								{t(
									'billing_form_input_labels_same_address_typo',
								)}
							</Switch>
						</div>

						{!form.billing.addressMatch && (
							<div className="billing__form-input-group">
								<MVTHeadline2>
									{t('biling_form_delivery_form_headline')}
								</MVTHeadline2>
								<Input
									label={t(
										'billing_form_input_labels_firstname_typo',
									)}
									required
									value={
										form.billing.deliveryAddress.firstname
									}
									onChange={v =>
										handleInput(
											v,
											'deliveryAddress',
											'firstname',
										)
									}
									isInvalid={isInvalid(
										form.formErrors.deliveryAddress,
										'firstname',
									)}
								/>
								<Input
									label={t(
										'billing_form_input_labels_lastname_typo',
									)}
									required
									value={
										form.billing.deliveryAddress.lastname
									}
									onChange={v =>
										handleInput(
											v,
											'deliveryAddress',
											'lastname',
										)
									}
									isInvalid={isInvalid(
										form.formErrors.deliveryAddress,
										'lastname',
									)}
								/>
								<Input
									label={t(
										'billing_form_input_labels_company_typo',
									)}
									required
									value={form.billing.deliveryAddress.company}
									onChange={v =>
										handleInput(
											v,
											'deliveryAddress',
											'company',
										)
									}
									isInvalid={isInvalid(
										form.formErrors.deliveryAddress,
										'company',
									)}
								/>
								<Input
									label={t(
										'billing_form_input_labels_department_typo',
									)}
									tooltip="Geben Sie hier optional Ihre Abteilung an."
									value={
										form.billing.deliveryAddress.department
									}
									onChange={v =>
										handleInput(
											v,
											'deliveryAddress',
											'department',
										)
									}
								/>
								<Input
									label={t(
										'billing_form_input_labels_address_typo',
									)}
									required
									value={form.billing.deliveryAddress.street}
									onChange={v =>
										handleInput(
											v,
											'deliveryAddress',
											'street',
										)
									}
									isInvalid={isInvalid(
										form.formErrors.deliveryAddress,
										'street',
									)}
								/>
								<Input
									label={t(
										'billing_form_input_labels_postcode_typo',
									)}
									required
									value={
										form.billing.deliveryAddress.postcode
									}
									onChange={v =>
										handleInput(
											v,
											'deliveryAddress',
											'postcode',
										)
									}
									isInvalid={isInvalid(
										form.formErrors.deliveryAddress,
										'postcode',
									)}
								/>
								<Input
									label={t(
										'billing_form_input_labels_city_typo',
									)}
									required
									value={form.billing.deliveryAddress.city}
									onChange={v =>
										handleInput(
											v,
											'deliveryAddress',
											'city',
										)
									}
									isInvalid={isInvalid(
										form.formErrors.deliveryAddress,
										'city',
									)}
								/>
								<CountryInput
									label={t(
										'billing_form_input_labels_country_typo',
									)}
									required
									selected={
										form.billing.deliveryAddress
											.country_code
									}
									countryList={
										countriesQuery.data?.countries ?? []
									}
									onChange={v =>
										handleInput(
											v.two_letter_abbreviation,
											'deliveryAddress',
											'country_code',
										)
									}
									isInvalid={isInvalid(
										form.formErrors.deliveryAddress,
										'country_code',
									)}
								/>
							</div>
						)}
						<div>
							<MVTHeadline2>
								{t('biling_form_billing_details_headline')}
							</MVTHeadline2>
							<Input
								label={t(
									'billing_form_input_labels_po_number_typo',
								)}
								tooltip="Geben Sie hier Ihre Bestellnummer zur Zuordnung des internen Bestellvorgangs an."
								value={form.billing.poNumber}
								onChange={v =>
									setForm(f => ({
										...f,
										billing: { ...f.billing, poNumber: v },
									}))
								}
							/>
						</div>
					</BillingFormView>
				)}

				{checkoutStep === 3 && (
					<>
						{!!cartQuery.data?.customerCart.items.find(
							item =>
								item.product.product_type ===
								PRODUCT_TYPES.material,
						) &&
							!!availableShippingMethods.length && (
								<div className="shippingMethod">
									<MVTHeadline2>
										{t(
											'billing_shipment_shipment_headline',
										)}
									</MVTHeadline2>
									<BillingNoticeView
										dangerouslySetInnerHTML={{
											__html: `
											${t('billing_shipment_shipment_description')}
										`,
										}}
									/>

									{/* {!!getAvailableShippingMethods()?.length
									? availableShippingMethods.map(
											(ship: IShipping) => (
												<Switch
													key={ship.carrier_code}
													type={SWITCH_TYPES.radio}
													checked={
														form.shippingMethod
															.carrier_code ===
														ship.carrier_code
													}
													onChange={() => {
														setForm(f => ({
															...f,
															shippingMethod:
																ship,
														}));
													}}
													width="100%"
												>
													<div
														style={{
															width: '100%',
															display: 'grid',
															gridGap: 10,
															gridTemplateColumns:
																'1fr 1fr 82px',
														}}
													>
														<div>
															{ship.method_title}
														</div>
														<div
															style={{
																justifySelf:
																	'end',
															}}
														>
															{formatNumber2Price(
																ship.amount,
															)}
														</div>
													</div>
												</Switch>
											),
									  )
									: null} */}
								</div>
							)}

						<RequestDeliveryDate
							checked={isDesiredDate}
							onChange={() => setIsDesiredDate(bool => !bool)}
						/>

						<div className="service">
							<MVTHeadline2>
								{t('billing_service_service_headline')}
							</MVTHeadline2>
							<BillingNoticeView>
								{t('billing_service_service_description')}
							</BillingNoticeView>
						</div>

						<div className="payment">
							<MVTHeadline2>
								{t('billing_payment_payment_headline')}
							</MVTHeadline2>
							<BillingNoticeView>
								{t('billing_payment_payment_description')}
							</BillingNoticeView>
							<Switch checked disabled>
								{t('billing_form_invoice_typo')}
							</Switch>
						</div>
					</>
				)}
				<div className="billing__button-container">
					<Button primary onClick={handleContinue}>
						{`${t('shopping_cart_step_typo')} ${checkoutStep} ${t(
							'shopping_cart_of_typo',
						)} 4`}
					</Button>
				</div>
			</BillingContainerView>
		</ContainerView>
	);
};
