import {
	CSSProperties,
	FC,
	ReactNode,
	useEffect,
	useRef,
	useState,
} from 'react';
import styled from 'styled-components';
import { COLORS } from '../styleguide/colors';

const TooltipView = styled.div`
	width: 18px;
	height: 18px;
	position: relative;
`;

const TooltipDescrView = styled.div`
	width: max-content;
	max-width: 185px;
	padding: 10px;
	position: absolute;
	z-index: 15;
	font-family: 'Arial';
	color: ${COLORS.primaryColor};
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
	text-align: left;
	pointer-events: none;
	word-break: break-word;
	& ul {
		text-align: left;
	}
	ul {
		padding-left: 20px;
	}
`;

interface ITooltipProps {
	children: ReactNode;
	style?: CSSProperties;
}

export const Tooltip: FC<ITooltipProps> = ({ children, style }) => {
	const [position, setPosition] = useState<CSSProperties>({
		left: '0px',
		top: '-70px',
		backgroundColor: COLORS.BG_Tooltip_GrayColor,
		visibility: 'hidden',
		blockSize: 'fit-content',
	});

	const tooltipRef = useRef<HTMLDivElement>(null);

	const tooltipDescrRef = useRef<HTMLDivElement>(null);

	const screenWidth = document.documentElement.clientWidth;

	useEffect(() => {
		// if tooltipDescr goes beyond the screen on the right
		if (
			tooltipDescrRef.current &&
			tooltipDescrRef.current?.getBoundingClientRect().right > screenWidth
		) {
			setPosition({
				...position,
				left: -tooltipDescrRef.current?.getBoundingClientRect().width,
			});
		}
	}, []);

	const positionCalculate = (
		event: React.MouseEvent<HTMLDivElement>,
	): CSSProperties => {
		const newPosition = Object.assign({}, position);

		const tooltipRect = tooltipRef.current?.getBoundingClientRect();

		const rect = event.currentTarget.getBoundingClientRect();

		const tooltipDescrWidth = tooltipDescrRef.current?.clientWidth
			? tooltipDescrRef.current?.clientWidth
			: 150;

		const tooltipDescrHeight = tooltipDescrRef.current?.clientHeight
			? tooltipDescrRef.current?.clientHeight
			: 65;

		if (tooltipDescrHeight > rect.top) {
			newPosition.top = tooltipRect?.height;
		} else {
			newPosition.top = `-${tooltipDescrHeight}px`;
		}

		if (tooltipDescrWidth > screenWidth - rect.right) {
			newPosition.left = `-${
				tooltipDescrWidth - (tooltipRect?.width || 18) / 2
			}px`;
		} else {
			newPosition.left = (tooltipRect?.width || 18) / 2;
		}

		return newPosition;
	};

	const handleInfoBtnClick = (
		event: React.MouseEvent<HTMLDivElement>,
	): void => {
		event.preventDefault();

		event.stopPropagation();

		if (position.visibility === 'hidden') {
			const newPosition = positionCalculate(event);

			setPosition({
				...newPosition,
				visibility: 'visible',
			});
		} else {
			setPosition({
				...position,
				visibility: 'hidden',
			});
		}
	};

	const onMouseEnter = (event: React.MouseEvent<HTMLDivElement>): void => {
		const newPosition = positionCalculate(event);

		setPosition({
			...newPosition,
			visibility: 'visible',
		});
	};

	const onMouseLeave = (): void => {
		setPosition({
			...position,
			visibility: 'hidden',
		});
	};

	return (
		<TooltipView
			className="tooltip"
			ref={tooltipRef}
			onClick={handleInfoBtnClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			style={style}
		>
			<svg
				width="18"
				height="18"
				viewBox="0 0 18 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
					fill="#F7F7F7"
				/>
				<path
					d="M6.8175 6.75C6.99383 6.24875 7.34187 5.82608 7.79997 5.55685C8.25807 5.28762 8.79668 5.1892 9.32039 5.27903C9.8441 5.36886 10.3191 5.64114 10.6613 6.04765C11.0035 6.45415 11.1908 6.96864 11.19 7.5C11.19 9 8.94 9.75 8.94 9.75"
					stroke={COLORS.primaryColor}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M9 12.75H9.00667"
					stroke={COLORS.primaryColor}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>

			<TooltipDescrView
				className="tooltip__descr"
				ref={tooltipDescrRef}
				style={position}
			>
				{children}
			</TooltipDescrView>
		</TooltipView>
	);
};
