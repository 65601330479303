import { FC } from 'react';
import styled from 'styled-components';
import { COLORS } from '../styleguide/colors';
import { ReactComponent as CartIcon } from '../../assets/icons/cart.svg';

const BasketContainerView = styled.div`
	grid-area: basket;
	justify-self: end;
	display: flex;
	align-items: center;
	cursor: pointer;
	height: 100%;
`;

const BasketNumberView = styled.div`
	width: 18px;
	height: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	background-color: ${COLORS.secondaryColor};
	font-family: 'Arial';
	font-size: 12px;
`;

interface IBasketProps {
	quantity: number;
	onClick?: () => void;
}

export const Basket: FC<IBasketProps> = ({ quantity, onClick }) => {
	return (
		<BasketContainerView onClick={onClick} className="cart-icon">
			{!!quantity && <BasketNumberView>{quantity}</BasketNumberView>}

			<CartIcon stroke={COLORS.primaryColor} />
		</BasketContainerView>
	);
};
