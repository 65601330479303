import { gql } from "@apollo/client";
import { IBanner } from "../../models/IBanner";

export interface IGetBannerListResponse {
    getBanners: IBanner[];
}

export const GET_BANNER_LIST_QUERY = gql`
    query {
        getBanners {
            id
            name
            type
            store_code
            expired_at
            content
            font_size
            font_color
            background_color
            link_url
        }
    }
`;