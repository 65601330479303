import {
	GET_APP_SETTINGS_QUERY,
	IAppSettings,
	IAppSettingsQueryResponse,
} from '../graphql/queries/appSettings';
import { useQuery } from '@apollo/client';

/**
 * Hook for fetching the application settings from magento, e.g. feature toggles.
 */
const useAppSettings = (): IAppSettings | null => {
	const { data: response } = useQuery<IAppSettingsQueryResponse>(
		GET_APP_SETTINGS_QUERY,
		{
			fetchPolicy: 'network-only',
		},
	);

	return response ? response.getAppSettings : null;
};

export default useAppSettings;
