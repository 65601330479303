import { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as MagnifyGlass } from '../../assets/icons/magnify-glass.svg';
import { Input } from '../Input/Input';
import { COLORS } from '../styleguide/colors';

const SearchbarView = styled.div`
	/* height: fit-content; */
	display: flex;
	align-items: center;
	gap: 3px;
	border-bottom: 1px solid ${COLORS.primaryColor};

	.searchbar__icon-wrapper {
		height: 100%;
		display: flex;
		align-items: center;
	}

	.input {
		width: 100%;
	}
`;

interface ISearchbarViewProps {
	className?: string;
	value: string | number;
	onChange: (value: string) => void;
	label?: string | null;
	labelStyle?: CSSProperties;
}

export const Searchbar: FC<ISearchbarViewProps> = ({
	className,
	value,
	onChange,
	label,
	labelStyle,
}) => {
	return (
		<SearchbarView className={['searchbar', className].join(' ').trim()}>
			<Input
				style={{
					width: '100%',
					border: 'none',
					textTransform: 'uppercase',
				}}
				label={label}
				labelStyle={{
					fontFamily: 'Arial, Helvetica, sans-serif',
					fontWeight: 700,
					textTransform: 'uppercase',
					...labelStyle,
				}}
				value={value}
				onChange={val => onChange(val.trim())}
			/>

			<div className="searchbar__icon-wrapper">
				<MagnifyGlass
					width={20}
					height={20}
					style={{
						marginTop: label ? 9 : '',
						marginRight: 5,
					}}
				/>
			</div>
		</SearchbarView>
	);
};
