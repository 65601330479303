import { gql } from "@apollo/client";

export interface IGetTranslationsQueryResponse {
    getTranslations: {
        store_code: string;
        short_key: string;
        original_string: string;
        translation: string;
    }[];
}

export const GET_TRANSLATIONS = gql`
	query {
        getTranslations {
            store_code
            short_key
            original_string
            translation
        }
    }
`;
