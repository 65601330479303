import { FC, useContext, useEffect } from 'react';
import { ContainerView } from '../../components/Container/Container.view';
import { Spinner } from '../../components/Spinner/Spinner';
import { useSearchParams } from 'react-router-dom';
import { AppContext, COOKIE_ENTRIES, LOCAL_STORAGE_ENTRIES } from '../../App';
import { useCookies } from 'react-cookie';

export const ForceAuthRedirectPage: FC = () => {
	const [searchParams] = useSearchParams();

	const { authorize } = useContext(AppContext);

	const [, setCookie] = useCookies();

	useEffect(() => {
		const access_token = searchParams.get('token');

		if (access_token) {
			const access_token_expires = 15 * 60 * 1000; // 15 min

			localStorage.setItem(
				LOCAL_STORAGE_ENTRIES.mvt_auth_token,
				access_token,
			);

			setCookie(COOKIE_ENTRIES.access_token, access_token, {
				path: '/',
				expires: new Date(Date.now() + access_token_expires),
			});

			authorize(access_token, {
				forceAuth: true,
			});
		}
	}, []);

	return (
		<ContainerView
			style={{
				display: 'grid',
				justifyContent: 'center',
			}}
		>
			<Spinner />
		</ContainerView>
	);
};
