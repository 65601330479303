import { FC, MouseEvent } from 'react';
import { TypoHeadline2 } from '../styleguide/typography';
import { IBanner } from '../../models/IBanner';

interface IBannerListProps {
	banners: IBanner[];
}

export const BannerList: FC<IBannerListProps> = ({ banners }) => {
	const handleBannerClick = (
		e: MouseEvent<HTMLAnchorElement>,
		url: string,
	): void => {
		e.preventDefault();

		window.open(url, '_blank', 'noopener,noreferrer');
	};

	return (
		<div className="header__banner-list">
			{banners.map(
				banner =>
					!!banner.content && (
						<a
							href={banner.link_url}
							key={banner.id}
							className="header__banner-item"
							onClick={e => handleBannerClick(e, banner.link_url)}
							style={{
								textDecoration: 'none',
								backgroundColor: banner.background_color,
							}}
						>
							<TypoHeadline2
								style={{
									color: banner.font_color,
									fontSize: banner.font_size,
								}}
							>
								{banner.content}
							</TypoHeadline2>
						</a>
					),
			)}
		</div>
	);
};
