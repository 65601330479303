import { ReactElement } from 'react';
import {
	MVTHeadline2,
	PriceView,
} from '../../../components/styleguide/typography';
import { formatDateToString } from '../../../utils/helpers';
import { t } from 'i18next';
import { ISapOrder } from '../../../models/IOrder';

import styles from '../OrderHistoryPage.module.scss';

type OrderArchiveListProps = {
	filteredSapOrders: ISapOrder[];
};

const emptyListMessage = (
	<div className="history__empty">
		<MVTHeadline2>{t('order_history_page_no_data_typo')}</MVTHeadline2>
	</div>
);

export default function OrderArchiveList({
	filteredSapOrders,
}: OrderArchiveListProps): ReactElement {
	return (
		<div>
			{filteredSapOrders.length
				? filteredSapOrders.map(order => (
						<div
							key={order.dongle_id + Math.random()}
							className={styles.history__container}
						>
							<div className={styles.history__data}>
								<div>
									<span>
										{t(
											'order_history_item_order_date_typo',
										)}
									</span>
									<PriceView>
										{formatDateToString(
											new Date(order.sell_date),
										)}
									</PriceView>
								</div>

								{order.sales_order_id && (
									<div>
										<span>
											{t(
												'order_history_item_order_number_typo',
											)}
										</span>
										<PriceView>
											{order.sales_order_id}
										</PriceView>
									</div>
								)}

								<div>
									<span>
										{t(
											'order_history_item_order_dongle_id_typo',
										)}
									</span>
									<PriceView>{order.dongle_id}</PriceView>
								</div>
							</div>
							<div>
								<div className={styles['history__archive']}>
									<div
										className={
											styles['history__archive-name']
										}
									>
										{order.sales_order_name}
									</div>
								</div>
							</div>
						</div>
					))
				: emptyListMessage}
		</div>
	);
}
