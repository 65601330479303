import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Button } from '../../../../components/Buttons/Buttons';
import { Spinner } from '../../../../components/Spinner/Spinner';
import { GRID_BREAKPOINTS } from '../../../../components/styleguide/variables';
import { BillingNoticeView } from '../../../BillingPage/BillingPage';
import { useTranslation } from 'react-i18next';

const OverviewOrderButtonContainerView = styled.div`
	button {
		width: 100%;
		margin: 0 auto;

		@media (min-width: ${GRID_BREAKPOINTS.$xs}px) {
			max-width: 400px;
		}
	}
`;

interface OverviewOrderButtonProps {
	children: ReactNode;
	disabled?: boolean;
	isLoading: boolean;
	onClick: () => void;
}

export const OverviewOrderButton: FC<OverviewOrderButtonProps> = ({
	children,
	disabled,
	isLoading,
	onClick,
}) => {
	const { t } = useTranslation();

	return (
		<OverviewOrderButtonContainerView className="overview__order-button">
			<BillingNoticeView
				style={{
					margin: '0 auto',
					marginBottom: 12,
					textAlign: 'center',
				}}
				dangerouslySetInnerHTML={{
					__html: t(
						'order_overview_page_order_overview_agb_description',
					),
				}}
			/>

			{isLoading ? (
				<Spinner />
			) : (
				<Button
					primary
					className="overview__purchase-btn"
					onClick={onClick}
					disabled={disabled}
				>
					{children}
				</Button>
			)}
		</OverviewOrderButtonContainerView>
	);
};
