import { FC, useEffect, useState } from 'react';
import { isAllowedCountry } from '../../utils/helpers';
import { Dropdown } from '../Dropdown/Dropdown';

export interface ICountry {
	id: string;
	two_letter_abbreviation: string;
	three_letter_abbreviation: string;
	full_name_english: string;
	full_name_locale: string;
}

interface ICountryInputProps {
	countryList: ICountry[];
	selected: string;
	label?: string | null;
	required?: boolean;
	onChange: (country: ICountry) => void;
	isInvalid?: boolean;
}

export const CountryInput: FC<ICountryInputProps> = ({
	countryList,
	selected = '',
	label,
	required,
	onChange,
	isInvalid,
}) => {
	const [chosenCountry, setChosenCountry] = useState<ICountry>();

	const [countryInputValue, setCountryInputValue] = useState('');

	useEffect(() => {
		chosenCountry && onChange(chosenCountry);
	}, [chosenCountry]);

	const allowedCountyList = countryList.filter(country =>
		isAllowedCountry(country.two_letter_abbreviation),
	);

	const filteredCountries = countryInputValue
		? allowedCountyList.filter(
				country =>
					(country.full_name_locale &&
						country.two_letter_abbreviation &&
						country.full_name_locale
							.toLowerCase()
							.includes(countryInputValue.toLowerCase())) ||
					country.full_name_english
						.toLowerCase()
						.includes(countryInputValue.toLowerCase()),
			)
		: allowedCountyList;

	useEffect(() => {
		setChosenCountry(
			filteredCountries.find(
				fc => fc.two_letter_abbreviation === selected,
			),
		);
	}, [selected, countryList]);

	return (
		<div className="dropdown-input">
			<Dropdown
				selected={chosenCountry?.full_name_locale}
				inputSearch={{
					inputLabel: label,
					onChangeInput: value => setCountryInputValue(value),
					required: required,
				}}
				style={{
					containerStyle: { height: 'auto', borderBottom: 'none' },
					optionsContainerStyle: {
						maxHeight: 400,
					},
				}}
				isInvalid={isInvalid}
			>
				{filteredCountries.map(
					country =>
						country.full_name_locale && (
							<div
								key={country.id}
								onClick={() => setChosenCountry(country)}
							>
								{country.full_name_locale}
							</div>
						),
				)}
			</Dropdown>
		</div>
	);
};
