import { IDongleInputError } from '../models/IDongle';
import { findIndexNonUniqueElements } from './helpers';

const dongleRegexp = /^3-\d{7}$/,
	cidRegexp = /^[a-zA-Z0-9]{32,32}/,
	oldDongleRegexp = /^9-[a-fA-F0-9]{8}$/i,
	macRegexp =
		/^([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})$/i;

export const validateDongles = (
	dongles: string[],
	options?: {
		excludeMacAddressInput?: boolean;
		checkUnique?: boolean;
	},
): IDongleInputError[] => {
	const nonUnique: number[] =
		options?.checkUnique !== false
			? findIndexNonUniqueElements<string>(dongles)
			: [];

	return dongles.reduce<IDongleInputError[]>((acc, dongle, i) => {
		const error: IDongleInputError = {
			index: i,
			messages: [],
		};

		if (!dongle) {
			acc.push(error);

			return acc;
		}

		if (
			!options?.excludeMacAddressInput &&
			!dongleRegexp.test(dongle) &&
			!cidRegexp.test(dongle) &&
			!oldDongleRegexp.test(dongle) &&
			!macRegexp.test(dongle)
		) {
			error.messages.push('dongle_and_mac_input_error_message_typo');
		}

		if (
			options?.excludeMacAddressInput === true &&
			((!dongleRegexp.test(dongle) && !oldDongleRegexp.test(dongle)) ||
				(macRegexp.test(dongle) && cidRegexp.test(dongle)))
		) {
			error.messages.push('dongle_input_error_message_typo');
		}

		if (options?.checkUnique !== false && nonUnique.includes(i)) {
			error.messages.push('non_unique_dongle_input_error_message');
		}

		!!error.messages.length && acc.push(error);

		return acc;
	}, []);
};
