import { gql } from "@apollo/client";


export interface IAppSettings {
        language_switcher_enabled: boolean;
        grid_view_switcher_enabled: boolean;
        commitments_enabled: boolean;
        offers_enabled: boolean;
        open_positions_enabled: boolean;
}

export interface IAppSettingsQueryResponse {
    getAppSettings: IAppSettings;
}

export const GET_APP_SETTINGS_QUERY = gql`
    query {
        getAppSettings {
            language_switcher_enabled
            grid_view_switcher_enabled
            commitments_enabled
            offers_enabled
            open_positions_enabled
        }
    }
`;
