export interface ICategory {
	uid: string;
	name: string;
	position: number;
}

export type FilterOption = {
	label: string;
	value: string;
};

export interface IFilterQueryResponse {
	attribute_code: string;
	attribute_options: FilterOption[];
}

export enum PRODUCT_TYPES {
	virtual = 'VirtualProduct',
	simple = 'SimpleProduct',
	downloadable = 'DownloadableProduct',
	material = 'material',
	service = 'service_product',
	contract = 'contract',
}

export enum APPLICATION_TYPES {
	halcon = '35',
	merlic = '36',
	deep_learning_tool = '37',
}

export interface IProduct {
	uid: string;
	sku: string;
	name: string;
	product_type: string;
	application: string;
	version: string;
	dongle_sku: string;
	categories: ICategory[];
	exclude_mac_address: boolean | null; // if true - don't allow to input mac addresses
	position_in_categories: {
		category_uid: string;
		position: number;
	}[];
	image: {
		url: string;
		label: string;
	};
}

export interface IProductDetailed
	extends Omit<
		IProduct,
		'position_in_categories' | 'categories' | 'uid' | 'version'
	> {
	// sap_service_description: string;
	product_tech_headline: string;
	product_tech_description: string;
	product_tech_image: string;
	product_activation_headline: string;
	product_activation_description: string;
	product_pdf: string;
	info_description: string;
}
