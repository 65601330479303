import styled from 'styled-components';
import { COLORS } from '../styleguide/colors';

interface IAnchorViewProps {
	color?: COLORS;
}

export const AnchorView = styled.a<IAnchorViewProps>`
	width: fit-content;
	color: ${({ color }) => (color ? color : COLORS.primaryColor)};
	text-decoration: none;
	cursor: pointer;
`;
