import { FC, MouseEvent } from 'react';
import styled from 'styled-components';
import { ContainerView } from '../Container/Container.view';
import { COLORS } from '../styleguide/colors';
import { GRID_BREAKPOINTS } from '../styleguide/variables';
import { ReactComponent as FooterLogo } from '../../assets/icons/mvt-logo-footer.svg';
import { useTranslation } from 'react-i18next';

const FooterGroupView = styled.div`
	background-color: ${COLORS.primaryColor};
	padding-top: 41px;
	padding-bottom: 22px;

	& > div {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		gap: 50px;
		color: ${COLORS.white};
		font-size: 12px;
		text-transform: uppercase;

		@media (min-width: ${GRID_BREAKPOINTS.$md}px) {
			flex-direction: row;
		}
	}
`;

const FooterListView = styled.ul`
	list-style: none;
	display: flex;
	gap: 20px;
	flex-wrap: wrap;
`;

const FooterItemView = styled.li`
	a {
		color: ${COLORS.white};
		text-decoration: none;
	}
	a.footer__link {
		font-weight: 400;
	}
`;

const FooterCopyrightView = styled.div`
	display: flex;
	gap: 20px;

	& > svg {
		width: 50px;
	}
`;

export const Footer: FC = () => {
	const { t } = useTranslation();

	const handleMenuItemClick = (
		e: MouseEvent<HTMLAnchorElement>,
		url: string,
	): void => {
		e.preventDefault();

		window.open(url, '_blank', 'noopener,noreferrer');
	};

	const footerItems = [
		{
			title: t('footer_imprint_title'),
			url: t('footer_link_imprint'),
		},
		{
			title: t('footer_privacy_policy_title'),
			url: t('footer_link_privacy_policy'),
		},
		{
			title: t('footer_terms_and_conditions_title'),
			url: t('footer_link_terms_and_conditions'),
		},
		{
			title: t('footer_agbs'),
			url: t('footer_link_terms_and_conditions'),
		},
		{
			title: t('footer_cookie_settings_title'),
			url: t('footer_link_cookie_settings'),
		},
	];

	return (
		<FooterGroupView className="mvt-footer">
			<ContainerView>
				<FooterListView>
					{footerItems.map((item, i) => (
						<FooterItemView key={i}>
							<a
								href={item.url}
								onClick={e => handleMenuItemClick(e, item.url)}
							>
								{t(item.title)}
							</a>
						</FooterItemView>
					))}
				</FooterListView>
				<FooterCopyrightView className="mvt-footer__copyright">
					<FooterLogo fill={COLORS.white} />
					Copyright {new Date().getFullYear()} MVTEC Software GMBH
				</FooterCopyrightView>
			</ContainerView>
		</FooterGroupView>
	);
};
