import { gql } from "@apollo/client";
import { IAdditional, IPrice } from "../../models/ICart";
import { IProduct } from "../../models/IProduct";
import { IOfferQuote, IOfferQuoteCart } from "../../models/IOffer";

export interface IOfferQuoteItemMutationResponse {
    id: string;
    product: IProduct;
    quantity: number;
    additional: IAdditional;
    prices: {
        discount_amount: number;
        discount_percent: number;
        fixed_product_taxes: [];
        price: IPrice;
        row_total: IPrice;
        row_total_including_tax: IPrice;
        tax_percent: number;
    }
}


interface IOfferQuoteCartMutationResponse extends Omit<
    IOfferQuoteCart, 'items' | 'billing_address' | 'shipping_addresses'
> {
    items: IOfferQuoteItemMutationResponse[];
}

export interface IOfferQuoteMutationResponse extends Omit<IOfferQuote, 'cart'> {
    cart: IOfferQuoteCartMutationResponse;
}

export interface IGetOfferQuoteMutationResponse {
    getOfferQuote: IOfferQuoteMutationResponse;
}

export const GET_OFFER_QUOTE_MUTATION = gql`
    mutation getOfferQuote(
        $offerId: String!
    ) {
        getOfferQuote(
            offer_id: $offerId
        ) {
            cart {
                id
                items {
                    id
                    product {
                        uid
                        product_type
                        name
                        sku
                        application
                        version
                        image {
                            url
                            label
                        }
                        categories {
                            uid
                            url_key
                            position
                        }
                    }
                    quantity
                    additional {
                        dongle_sku
                        host_id
                        os
                    }
                    prices {
                        fixed_product_taxes {
                            amount {
                                currency
                                value
                            }
                            label
                        }
                        price {
                            currency
                            value
                        }
                        row_total {
                            currency
                            value
                        }
                        row_total_including_tax {
                            currency
                            value
                        }
                        tax_percent
                        discount_percent
                        discount_amount
                    }
                }
                prices {
                    applied_taxes {
                        amount {
                            currency
                            value
                        }
                        label
                    }
                    grand_total {
                        currency
                        value
                    }
                    subtotal_excluding_tax {
                        currency
                        value
                    }
                    total_tax_amount
                    subtotal_including_tax {
                        currency
                        value
                    }
                    subtotal_with_discount_excluding_tax {
                        currency
                        value
                    }
                }
            }
            offer_id
            order_id
            start_date
            end_date
            payment_terms
            delivery_terms
            contact_person
            is_contract_offer
        }
    }
`;
