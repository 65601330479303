import { MouseEvent } from 'react';
import { ALLOWED_COUNTRY_CODES } from '../App';
import { LANG } from '../models/ILang';
import { ROUTER_PATH } from '../router/routes';

export const getLangFromPath = (pathname: string): LANG => {
	const lang = pathname.slice(0, 3);

	return lang as LANG;
};

export const removeLangFromPathname = (pathname: string): ROUTER_PATH => {
	const path = pathname.slice(3);

	if (!path.match(/^\//)) {
		return ('/' + path) as ROUTER_PATH;
	} else {
		return path as ROUTER_PATH;
	}
};

export const onClickAnchor = (
	e: MouseEvent<HTMLAnchorElement>,
	func: () => void,
): void => {
	e.preventDefault();

	func();
};

export const isInvalid = (array: string[], key: string): boolean => {
	if (array.find(k => k === key)) {
		return true;
	} else {
		return false;
	}
};

export const isInvalidEmail = (email: string): boolean => {
	const reg = /^[\w+-.]+@([\w+-]+\.)+[\w-]{2,4}$/g;

	return !email.match(reg);
};

export const scrollToFirstInvalidControl = (
	selector: string,
	offset = 50,
): void => {
	const firstInvalidControl: HTMLElement | null =
		document.querySelector(selector);

	firstInvalidControl &&
		window.scroll({
			top:
				firstInvalidControl.getBoundingClientRect().top +
				window.scrollY -
				offset,
			left: 0,
			behavior: 'smooth',
		});
};

export const formatDateToString = (date: Date): string => {
	if (date instanceof Date && isNaN(date.getTime())) return '';

	const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();

	const month =
		date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;

	return day + '.' + month + '.' + date.getFullYear();
};

export const isAllowedCountry = (country_code: string): boolean =>
	!!Object.values(ALLOWED_COUNTRY_CODES).find(c => c === country_code);

export const isDongleProduct = (name: string): boolean =>
	name.toLowerCase().includes('dongle');

export const findIndexNonUniqueElements = <T>(array: T[]): number[] => {
	const result: number[] = [];

	const elementCountMap = new Map<T, number>();

	array.forEach((element, i) => {
		if (!element) {
			return;
		}

		if (elementCountMap.has(element)) {
			const currentMapElement = elementCountMap.get(element);

			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			!result.includes(currentMapElement!) &&
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				result.push(currentMapElement!);

			!result.includes(i) && result.push(i);
		} else {
			elementCountMap.set(element, i);
		}
	});

	return result;
};
