import styled from 'styled-components';
import { COLORS } from '../styleguide/colors';

export const TabContainerView = styled.div`
	position: relative;

	& > div {
		margin: 0 auto;
		width: 100%;
		display: flex;
		align-items: flex-end;
		gap: 30px;

		& > * {
			cursor: pointer;
		}
	}

	hr {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;
		border: 2px solid ${COLORS.disabledColor};
	}
`;

export const TabItemView = styled.div<{ active: boolean }>`
	padding: 5px 0;
	text-transform: uppercase;
	border-bottom: 4px solid;
	border-bottom-color: ${props =>
		props.active ? COLORS.primaryColor : 'transparent'};
	text-align: center;
	z-index: 2;
`;

export type TTabItem = string | number | JSX.Element;
