import { useLazyQuery, useQuery } from '@apollo/client';
import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AppContext } from '../../App';
import { Button } from '../../components/Buttons/Buttons';
import { ContainerView } from '../../components/Container/Container.view';
import { Counter } from '../../components/Counter/Counter';
import { Spinner } from '../../components/Spinner/Spinner';
import { COLORS } from '../../components/styleguide/colors';
import {
	MVTHeadline1,
	MVTHeadline2,
	TypoHeadline2,
} from '../../components/styleguide/typography';
import { GRID_BREAKPOINTS } from '../../components/styleguide/variables';
import { Switch } from '../../components/Switch/Switch';
import {
	GET_PRODUCT_BY_SKU_QUERY,
	IGetProductBySkuQueryResponse,
} from '../../graphql/queries/product';
import { ICartItem } from '../../models/ICart';
import {
	APPLICATION_TYPES,
	IProductDetailed,
	PRODUCT_TYPES,
} from '../../models/IProduct';
import { ROUTER_PATH } from '../../router/routes';
import { MESSAGE_TYPE } from '../MessagePage/MessagePage';
import { AnchorView } from '../../components/Anchor/AnchorView';
//
import { useTranslation } from 'react-i18next';
import { LANG } from '../../models/ILang';
import {
	GET_CUSTOMER_CART,
	IGetCustomerCartQueryResponse,
} from '../../graphql/queries/cart';
import { useCartMutations } from '../../hooks/useCartMutations';
import { DonglesInputs } from '../../components/DonglesInputs/DonglesInputs';
import { IDongleInputError, IDongleProductError } from '../../models/IDongle';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { OperationSystemSelect } from '../../components/OperationSystemSelect/OperationSystemSelect';
import { scrollToFirstInvalidControl } from '../../utils/helpers';

const PDPContainerView = styled.div`
	display: grid;
	grid-gap: 50px;

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		padding: 0 30px;
		width: 100%;
		margin: 0 auto;
		max-width: 1320px;
	}
`;

interface IPDPProductContainerViewProps {
	isMaterialProduct?: boolean;
	backgroundColor: string;
}

const PDPProductContainerView = styled.div<IPDPProductContainerViewProps>`
	display: grid;
	grid-gap: 20px;
	grid-template-areas:
		'image image'
		'details details'
		'counter counter'
		'key-inputs key-inputs'
		'buy-btn buy-btn';

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		grid-template-columns: 40%;
		grid-template-areas: ${({ isMaterialProduct }) =>
			isMaterialProduct
				? `'name name name'
			'image details details'
			'image counter .'
			'image . buy-btn'`
				: `'name name name'
			'image details details'
			'image key-inputs key-inputs'
			'image counter .'
			'image buy-btn buy-btn'`};
	}

	.pdp__name {
		grid-area: name;
		margin-bottom: 20px;
		display: none;

		@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
			display: block;
		}
	}

	.pdp__image {
		grid-area: image;
		padding-top: 30px;
		padding-bottom: 30px;
		background-color: ${props => props.backgroundColor};
		width: 100vw;
		height: fit-content;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 15px;

		@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
			width: 100%;
			background-color: ${COLORS.BG_GrayColor};
		}

		h1 {
			width: 100%;
			color: ${COLORS.white};
			font-size: 24px;
			text-transform: uppercase;

			@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
				display: none;
			}
		}

		img {
			width: 285px;
			max-width: 100%;
			max-height: 100%;
		}
	}

	.pdp__details {
		grid-area: details;
		margin-top: 20px;

		@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
			margin-top: 0;
			padding-left: 0;
			padding-right: 0;
		}

		h2 {
			margin-bottom: 10px;
		}

		ul {
			margin-top: 0;
			margin-bottom: 0;
			padding-left: 20px;
			font-family: 'Arial';
			font-size: 16px;
			font-weight: 400;
		}

		.pdp__tech > div {
			font-family: Arial, Helvetica, sans-serif;
			font-size: 16px;
			font-weight: 400;
		}

		.pdp__version {
			font-family: 'Arial';
			font-size: 16px;
			font-weight: 400;

			h2 {
				margin-bottom: 10px;
			}

			// options and counter wrapper
			& + div {
				margin-top: 20px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				gap: 20px;

				@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
					flex-direction: column;
					gap: 20px;
				}
			}

			.pdp__info {
				display: flex;
				gap: 6px;

				& > svg {
					margin-top: 3px;
				}

				& > span,
				div {
					flex: 1;

					a {
						color: ${COLORS.primaryColor};
						font-weight: 700;
						text-decoration: none;
					}
				}
			}
		}

		.pdp__options {
			h2 {
				margin-bottom: 10px;
			}

			& > div {
				display: grid;
				grid-template-columns: max-content;
				grid-gap: 15px;

				@media (min-width: ${GRID_BREAKPOINTS.$xs}px) {
					grid-template-columns: repeat(2, min-content) max-content;
				}

				@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
					grid-gap: 25px;
				}
			}
		}
	}

	.pdp__price-notification,
	.pdp__eula {
		color: ${COLORS.primaryColor};
		font-family: Arial, Helvetica, sans-serif;
		font-size: 16px;
		font-weight: 400;
		line-height: 25px;
		/* margin-bottom: 15px; */

		a {
			font-weight: 700;
			text-decoration: underline;
		}
	}

	.pdp__buy-btn {
		grid-area: buy-btn;

		@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
			padding-left: 0;
			padding-right: 0;
			justify-content: flex-end;
		}

		& > button {
			margin-top: auto;
			width: 100%;
			height: fit-content;

			@media (min-width: ${GRID_BREAKPOINTS.$xs}px) {
				width: fit-content;
			}

			@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
				width: auto;
			}
		}
	}
`;

const PDPInputsContainerView = styled(ContainerView)`
	grid-area: key-inputs;

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		padding: 0;
	}

	.pdp__key_number {
		width: 100%;
		padding: 15px 20px;
		border: 1px solid ${COLORS.BG_TileColor};
		display: flex;
		flex-direction: column;
		gap: 20px;

		@media (min-width: ${GRID_BREAKPOINTS.$xs}px) {
			width: fit-content;
		}

		& > div {
			display: flex;
			flex-direction: column;
			gap: 20px;

			@media (min-width: ${GRID_BREAKPOINTS.$xs}px) {
				flex-direction: row;
			}
		}

		&-title {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			span {
				font-size: 12px;
				text-transform: uppercase;
			}
		}

		&-input-group {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 5px;
		}
	}
`;

const PDPCounterWrapperView = styled(ContainerView)`
	grid-area: counter;

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		padding: 0;
	}
`;

const PDPSectionContainerView = styled(ContainerView)`
	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		padding: 0;
	}

	.activation {
		&__container {
			padding-top: 50px;
			display: grid;
			grid-gap: 50px;
			align-content: center;

			@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
				grid-template-columns: 40% 1fr;
			}

			& > div:nth-child(2) {
				display: flex;
				justify-content: center;
				align-items: center;

				@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
					justify-content: flex-start;
				}

				img {
					max-width: 90%;
				}
			}
		}

		& > div {
			align-self: center;
		}

		&__descr {
			align-self: center;
			font-family: Arial, Helvetica, sans-serif;
			font-size: 18px;
			font-weight: 400;
			line-height: 25px;

			a {
				color: ${COLORS.primaryColor};
				font-family: Arial, Helvetica, sans-serif;
				font-size: 18px;
				font-weight: 700;
				line-height: 25px;
				text-decoration: none;
			}
		}
	}
`;

const PDPLoadingContainerView = styled(ContainerView)`
	display: grid;
	grid-column-start: 1;
	grid-column-end: 3;

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		grid-column-start: 2;
	}
`;

interface IDetailPageState
	extends Omit<
		ICartItem,
		'product' | 'cartItemUid' | 'withDongleNumber' | 'withMacAddress'
	> {
	product: IProductDetailed;
	includeDongle: boolean;
	dongleValues: string[];
	dongleErrors: IDongleInputError[];
	// withDongleNumber: {
	// 	chosen: boolean;
	// 	values: string[];
	// };
	// withMacAddress: {
	// 	chosen: boolean;
	// 	values: string[];
	// };
	dongle_sku: string;
}

export const ProductDetailPage: FC = () => {
	const { t, i18n } = useTranslation();

	const navigate = useNavigate();

	const { isLogged, navigateToMessagePage, setOpenedCart, setModal } =
		useContext(AppContext);

	const current_lang = i18n.language as LANG;

	const { productId } = useParams();

	const cartMutations = useCartMutations();

	const [state, setState] = useState<IDetailPageState>({
		product: {
			sku: '',
			product_type: '',
			name: '',
			image: {
				label: '',
				url: '',
			},
			application: '',
			dongle_sku: '',
			exclude_mac_address: false,
			product_tech_headline: '',
			product_tech_description: '',
			product_tech_image: '',
			product_activation_headline: '',
			product_activation_description: '',
			product_pdf: '',
			info_description: '',
		},
		includeDongle: true,
		dongleValues: [],
		dongleErrors: [],
		dongle_sku: '',
		OS: null,
		quantity: 1,
		totalItemPrice: {
			totalPrice: {
				value: 0,
				currency: 'EUR',
			},
			taxPercent: 0,
			totalPriceWithTax: {
				value: 0,
				currency: 'EUR',
			},
		},
	});

	useEffect(() => {
		!isLogged && navigate(current_lang);
	}, []);

	const [getProductBySkuQuery, { loading }] =
		useLazyQuery<IGetProductBySkuQueryResponse>(GET_PRODUCT_BY_SKU_QUERY, {
			variables: {
				sku: productId,
			},
			fetchPolicy: 'network-only',
			onError(error) {
				console.error('GET_PRODUCT_BY_SKU_QUERY error:>>', error);

				navigateToMessagePage(current_lang, {
					type: MESSAGE_TYPE.error,
					title: error.message,
					btnDescr: 'Products',
					redirectUrl: current_lang + ROUTER_PATH.products,
				});
			},
		});

	const cartQuery = useQuery<IGetCustomerCartQueryResponse>(
		GET_CUSTOMER_CART,
		{
			fetchPolicy: 'cache-and-network',
			onCompleted(data) {
				const itemFromCart = data.customerCart.items.find(
					p => p.product.sku === productId,
				);

				if (itemFromCart) {
					const host_id = itemFromCart.additional.host_id;

					setState(s => ({
						...s,
						product: itemFromCart.product,

						includeDongle: host_id.length ? false : true,
						dongleValues: host_id,

						dongle_sku: itemFromCart.additional.dongle_sku,
						OS: itemFromCart.additional.os || null,
						quantity: itemFromCart.quantity || 1,
					}));
				} else {
					getProductBySkuQuery({
						onCompleted(productData) {
							const product = productData.products.items[0];

							setState(s => ({
								...s,
								product,
								dongle_sku:
									product.product_type !==
									PRODUCT_TYPES.material
										? product.dongle_sku ?? ''
										: '',
							}));
						},
					});
				}
			},
		},
	);

	const handleCounter = (quantity: number): void => {
		setState(prevState => ({
			...prevState,
			quantity,
		}));
	};

	const handleDongleSwitch = (includeDongle: boolean): void => {
		setState(prevState => ({
			...prevState,
			includeDongle,
			dongle_sku: includeDongle ? prevState.product.dongle_sku : '',
		}));
	};

	const handleOperatingSystem = (OS: string): void => {
		setState(s => ({
			...s,
			OS,
		}));
	};

	const handleDongles = (value: string | string[], index: number): void => {
		setState(prevState => {
			const newState = { ...prevState };

			newState.dongleErrors = newState.dongleErrors.filter(
				item => item.index !== index,
			);

			let updatedValues = [...newState.dongleValues];

			if (typeof value === 'string') {
				// const str =
				// 	key === 'withMacAddress'
				// 		? value.replace(/[;,\-_:"' ]/g, '')
				// 		: value;

				updatedValues[index] = value;
			} else {
				newState.dongleValues = [];

				newState.quantity = value.length;

				updatedValues = value;
			}

			newState.dongleValues = updatedValues;

			return newState;
		});
	};

	const handleCart = (item: IDetailPageState): void => {
		if (item.quantity <= 0) {
			setModal({
				type: 'warning',
				messages: [t('modal_messages_positive_number_warning')],
			});

			return;
		}

		cartMutations
			.updateCart({
				cart: cartQuery.data,
				items: [
					{
						sku: item.product.sku,
						quantity: item.quantity,
						additional: {
							dongle_sku: item.dongle_sku,
							host_id: item.dongleValues,
							os: item.OS,
						},
						exclude_mac_address: item.product.exclude_mac_address,
						product_type: item.product.product_type,
						dongleErrors: item.dongleErrors,
						osError: !item.OS,
					},
				],
			})
			.then(() => {
				setOpenedCart(true);

				setState(prevState => ({
					...prevState,
					dongleValues: [],
				}));
			})
			.catch((errors: IDongleProductError[]) => {
				setState(prevState => ({
					...prevState,
					dongleErrors: errors[0]?.errors ?? [],
				}));

				scrollToFirstInvalidControl('.invalid-input');
			});
	};

	const backgroundColor = (): COLORS => {
		switch (state.product.application) {
			case APPLICATION_TYPES.merlic:
				return COLORS.blueColor;

			case APPLICATION_TYPES.deep_learning_tool:
				return COLORS.greenColor;

			default:
				return COLORS.orangeColor;
		}
	};

	const eulaText = t('product_detail_pdp_eula_typo').split('{EULA}');

	const dongleTitle = state.product.exclude_mac_address
		? t('product_detail_page_dongle_without_mac_dongle_title_typo')
		: t('product_detail_page_dongle_dongle_title_typo');

	if (loading || cartQuery.loading) return <Spinner />;

	return (
		<PDPContainerView className="pdp">
			<PDPProductContainerView
				isMaterialProduct={false}
				backgroundColor={backgroundColor()}
			>
				<MVTHeadline1 className="pdp__name">
					{state.product.name}
				</MVTHeadline1>
				<ContainerView className="pdp__image">
					<h1>{state.product.name}</h1>
					<img
						src={state.product.image.url}
						alt={state.product.image.label}
					/>
				</ContainerView>
				<ContainerView className="pdp__details">
					{state.product.product_tech_headline && (
						<div className="pdp__tech" style={{ marginBottom: 20 }}>
							<TypoHeadline2>
								{state.product.product_tech_headline}
							</TypoHeadline2>
							<div
								dangerouslySetInnerHTML={{
									__html: state.product
										.product_tech_description,
								}}
							/>
						</div>
					)}

					{state.product.product_type !== PRODUCT_TYPES.material &&
					state.product.application !== APPLICATION_TYPES.merlic ? (
						<div className="pdp__version">
							<TypoHeadline2>
								{t(
									'product_detail_page_version_version_title_typo',
								)}
							</TypoHeadline2>
							<div>
								{t(
									'product_detail_page_version_version_descr_typo',
								)}
							</div>

							<div className="pdp__info">
								<InfoIcon stroke={COLORS.primaryColor} />
								<span
									dangerouslySetInnerHTML={{
										__html: t(
											'product_detail_page_version_info_description',
										),
									}}
								/>
							</div>
							{state.product.info_description && (
								<div className="pdp__info">
									<InfoIcon stroke={COLORS.primaryColor} />
									<div
										className="pdp__info_description"
										dangerouslySetInnerHTML={{
											__html: state.product
												.info_description,
										}}
									/>
								</div>
							)}
						</div>
					) : null}

					<div>
						{state.product.product_type !==
							PRODUCT_TYPES.material &&
							!cartMutations.loading && (
								<div className="pdp__options">
									<TypoHeadline2
										style={{
											maxWidth:
												t(
													'product_detail_page_dongle_dongle_title_first_part_typo',
												).length + 'ch',
										}}
									>
										{dongleTitle}
									</TypoHeadline2>
									<div>
										{state.product.dongle_sku && (
											<>
												<Switch
													type="radio"
													checked={
														state.includeDongle
													}
													tooltip={t(
														'product_detail_page_dongle_dongle_options_tooltip',
													)}
													onChange={() =>
														handleDongleSwitch(true)
													}
												>
													{t(
														'product_detail_page_dongle_dongle_options_title_no',
													)}
												</Switch>
												<Switch
													type="radio"
													checked={
														!state.includeDongle
													}
													onChange={() =>
														handleDongleSwitch(
															false,
														)
													}
												>
													{t(
														'product_detail_page_dongle_dongle_options_title_yes',
													)}
												</Switch>
											</>
										)}

										<OperationSystemSelect
											selected={state.OS}
											onChange={value =>
												handleOperatingSystem(value)
											}
										/>
									</div>
								</div>
							)}
					</div>
				</ContainerView>

				{(!state.includeDongle || !state.product.dongle_sku) &&
					state.product.product_type !== PRODUCT_TYPES.material &&
					!cartMutations.loading && (
						<PDPInputsContainerView className="pdp__key_number">
							<DonglesInputs
								quantity={state.quantity}
								dongles={state.dongleValues}
								handleDonglesInput={handleDongles}
								csvLoadable
								errors={state.dongleErrors}
								excludeMacAddressInput={
									!!state.product.exclude_mac_address
								}
							/>
						</PDPInputsContainerView>
					)}

				{!cartMutations.loading ? (
					<>
						<PDPCounterWrapperView>
							<Counter
								className="pdp__counter"
								title={t('quantity_typo')}
								value={state.quantity}
								onChange={n => handleCounter(n)}
							/>
						</PDPCounterWrapperView>

						<ContainerView
							className="pdp__buy-btn"
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-start',
								gap: 20,
							}}
						>
							<div className="pdp__price-notification">
								{t('product_detail_price_notification_typo')}
							</div>
							<Button primary onClick={() => handleCart(state)}>
								{t('product_detail_in_shopping_cart_typo')}
							</Button>
							{state.product.product_pdf && !!eulaText.length && (
								<div className="pdp__eula">
									{eulaText[0]}
									<AnchorView
										href={state.product.product_pdf}
										target="_blank"
										rel="noopener noreferrer"
									>
										EULA
									</AnchorView>
									{eulaText[1]}
								</div>
							)}
						</ContainerView>
					</>
				) : (
					<PDPLoadingContainerView>
						<Spinner />
					</PDPLoadingContainerView>
				)}
			</PDPProductContainerView>

			{state.product.product_activation_headline &&
				state.product.product_activation_description && (
					<PDPSectionContainerView className="activation">
						<MVTHeadline2>
							{state.product.product_activation_headline}
						</MVTHeadline2>
						<div className="activation__container">
							<div
								className="activation__descr"
								dangerouslySetInnerHTML={{
									__html: state.product
										.product_activation_description,
								}}
							/>
							{state.product.product_tech_image && (
								<div>
									<img
										src={state.product.product_tech_image}
										alt="tech_image"
									/>
								</div>
							)}
						</div>
					</PDPSectionContainerView>
				)}

			{/* <Modal
				type="warning"
				show={!!modalMessages.length}
				onClose={() => setModalMessages([])}
			>
				<div style={{ padding: 20 }}>
					<div
						style={{
							margin: '0 auto',
							width: 'fit-content',
							marginBottom: 25,
						}}
					>
						<WarningIcon
							fill={COLORS.secondaryColor}
							width="100px"
							height="100px"
						/>
					</div>
					<ul style={{ paddingLeft: 25, paddingBottom: 0 }}>
						{modalMessages.map((mm, i) => (
							<li key={i} style={{ marginBottom: 15 }}>
								<TypoHeadline2>{mm}</TypoHeadline2>
							</li>
						))}
					</ul>
				</div>
			</Modal> */}
		</PDPContainerView>
	);
};
