import { FC, MouseEvent, useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '../styleguide/colors';
import { GRID_BREAKPOINTS } from '../styleguide/variables';
import { ReactComponent as WebshopIcon } from './webshop-icon.svg';
import { ReactComponent as DownloadIcon } from './downloads-icon.svg';

export enum TILE_TYPES {
	webshop = 'webshop',
	download = 'download',
	others = 'others',
}

export const TileGroupView = styled.div`
	margin: 0 auto;
	display: grid;
	justify-content: center;
	grid-template-columns: minmax(auto, 450px) minmax(auto, 450px);
	grid-template-areas: 'webshop download';
	grid-gap: 15px;

	@media (min-width: ${GRID_BREAKPOINTS.$xxs}px) {
		grid-gap: 20px;
	}

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		width: 80%;
	}
`;

interface ITileContainerViewProps {
	type: TILE_TYPES;
	isDescription: boolean;
	disabled?: boolean;
}

const TileContainerView = styled.a<ITileContainerViewProps>`
	max-width: 513px;
	grid-area: ${({ type }) => {
		if (type === TILE_TYPES.webshop) return TILE_TYPES.webshop;

		if (type === TILE_TYPES.download) return TILE_TYPES.download;
	}};
	padding: 25px 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;
	color: ${COLORS.white};
	font-size: 16px;
	text-transform: uppercase;
	text-align: center;
	text-decoration: none;
	background-color: ${({ type, isDescription }) => {
		if (type === TILE_TYPES.download) return COLORS.secondaryColor;

		if (type === TILE_TYPES.webshop && !isDescription)
			return COLORS.primaryColor;

		return COLORS.BG_TileColor;
	}};
	cursor: ${({ isDescription }) => (!isDescription ? 'pointer' : '')};
	position: relative;
	overflow: hidden;

	&:hover {
		.tile__descr {
			transform: translateY(0);
		}
	}

	@media (min-width: ${GRID_BREAKPOINTS.$xxs}px) {
		aspect-ratio: 1 / 1;
		font-size: 18px;
	}
`;

const TileIconWrapperView = styled.div`
	height: 60px;
	width: 100%;

	& > svg {
		max-height: 100%;
		max-width: 100%;
	}
`;

interface TileDescriptionViewProps {
	dirty: boolean;
}

const TileDescriptionView = styled.div<TileDescriptionViewProps>`
	width: 100%;
	padding: 10px;
	background-color: ${COLORS.primaryColor};
	font-family: 'Arial';
	color: ${COLORS.white};
	font-size: 14px;
	text-transform: none;
	text-align: center;
	position: absolute;
	bottom: 0;
	transform: translateY(100%);
	pointer-events: none;
	transition: 0.4s all;

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		padding: 15px;
		font-size: 16px;
	}
`;

interface ITileProps {
	type: TILE_TYPES;
	title: string;
	description?: string;
	href?: string;
	onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
	disabled?: boolean;
	target?: string;
	rel?: string;
}

export const Tile: FC<ITileProps> = ({
	type,
	title,
	// icon,
	description,
	href,
	onClick,
	disabled,
	target,
	rel,
}) => {
	const [dirty, setDirty] = useState(false);

	const handleTileClick = (e: MouseEvent<HTMLAnchorElement>): void => {
		if (type !== TILE_TYPES.download) e.preventDefault();

		onClick && onClick(e);

		description && setDirty(true);
	};

	return (
		<TileContainerView
			className="tile"
			type={type}
			isDescription={!!description}
			disabled={disabled}
			href={href}
			onClick={handleTileClick}
			target={target}
			rel={rel}
		>
			<TileIconWrapperView>
				{type === TILE_TYPES.webshop ? (
					<WebshopIcon />
				) : (
					<DownloadIcon />
				)}
			</TileIconWrapperView>
			{title}
			{description && (
				<TileDescriptionView className="tile__descr" dirty={dirty}>
					{description}
				</TileDescriptionView>
			)}
		</TileContainerView>
	);
};
