import { findKey } from 'lodash';
import { FC, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AppContext, COOKIE_ENTRIES } from '../../App';
import { ContainerView } from '../../components/Container/Container.view';
import { COLORS } from '../../components/styleguide/colors';
import { GRID_BREAKPOINTS } from '../../components/styleguide/variables';
import { Tile, TileGroupView, TILE_TYPES } from '../../components/Tile/Tile';
import { ROUTER_PATH } from '../../router/routes';
import { isAllowedCountry } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import {
	IGetCustomerQueryResponse,
	GET_CUSTOMER_QUERY,
} from '../../graphql/queries/customer';

const DashboardContainerView = styled(ContainerView)`
	padding-top: 30px;
`;

const DashboardWelcomeView = styled.div`
	display: none;
	width: 80%;
	margin: 0 auto;
	color: ${COLORS.primaryColor};
	font-weight: 700;
	font-size: 30px;
	line-height: 42px;
	text-transform: uppercase;
	padding-bottom: 80px;
	text-align: center;

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		display: block;
	}
`;

enum SHOP_RESTRICTIONS_NAMES {
	isSapCustomer = 'isSapCustomer',
	countryCode = 'countryCode',
	hasCatalogs = 'hasCatalogs',
	hasInternalDistributors = 'hasInternalDistributors',
}

export const Dashboard: FC = () => {
	const { t, i18n } = useTranslation();

	const current_lang = i18n.language;

	const navigate = useNavigate();

	const [cookies] = useCookies();

	const { isLogged } = useContext(AppContext);

	const user = useQuery<IGetCustomerQueryResponse>(GET_CUSTOMER_QUERY, {
		fetchPolicy: 'cache-and-network',
	});

	const param = {
		[SHOP_RESTRICTIONS_NAMES.isSapCustomer]:
			cookies[COOKIE_ENTRIES.is_sap_customer] === 'true' ? true : false,
		[SHOP_RESTRICTIONS_NAMES.countryCode]: isAllowedCountry(
			cookies[COOKIE_ENTRIES.country_code],
		),
		[SHOP_RESTRICTIONS_NAMES.hasCatalogs]:
			cookies[COOKIE_ENTRIES.has_catalogs] === 'true' ? true : false,
		[SHOP_RESTRICTIONS_NAMES.hasInternalDistributors]:
			cookies[COOKIE_ENTRIES.has_internal_distributors] === 'true' // if "false" -> webshop closed
				? true
				: false,
	};

	const checkRestriction: string | undefined = findKey(
		param,
		(value: boolean) => !value,
	);

	const handleTileClick = (url: string): void => {
		!checkRestriction
			? navigate(url)
			: window.open(url, '_blank', 'noopener,noreferrer');
	};

	return (
		<DashboardContainerView>
			{!isLogged && (
				<DashboardWelcomeView>
					{t('dashboard_welcome_typo')}!
				</DashboardWelcomeView>
			)}

			{isLogged &&
				user.data?.customer.firstname &&
				user.data?.customer.lastname && (
					<DashboardWelcomeView>
						{t('dashboard_welcome_typo')},{' '}
						{user.data.customer.firstname +
							' ' +
							user.data.customer.lastname}
						!
					</DashboardWelcomeView>
				)}

			<TileGroupView>
				<Tile
					type={TILE_TYPES.webshop}
					title={t('dashboard_webshop_title_typo')}
					href={
						!checkRestriction
							? current_lang + ROUTER_PATH.products
							: 'https://www.mvtec.com/company/partner-network'
					}
					onClick={() =>
						handleTileClick(
							!checkRestriction
								? current_lang + ROUTER_PATH.products
								: 'https://www.mvtec.com/company/partner-network',
						)
					}
					description={
						checkRestriction &&
						{
							[SHOP_RESTRICTIONS_NAMES.isSapCustomer]: t(
								'dashboard_is_not_sap_customer_message',
							),
							[SHOP_RESTRICTIONS_NAMES.hasInternalDistributors]:
								t('dashboard_is_not_distributor_message'),
							[SHOP_RESTRICTIONS_NAMES.countryCode]: t(
								'dashboard_not_allowed_country_message',
							),
							[SHOP_RESTRICTIONS_NAMES.hasCatalogs]: t(
								'dashboard_no_catalogs_message',
							),
						}[checkRestriction]
					}
				/>
				<Tile
					type={TILE_TYPES.download}
					title={t('dashboard_download_center_title_typo')}
					href={t('dashboard_download_center_link')}
					target="_blank"
					rel="noopener noreferrer"
				/>
			</TileGroupView>
		</DashboardContainerView>
	);
};
