import { FC, MouseEvent, ReactNode } from 'react';
import styled from 'styled-components';
import { COLORS } from '../styleguide/colors';
import { GRID_BREAKPOINTS } from '../styleguide/variables';
import { ReactComponent as ChevronIcon } from '../../assets/icons/chevron-up.svg';

enum BUTTON_HEIGHT {
	btnHeightDesktop = 45,
	btnHeightMobile = 38,
}

export enum BUTTON_WIDTH {
	auto = 'auto',
	full = '100%',
}

interface IButtonViewProps {
	disabled?: boolean;
	primary?: boolean;
	secondary?: boolean;
	tertiary?: boolean;
	width?: BUTTON_WIDTH;
}

const ButtonView = styled.button<IButtonViewProps>`
	width: ${props => (props.width ? props.width : BUTTON_WIDTH.auto)};
	min-height: ${BUTTON_HEIGHT.btnHeightMobile}px;
	padding: 10px 20px;
	color: ${props => {
		if (props.secondary && !props.disabled) return COLORS.primaryColor;

		if (!props.primary && !props.secondary && !props.tertiary)
			return COLORS.white;

		if ((props.disabled || props.primary) && !props.tertiary) {
			return COLORS.white;
		} else {
			COLORS.primaryColor;
		}

		if (props.tertiary && !props.disabled) return COLORS.primaryColor;

		if (props.disabled && props.tertiary) {
			return COLORS.white;
		}
	}};
	font-family: 'Futura';
	font-size: 14px;
	font-weight: 700;
	line-height: 18px;
	text-transform: uppercase;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border: none;
	cursor: ${props => (props.disabled ? '' : 'pointer')};
	background-color: ${props => {
		if (!props.primary && !props.secondary && !props.tertiary)
			return COLORS.primaryColor;

		if (props.disabled) return COLORS.disabledColor;

		if (props.tertiary) return 'transparent';

		if (props.primary) return COLORS.primaryColor;

		if (props.secondary) return COLORS.secondaryColor;
	}};

	&:hover {
		.button__icon {
			& > div {
				transform: ${props =>
					props.disabled ? '' : 'translateX(45%) translateY(0.4px)'};

				& > svg {
					stroke-width: 1;
					transform: ${props =>
						props.disabled ? '' : 'scale(2) rotate(45deg)'};
				}
			}
		}
	}

	@media (min-width: ${GRID_BREAKPOINTS.$xs}) {
		height: ${BUTTON_HEIGHT.btnHeightDesktop}px;
		font-size: 18px;
		line-height: 25px;
		gap: 5px;
	}

	.button__icon {
		display: flex;
		align-items: center;

		position: relative;

		& > div {
			transition: 0.3s all;
			display: flex;
			align-items: center;

			& > svg {
				transition: 0.3s all;
				stroke-width: 1.5;

				& > rect {
					stroke: ${props => {
						if (
							!props.primary &&
							!props.secondary &&
							!props.tertiary
						)
							return COLORS.secondaryColor;

						if (props.disabled && props.tertiary)
							return COLORS.disabledColor;

						if (props.disabled) return COLORS.white;

						if (props.primary) return COLORS.secondaryColor;

						if (props.secondary) return COLORS.primaryColor;

						if (props.tertiary) return COLORS.secondaryColor;
					}};
				}
			}
		}

		& > svg {
			transform: translateX(-7px) rotate(90deg);

			& > path {
				stroke: ${props =>
					props.disabled || props.primary
						? props.tertiary
							? COLORS.disabledColor
							: COLORS.white
						: COLORS.primaryColor};
			}
		}
	}
`;

interface IButtonProps {
	children: ReactNode;
	primary?: boolean;
	secondary?: boolean;
	tertiary?: boolean;
	disabled?: boolean;
	width?: BUTTON_WIDTH;
	onClick?: () => void;
	className?: string;
}

export const Button: FC<IButtonProps> = ({
	children,
	primary,
	secondary,
	tertiary,
	disabled = false,
	width,
	onClick,
	className,
}) => {
	const handleClick = (e: MouseEvent<HTMLButtonElement>): void => {
		e.preventDefault();

		onClick && onClick();
	};

	return (
		<ButtonView
			primary={primary}
			secondary={secondary}
			tertiary={tertiary}
			className={['mvt-button', className].join(' ').trim()}
			disabled={disabled}
			width={width}
			onClick={handleClick}
		>
			{children}
			{(primary || secondary || tertiary) && (
				<div className="button__icon">
					<div>
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect
								x="7.89343"
								y="1.06066"
								width="9.66296"
								height="9.66296"
								transform="rotate(45 7.89343 1.06066)"
							/>
						</svg>
					</div>
					<ChevronIcon width={16} height={16} />
				</div>
			)}
		</ButtonView>
	);
};
