import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AppContext } from '../../App';
import { Button } from '../../components/Buttons/Buttons';
import { ContainerView } from '../../components/Container/Container.view';
import {
	MVTHeadline1,
	MVTHeadline2,
} from '../../components/styleguide/typography';
import { ROUTER_PATH } from '../../router/routes';
import { useTranslation } from 'react-i18next';

const Error404ContainerView = styled(ContainerView)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 30px;

	.error__code {
		padding: 25px;
		font-size: 58px;
		text-align: center;
	}

	.error__descr {
		text-align: center;
	}
`;

export const Error404: FC = () => {
	const navigate = useNavigate();

	const { t, i18n } = useTranslation();

	const current_lang = i18n.language;

	const { isLogged } = useContext(AppContext);

	return (
		<Error404ContainerView>
			<MVTHeadline1 className="error__code">404</MVTHeadline1>
			<MVTHeadline2 className="error__descr">
				{t('error_404_description')}
			</MVTHeadline2>
			<Button
				primary
				onClick={() => {
					!isLogged
						? navigate(current_lang)
						: navigate(current_lang + ROUTER_PATH.dashboard);
				}}
			>
				Homepage
			</Button>
		</Error404ContainerView>
	);
};
