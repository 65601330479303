import { FC, MouseEvent, useContext, useEffect, useRef, useState } from 'react';
import { Dropdown } from '../Dropdown/Dropdown';
import { ReactComponent as BellIcon } from '../../assets/icons/bell.svg';
import styled from 'styled-components';
import { COLORS } from '../styleguide/colors';
import { TypoBody, TypoHeadline4 } from '../styleguide/typography';
import { TabContainerView, TabItemView } from '../Tabs/Tabs';
import {
	GET_OFFER_LIST_QUERY,
	IGetOfferListQueryResponse,
} from '../../graphql/queries/notifications';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import {
	GET_COMMITMENT_LIST_QUERY,
	IGetCommitmentListQueryResponse,
} from '../../graphql/queries/notifications';
import { NotificationItem } from './NotificationItem';
import { AppSettingsContext } from '../../App';
import { INotification } from '../../models/INotification';

interface INotificationBellContainerProps {
	active: boolean;
}

enum Tabs {
	Commitments,
	Offers,
}

const NotificationBellContainer = styled.div<INotificationBellContainerProps>`
	height: fit-content;
	display: grid;
	align-items: center;
	position: relative;

	&::after {
		content: '';
		width: 12px;
		height: 12px;
		position: absolute;
		bottom: 0px;
		right: -3px;
		background-color: ${COLORS.secondaryColor};
		border-radius: 100%;
		display: ${({ active }) => (active ? 'block' : 'none')};
	}
`;

const NotificationContainer = styled.div`
	padding: 0 !important;
	padding-top: 20px;
	padding-bottom: 20px;

	& > h4 {
		padding: 10px 15px;
	}

	.notification {
		&__tab {
			padding: 0 15px;

			&-items > div {
				border-width: 2px;
				font-family: Arial, Helvetica, sans-serif;
				font-size: 16px;
				font-weight: 400;
				text-transform: capitalize;
				display: flex;
				align-items: center;
				gap: 1ch;

				& > div {
					padding: 0 4px;
					border-radius: 2px;
					background-color: ${COLORS.BG_GrayColor};
				}
			}

			hr {
				border-width: 0.5px;
			}
		}

		&__items {
			&-container {
				overflow-y: auto;
			}
		}

		&__empty-message {
			padding: 15px;
			font-family: Arial, Helvetica, sans-serif;
			font-size: 16px;
			font-weight: 400;
			text-transform: none;
		}
	}
`;

/**
 * Returns the active commitment of a commitment configuration
 *
 * If there are commitments with open quantitites (open_qty > 0), the one with the lowest step is chosen.
 * If all commitments are already fulfilled (open_qty = 0), then we take the commitment with the highest step.
 *
 * @param commitmentGroup commitments, that belong to one commitment group (same item_id)
 * @returns The active commitment
 */
export function getActiveCommitment(
	commitmentGroup: INotification[],
): INotification {
	// Sort by the commitment step
	const sortedCommitments = commitmentGroup.sort(
		(a, b) => a.commitment_step - b.commitment_step,
	);
	// Filter to those with open quantitity
	const openComitments = sortedCommitments.filter(commitment =>
		commitment.products.some(product =>
			product.open_qty ? product.open_qty > 0 : false,
		),
	);
	return openComitments.length > 0
		? openComitments[0]
		: sortedCommitments[sortedCommitments.length - 1];
}

/**
 * Returns only the active commitment per commitment group (same item_id)
 *
 * @param commitments All commitments provided by the backend
 * @returns A list of active commitments, only one per commitment group
 */
export function filterCommitments(
	commitments: INotification[] | undefined,
): INotification[] {
	if (!commitments) {
		return [];
	}
	// only the first step with open commitments of a staggered commitment config shall be shown
	const groupedCommitments = commitments.reduce(
		(result, currentValue) => {
			const itemId = currentValue['item_id'];
			if (!result[itemId]) {
				result[itemId] = [];
			}
			result[itemId].push(currentValue);
			return result;
		},
		{} as Record<string, INotification[]>,
	);
	const activeCommitments = Object.values(groupedCommitments).map(group =>
		getActiveCommitment(group),
	);
	return activeCommitments;
}

export const NotificationList: FC = () => {
	const { t } = useTranslation();

	const appSettings = useContext(AppSettingsContext);

	const containerRef = useRef<HTMLDivElement>(null);

	const [currentTab, setCurrentTab] = useState<Tabs>(
		appSettings?.commitments_enabled ? Tabs.Commitments : Tabs.Offers,
	);

	const [maxHeight, setMaxHeight] = useState<string>('67vh');

	// only start commitments query if enabled
	const commitments = appSettings?.commitments_enabled
		? useQuery<IGetCommitmentListQueryResponse>(GET_COMMITMENT_LIST_QUERY, {
				onError(error) {
					console.error('GET_COMMITMENTS_LIST_QUERY error:>>', error);
				},
				fetchPolicy: 'cache-only',
			}).data
		: null;
	let commitmentList = commitments?.getCommitmentsList;
	commitmentList = filterCommitments(commitmentList);

	// only start offers query if enabled
	const offers = appSettings?.offers_enabled
		? useQuery<IGetOfferListQueryResponse>(GET_OFFER_LIST_QUERY, {
				onError(error) {
					console.error('GET_OFFER_LIST_QUERY error:>>', error);
				},
				fetchPolicy: 'cache-only',
			}).data
		: null;
	const offerList = offers?.getOffersList;

	useEffect(() => {
		// switch to offers tab, if commitments are empty
		if (
			!commitmentList?.length &&
			offerList?.length &&
			appSettings?.offers_enabled
		) {
			setCurrentTab(Tabs.Offers);
		}
	}, [appSettings, commitmentList?.length, offerList?.length]);

	const getMaxHeight = (): void => {
		const rect =
			containerRef.current?.parentElement?.getBoundingClientRect();

		if (rect) {
			const height = document.body.clientHeight - rect.top - 150;
			setMaxHeight(`${height}px`);
		}
	};

	const handleTabChange = (e: MouseEvent, selectedTab: Tabs): void => {
		e.stopPropagation();
		setCurrentTab(selectedTab);
	};

	return (
		<Dropdown
			className="notification__dropdown"
			description={
				<NotificationBellContainer
					className="bell-container"
					active={!!offerList?.length || !!commitmentList?.length}
				>
					<BellIcon fill={COLORS.primaryColor} />
				</NotificationBellContainer>
			}
			style={{
				containerStyle: {
					border: 'none',
				},
				chevronStyle: {
					display: 'none',
				},
				optionsContainerStyle: {
					left: 'auto',
					border: 'none',
					boxShadow: '2px 4px 15px -1px rgba(0, 0, 0, 0.15)',
				},
			}}
			onOpenEffect={getMaxHeight}
		>
			<NotificationContainer className="notification">
				<TypoHeadline4>{t('notifications_typo')}</TypoHeadline4>
				<TabContainerView className="notification__tab">
					<div className="notification__tab-items">
						{appSettings?.commitments_enabled && (
							<TabItemView
								onClick={e => {
									handleTabChange(e, Tabs.Commitments);
								}}
								active={currentTab === Tabs.Commitments}
							>
								{t('commitments_typo')}
								<div>{commitmentList?.length ?? 0}</div>
							</TabItemView>
						)}
						{appSettings?.offers_enabled && (
							<TabItemView
								onClick={e => {
									handleTabChange(e, Tabs.Offers);
								}}
								active={currentTab === Tabs.Offers}
							>
								{t('offers_typo')}
								<div>{offerList?.length ?? 0}</div>
							</TabItemView>
						)}
					</div>
					<hr />
				</TabContainerView>

				<div
					ref={containerRef}
					className="notification__items-container"
					style={{
						maxHeight,
					}}
				>
					<div className="notification__items-group">
						{currentTab === Tabs.Commitments &&
							(commitmentList?.length ? (
								commitmentList.map(commitment => (
									<NotificationItem
										key={commitment.item_id}
										type="commitment"
										item={commitment}
									/>
								))
							) : (
								<TypoBody className="notification__empty-message">
									{t(
										'notifications_commitment_empty_message',
									)}
								</TypoBody>
							))}

						{currentTab === Tabs.Offers &&
							(offerList?.length ? (
								offerList.map(offer => (
									<NotificationItem
										key={offer.item_id}
										type="offer"
										item={offer}
									/>
								))
							) : (
								<TypoBody className="notification__empty-message">
									{t('notifications_offer_empty_message')}
								</TypoBody>
							))}
					</div>
				</div>
			</NotificationContainer>
		</Dropdown>
	);
};
