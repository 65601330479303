import { t } from 'i18next';
import { CSSProperties, FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ContainerView } from '../Container/Container.view';
import { Input } from '../Input/Input';
import { COLORS } from '../styleguide/colors';
import { GRID_BREAKPOINTS } from '../styleguide/variables';
import { TabContainerView, TabItemView } from '../Tabs/Tabs';
import { TypoBody } from '../styleguide/typography';
import { Tooltip } from '../Tooltip/Tooltip';
import { scrollToFirstInvalidControl } from '../../utils/helpers';
import { IDongleInputError } from '../../models/IDongle';

const PDPInputsContainerView = styled(ContainerView)`
	grid-area: key-inputs;
	display: grid;
	grid-gap: 10px;
	grid-template-areas:
		'dongle-switch'
		'dongle-inputs'
		'mac-switch'
		'mac-inputs';

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		padding: 0;
	}

	.dongles {
		width: 100%;
		padding: 15px 20px;
		border: 1px solid ${COLORS.BG_TileColor};
		display: flex;
		flex-direction: column;
		gap: 20px;

		@media (min-width: ${GRID_BREAKPOINTS.$xs}px) {
			width: fit-content;
		}

		&__switch-container {
			display: flex;
			flex-direction: column;
			gap: 20px;

			@media (min-width: ${GRID_BREAKPOINTS.$lg}px) {
				flex-direction: row;
			}
		}

		&__switch-group {
			display: flex;
			flex-direction: column;
			gap: 20px;

			@media (min-width: ${GRID_BREAKPOINTS.$xxs}px) {
				flex-direction: row;
			}
		}

		&__title {
			display: flex;
			flex-direction: row;
			gap: 5px;
			flex: 1;
			align-items: center;

			& > span {
				font-size: 12px;
				line-height: 15px;
				text-transform: uppercase;
			}
		}

		&__input-group {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 5px;
		}
	}
`;

const DonglesInputsShowMoreContainer = styled(TypoBody)`
	margin: 0 auto;
	width: fit-content;
	cursor: pointer;
	font-family: Arial, Helvetica, sans-serif;
	text-align: center;
`;

interface IDonglesProps {
	quantity: number;
	dongles: string[];
	handleDonglesInput: (value: string | string[], index: number) => void;
	csvLoadable?: boolean;
	errors?: IDongleInputError[];
	style?: CSSProperties;
	listPage?: boolean;
	excludeMacAddressInput?: boolean;
}

export const DonglesInputs: FC<IDonglesProps> = ({
	quantity,
	// handleDongleType,
	dongles,
	handleDonglesInput,
	csvLoadable,
	errors,
	style,
	listPage,
	excludeMacAddressInput,
}) => {
	const [isCSVTab, setIsCSVTab] = useState(false);

	const [csv, setCsv] = useState<File>();

	const [showNum, setShowNum] = useState<number>(5);

	useEffect(() => {
		!!errors?.length && scrollToFirstInvalidControl('.invalid-input');
	}, [errors?.length]);

	const handleCSVFile = (file: File): void => {
		setCsv(file);

		const reader = new FileReader();

		reader.readAsText(file);

		reader.onload = function (event) {
			if (event.target?.result) {
				const csvData = event.target.result as string; // Get the CSV data as text

				const rows = csvData.split('\n'); // Split the CSV into rows

				// Initialize an array to store the values
				const values: string[] = [];

				// Iterate through each row and split it into values
				rows.forEach(row => {
					// const dongle =
					// 	dongleType === DONGLE_TYPES.withMacAddress
					// 		? row.replace(/[;,\-_:"' ]/g, '')
					// 		: row;

					values.push(row.trim());
				});

				handleDonglesInput(values, 0);

				setIsCSVTab(false);
			}
		};
	};

	const onChange = (v: string, i: number): void => {
		handleDonglesInput(v, i);
	};

	const placeholder = excludeMacAddressInput
		? '3-1234567'
		: `3-1234567 / 008041aefd7e`;

	const dongleTitle = (
		<>
			<span>
				{t('product_detail_page_dongle_dongle_number_typo')}
				{!excludeMacAddressInput &&
					`/${t('product_detail_page_dongle_mac_address_typo')}`}
			</span>
			<Tooltip>
				{t('product_detail_page_dongle_dongle_number_tooltip_typo')}
				{!excludeMacAddressInput &&
					` / ${t(
						'product_detail_page_dongle_mac_address_tooltip_typo',
					)}`}
			</Tooltip>
		</>
	);

	const dongleLength = dongles.filter(d => !!d).length;

	return (
		<PDPInputsContainerView
			className="dongles dongles-inputs"
			style={style}
		>
			{!listPage && <div>Host ID</div>}
			<div
				className="dongles__input-group"
				style={{ gridArea: 'dongle-inputs' }}
			>
				{!!csvLoadable && !!quantity && (
					<TabContainerView
						style={{
							marginBottom: 10,
						}}
					>
						<div>
							<TabItemView
								className="dongles__title"
								active={!isCSVTab}
								onClick={() => setIsCSVTab(false)}
								style={{
									flex: 1,
									justifyContent: 'center',
									color: errors?.length
										? COLORS.errorColor
										: COLORS.primaryColor,
								}}
							>
								{dongleTitle}
							</TabItemView>
							<TabItemView
								className="dongles__title"
								active={isCSVTab}
								onClick={() => setIsCSVTab(true)}
								style={{
									flex: 1,
									justifyContent: 'center',
								}}
							>
								<span>CSV</span>
							</TabItemView>
						</div>
						<hr />
					</TabContainerView>
				)}

				{!csvLoadable && (
					<div className="dongles__title">{dongleTitle}</div>
				)}

				{!isCSVTab && (
					<div className="dongles__input-group">
						{Array.from(
							Array(quantity),
							(e, i) =>
								i < showNum && (
									<Input
										key={i}
										required
										placeholder={placeholder}
										fullBorder
										value={dongles[i]}
										onChange={v => onChange(v, i)}
										isInvalid={errors?.some(
											err => err.index === i,
										)}
										errorMessages={errors
											?.find(err => err.index === i)
											?.messages.map(msg => t(msg))}
									/>
								),
						)}

						{!!dongleLength &&
							!!quantity &&
							quantity - showNum > 0 && (
								<DonglesInputsShowMoreContainer
									onClick={() => setShowNum(quantity)}
								>
									({quantity - showNum}) {t('show_more_typo')}
									...
								</DonglesInputsShowMoreContainer>
							)}
						{!!dongleLength &&
							quantity > 5 &&
							quantity === showNum && (
								<DonglesInputsShowMoreContainer
									onClick={() => setShowNum(5)}
								>
									{t('show_less_typo')}...
								</DonglesInputsShowMoreContainer>
							)}
					</div>
				)}

				{!!isCSVTab && (
					<Input
						fullBorder
						type={'file'}
						value={csv?.name}
						onUpload={value => handleCSVFile(value)}
					/>
				)}
			</div>
		</PDPInputsContainerView>
	);
};
