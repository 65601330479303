import { IPrice } from './ICart';

export enum ORDER_STATUS {
	processing = 'Processing',
	pending = 'Pending',
}

export interface IOrder {
	total: {
		grand_total: IPrice;
	};
	order_date: string;
	number: string;
	status: string;
	sap_order_id: string;
	items: {
		product_name: string;
		product_sku: string;
		product_image: string;
		quantity_ordered: number;
	}[];
}

export interface ISapOrder {
	dongle_id: string;
	sell_date: string;
	sales_order_id: string;
	sales_order_name: string;
	licence_customer: string;
	licence_customer_id: string;
}
