import { IOrder, ORDER_STATUS } from '../../../models/IOrder';
import {
	MVTHeadline2,
	PriceView,
	TypoHeadline5,
} from '../../../components/styleguide/typography';
import { formatDateToString, onClickAnchor } from '../../../utils/helpers';
import { t } from 'i18next';
import { formatNumber2Price } from '../../../utils/price';
import { AnchorView } from '../../../components/Anchor/AnchorView';
import { ROUTER_PATH } from '../../../router/routes';
import { useNavigate } from 'react-router-dom';
import i18n from '../../../i18n';
import { ReactElement } from 'react';

import styles from '../OrderHistoryPage.module.scss';

type WebshopOrderListProps = {
	filteredOrders: IOrder[];
};

const emptyListMessage = (
	<div className="history__empty">
		<MVTHeadline2>{t('order_history_page_no_data_typo')}</MVTHeadline2>
	</div>
);

export default function WebshopOrderList({
	filteredOrders,
}: WebshopOrderListProps): ReactElement {
	const navigate = useNavigate();
	const current_lang = i18n.language;

	return (
		<div className={styles.history__group}>
			{filteredOrders.length ? (
				<>
					<MVTHeadline2>{t('actual_order_typo')}</MVTHeadline2>
					{filteredOrders.filter(
						order =>
							order.status === ORDER_STATUS.processing ||
							order.status === ORDER_STATUS.pending,
					) &&
						filteredOrders.map(order => (
							<div
								key={order.number}
								className={styles.history__container}
							>
								<div className={styles.history__data}>
									<div>
										<span>
											{t(
												'order_history_item_order_date_typo',
											)}
										</span>
										<PriceView>
											{formatDateToString(
												new Date(order.order_date),
											)}
										</PriceView>
									</div>

									<div>
										<span>
											{t(
												'order_history_item_order_brutto_typo',
											)}
										</span>
										<PriceView>
											{formatNumber2Price(
												order.total.grand_total,
											)}
										</PriceView>
									</div>

									<div>
										<span>
											{t(
												'order_history_item_order_number_typo',
											)}
										</span>
										<PriceView>{order.number}</PriceView>
									</div>

									<div>
										<span>
											{t(
												'order_history_item_sap_order_number_typo',
											)}
										</span>
										<PriceView>
											{order.sap_order_id}
										</PriceView>
									</div>
								</div>
								<div>
									{order.items.map(product => (
										<div
											key={product.product_sku}
											className={styles.history__orders}
										>
											<div
												className={
													styles[
														'history__orders-image'
													]
												}
											>
												<img
													src={product.product_image}
													alt={product.product_name}
												/>
											</div>
											<AnchorView
												className={
													styles[
														'history__orders-name'
													]
												}
												href={
													current_lang +
													ROUTER_PATH.products +
													'/' +
													product.product_sku
												}
												onClick={e =>
													onClickAnchor(e, () =>
														navigate(
															current_lang +
																ROUTER_PATH.products +
																'/' +
																product.product_sku,
														),
													)
												}
											>
												{product.product_name}
												<div>
													<TypoHeadline5>
														{t(
															'order_history_item_quantity_ordered',
														)}{' '}
														{
															product.quantity_ordered
														}
													</TypoHeadline5>
												</div>
											</AnchorView>

											<div
												className={
													styles[
														'history__orders-reorder'
													]
												}
												onClick={() =>
													navigate(
														current_lang +
															ROUTER_PATH.products +
															'/' +
															product.product_sku,
													)
												}
											>
												{t(
													'order_history_item_order_again_typo',
												)}
											</div>
										</div>
									))}
								</div>
							</div>
						))}

					{filteredOrders.filter(
						order =>
							order.status !== ORDER_STATUS.processing &&
							order.status !== ORDER_STATUS.pending,
					).length ? (
						<>
							<MVTHeadline2>
								{t('purchased_products_typo')}
							</MVTHeadline2>

							{filteredOrders
								.filter(
									order =>
										order.status !==
										ORDER_STATUS.processing,
								)
								.map(order => (
									<div
										key={order.number}
										className={styles.history__container}
									>
										<div className={styles.history__data}>
											<div>
												<span>
													{t(
														'order_history_item_order_date_typo',
													)}
												</span>
												<PriceView>
													{formatDateToString(
														new Date(
															order.order_date,
														),
													)}
												</PriceView>
											</div>

											<div>
												<span>
													{t(
														'order_history_item_order_brutto_typo',
													)}
												</span>
												<PriceView>
													{formatNumber2Price(
														order.total.grand_total,
													)}
												</PriceView>
											</div>

											<div>
												<span>
													{t(
														'order_history_item_order_number_typo',
													)}
												</span>
												<PriceView>
													{order.number}
												</PriceView>
											</div>
										</div>
										<div>
											{order.items.map(product => (
												<div
													key={product.product_sku}
													className={
														styles.history__orders
													}
												>
													<div
														className={
															styles[
																'history__orders-image'
															]
														}
													>
														<img
															src={
																product.product_image
															}
															alt={
																product.product_name
															}
														/>
													</div>
													<AnchorView
														className={
															styles[
																'history__orders-name'
															]
														}
														href={
															current_lang +
															ROUTER_PATH.products +
															'/' +
															product.product_sku
														}
														onClick={e =>
															onClickAnchor(
																e,
																() =>
																	navigate(
																		current_lang +
																			ROUTER_PATH.products +
																			'/' +
																			product.product_sku,
																	),
															)
														}
													>
														{product.product_name}
													</AnchorView>

													<div
														className={
															styles[
																'history__orders-reorder'
															]
														}
														onClick={() =>
															navigate(
																current_lang +
																	ROUTER_PATH.products +
																	'/' +
																	product.product_sku,
															)
														}
													>
														{t(
															'nicht mehr verfügbar',
														)}
													</div>
												</div>
											))}
										</div>
									</div>
								))}
						</>
					) : null}
				</>
			) : (
				emptyListMessage
			)}
		</div>
	);
}
