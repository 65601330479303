import { gql } from "@apollo/client";
import { INotification } from "../../models/INotification";

export interface IGetOfferListQueryResponse {
    getOffersList: INotification[];
}

export const GET_OFFER_LIST_QUERY = gql`
    query {
        getOffersList {
            calculated_amount {
                currency
                value
            }
            start_date
            end_date
            item_id
            item_name
            products {
                qty
                product_sku
                product_thumbnail
                product_name
            }
            is_valid
        }
    }
`;

export interface IGetCommitmentListQueryResponse {
    getCommitmentsList: INotification[];
}

export const GET_COMMITMENT_LIST_QUERY = gql`
    query {
        getCommitmentsList {
            item_id
            item_name
            start_date
            end_date
            products {
                commited_qty
                open_qty
                product_sku
                product_thumbnail
                product_name
            }
            commitment_step
        }
    }
`;
