/* eslint-disable prettier/prettier */
import { gql } from '@apollo/client';
import { ICartPricesResponse, ICustomerCartItemQueryResponse } from '../../models/ICart';

export interface ICreateEmptyCartMutationResponse {
	createEmptyCart: string;
}

export const CREATE_EMPTY_CART_MUTATION = gql`
	mutation {
		createEmptyCart
	}
`;

export interface ICartMutationResponse {
	cart: {
		items: ICustomerCartItemQueryResponse[];
		prices: ICartPricesResponse;
	};
}

export interface IAddProductToCartMutationResponse {
	addProductsToCart: ICartMutationResponse;
}

export const ADD_PRODUCTS_TO_CART_MUTATION = gql`
	mutation AddProducts2CartMutation(
		$cartId: String!
		$items: [CartItemInput!]!
	) {
		addProductsToCart(
			cartId: $cartId
			cartItems: $items
		) {
			cart {
				items {
					uid
					quantity
					additional {
						dongle_sku
						host_id
						os
					}
					prices {
						price {
							value
							currency
						}
						row_total {
							value
							currency
						}
						tax_percent
						row_total_including_tax {
							value
							currency
						}
					}
					product {
						uid
						product_type
						name
						sku
						application
						version
						image {
							url
							label
						}
						categories {
							uid
							url_key
							position
						}
					}
				}
				prices {
					grand_total {
						currency
						value
					}
					subtotal_excluding_tax {
						value
						currency
					}
					total_tax_amount
				}
			}
		}
	}
`;

export interface IRemoveItemFromCartMutationResponse {
	removeItemFromCart: ICartMutationResponse;
}

export const REMOVE_ITEM_FROM_CART_MUTATION = gql`
	mutation removeItemFromCart(
		$cartId: String!
		$cartItemUid: ID!

	) {
		removeItemFromCart(
			input: {
				cart_id: $cartId
				cart_item_uid: $cartItemUid
			}
		) {
			cart {
				items {
					uid
					quantity
					additional {
						dongle_sku
						host_id
						os
					}
					prices {
						price {
							value
							currency
						}
						row_total {
							value
							currency
						}
						tax_percent
						row_total_including_tax {
							value
							currency
						}
					}
					product {
						uid
						product_type
						name
						sku
						application
						version
						image {
							url
							label
						}
						categories {
							uid
							url_key
							position
						}
					}
				}
				prices {
					grand_total {
						value
						currency
					}
					subtotal_excluding_tax {
						value
						currency
					}
					total_tax_amount
				}
			}
		}
	}
`;

export interface IUpdateItemFromCartMutationResponse {
	updateCartItems: ICartMutationResponse;
}

export const UPDATE_CART_ITEM_MUTATION = gql`
	mutation updateCartItem(
		$cartId: String!,
		$items: [CartItemUpdateInput!]!
	) {
		updateCartItems(
			input: {
				cart_id: $cartId
				cart_items: $items
			}
		) {
			cart {
				items {
					uid
					quantity
					additional {
						dongle_sku
						host_id
						os
					}
					prices {
						price {
							value
							currency
						}
						row_total {
							value
							currency
						}
						tax_percent
						row_total_including_tax {
							value
							currency
						}
					}
					product {
						uid
						product_type
						name
						sku
						application
						version
						image {
							url
							label
						}
						categories {
							uid
							url_key
							position
						}
					}
				}
				prices {
					grand_total {
						value
						currency
					}
					subtotal_excluding_tax {
						value
						currency
					}
					total_tax_amount
				}
			}
		}
	}
`;

export const SET_SHIPPING_ADDRESSES_TO_CART_MUTATION = gql`
	mutation setShippingAddressesOnCart(
		$cartId: String!
		$firstname: String!
		$lastname: String!
		$company: String!
		$department: String
		$street: [String]!
		$city: String!
		$postcode: String!
		$country_code: String!
	) {
		setShippingAddressesOnCart(
			input: {
				cart_id: $cartId
				shipping_addresses: [
					{
						address: {
							firstname: $firstname
							lastname: $lastname
							company: $company
							department: $department
							street: $street
							city: $city
							postcode: $postcode
							country_code: $country_code
							telephone: "123-456-0000"
							save_in_address_book: false
						}
					}
				]
			}
		) {
			cart {
				shipping_addresses {
					firstname
					lastname
					company
					street
					city
					region {
						code
						label
					}
					postcode
					telephone
					country {
						code
						label
					}
					available_shipping_methods {
						carrier_code
						carrier_title
						method_code
						method_title
					}
				}
			}
		}
	}
`;

export const SET_BILLING_ADDRESS_TO_CART_MUTATION = gql`
	mutation setBillingAddressOnCart (
		$cartId: String!
		$firstname: String!
		$lastname: String!
		$company: String!
		$department: String
		$street: [String]!
		$city: String!
		$postcode: String!
		$country_code: String!
		$billing_email: String
		$licence_email: String
	) {
		setBillingAddressOnCart(
			input: {
				cart_id: $cartId
				billing_address: {
					address: {
						firstname: $firstname
						lastname: $lastname
						company: $company
						department: $department
						street: $street
						city: $city
						postcode: $postcode
						country_code: $country_code
						billing_email: $billing_email
						licence_email: $licence_email
						telephone: "123-456-0000"
						save_in_address_book: true
					}
				}
			}
		) {
			cart {
				billing_address {
					firstname
					lastname
					company
					street
					city
					region{
						code
						label
					}
					postcode
					telephone
					country {
						code
						label
					}
				}
				available_payment_methods {
					code
					title
				}
			}
		}
	}

`;

export const SET_SHIPPING_METHOD_TO_CART_MUTATION = gql`
	mutation setShippingMethodOnCart(
		$cartId: String!
		$carrier_code: String!
		$method_code: String !
	) {
		setShippingMethodsOnCart(input: {
			cart_id: $cartId
			shipping_methods: [
				{
					carrier_code: $carrier_code
					method_code: $method_code
				}
			]
		}) {
			cart {
				shipping_addresses {
					selected_shipping_method {
						carrier_code
						method_code
						carrier_title
						method_title
						amount {
							value
							currency
						}
					}
				}
			}
		}
	}

`;

export const SET_PAYMENT_METHOD_TO_CART_MUTATION = gql`
	mutation setPaymentMethodOnCart($cartId: String!, $methodCode: String!) {
		setPaymentMethodOnCart(input: {
			cart_id: $cartId
			payment_method: {
				code: $methodCode
			}
		}) {
			cart {
				selected_payment_method {
					code
				}
			}
		}
	}
`;

export interface IPlaceOrderMutationResponse {
	data: {
		placeOrder: {
			order: {
			order_number: string;
			}
		}
	}
}

export const PLACE_ORDER_MUTATION = gql`
	mutation placeOrder(
		$cartId: String!
		$poNumber: String
		$requestedFulfillmentPeriodStart: String
	) {
  		placeOrder(
			input: { 
				cart_id: $cartId, 
				po_number: $poNumber
				requested_fulfillment_period_start:
					$requestedFulfillmentPeriodStart
			}
		) {
    		order {
      			order_number
    		}
  		}
	}
`;