import { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../auth/auth';
import { Button } from '../../components/Buttons/Buttons';
import { ContainerView } from '../../components/Container/Container.view';
import { COLORS } from '../../components/styleguide/colors';
import { GRID_BREAKPOINTS } from '../../components/styleguide/variables';
import { AppContext } from '../../App';
import { Navigate } from 'react-router-dom';
import { ROUTER_PATH } from '../../router/routes';
import backgroundImage from '../../assets/images/Rectangle-193.png';
import { LANG } from '../../models/ILang';
import { useTranslation } from 'react-i18next';

const EntranceWrapperView = styled.div`
	/* background: url(${backgroundImage}) center center / cover no-repeat; */
	width: 100%;
	min-height: 100%;
	padding-top: 90px;
`;

const EntranceGroup = styled(ContainerView)`
	display: grid;
	grid-gap: 20px;
	justify-content: center;
	align-content: center;
	height: 100%;

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		grid-template-columns: 1fr 1fr;
		width: fit-content;
	}
`;

const EntranceContainerView = styled(ContainerView)`
	background-color: ${COLORS.white};

	margin: 0;
	flex: 1;
	max-width: 500px;
	min-height: 420px;
	height: 100%;
	padding: 35px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 25px;

	@media (min-width: ${GRID_BREAKPOINTS.$md}px) {
		padding: 50px;
	}

	.entrance {
		&__icon {
			height: 60px;
			width: 100%;

			& > svg {
				display: block;
				margin: 0 auto;
				max-height: 100%;
				max-width: 100%;
			}
		}

		&__title {
			font-weight: 700;
			font-size: 20px;
			line-height: 30px;
			text-transform: uppercase;
			text-align: center;
		}

		&__descr {
			font-family: 'Arial';
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 25px;
			text-align: center;
		}

		button {
			margin: 0 auto;
		}
	}
`;

export const EntrancePage: FC = () => {
	const { isLogged } = useContext(AppContext);

	const { t, i18n } = useTranslation();

	const current_lang = i18n.language;

	useEffect(() => {
		// Setup background to parent wrapper (ContentView)
		const parent =
			document.querySelector('.entrance__content')?.parentElement;

		if (parent)
			parent.style.background = `url(${backgroundImage}) center center / cover no-repeat`;

		return () => {
			if (parent) parent.style.background = '';
		};
	}, []);

	const handleClick = (): void => {
		const url =
			current_lang === LANG.de
				? 'https://www.mvtec.com/de/services-support/mvshop'
				: 'https://www.mvtec.com/services-support/mvshop';

		window.open(url, '_blank', 'noopener noreferrer');
	};

	if (isLogged)
		return <Navigate to={current_lang + ROUTER_PATH.dashboard} replace />;

	const { login } = useAuth();

	return (
		<EntranceWrapperView className="entrance__content">
			<EntranceGroup>
				<EntranceContainerView className="entrance__tile">
					<div className="entrance__icon">
						<svg
							viewBox="0 0 70 58"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M32.6979 55.9647C34.1121 55.9647 35.2586 54.8025 35.2586 53.3689C35.2586 51.9353 34.1121 50.7731 32.6979 50.7731C31.2837 50.7731 30.1372 51.9353 30.1372 53.3689C30.1372 54.8025 31.2837 55.9647 32.6979 55.9647Z"
								fill={COLORS.primaryColor}
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M32.6974 52.2257C32.1041 52.2257 31.5894 52.7188 31.5894 53.3689C31.5894 54.019 32.1041 54.5121 32.6974 54.5121C33.2908 54.5121 33.8054 54.019 33.8054 53.3689C33.8054 52.7188 33.2908 52.2257 32.6974 52.2257ZM28.6841 53.3689C28.6841 51.1517 30.4623 49.3204 32.6974 49.3204C34.9325 49.3204 36.7108 51.1517 36.7108 53.3689C36.7108 55.5861 34.9325 57.4174 32.6974 57.4174C30.4623 57.4174 28.6841 55.5861 28.6841 53.3689Z"
								fill={COLORS.primaryColor}
							/>
							<path
								d="M60.8659 55.9647C62.2801 55.9647 63.4265 54.8025 63.4265 53.3689C63.4265 51.9353 62.2801 50.7731 60.8659 50.7731C59.4516 50.7731 58.3052 51.9353 58.3052 53.3689C58.3052 54.8025 59.4516 55.9647 60.8659 55.9647Z"
								fill={COLORS.primaryColor}
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M60.8659 52.2257C60.2725 52.2257 59.7578 52.7188 59.7578 53.3689C59.7578 54.019 60.2725 54.5121 60.8659 54.5121C61.4592 54.5121 61.9739 54.019 61.9739 53.3689C61.9739 52.7188 61.4592 52.2257 60.8659 52.2257ZM56.8525 53.3689C56.8525 51.1517 58.6308 49.3204 60.8659 49.3204C63.101 49.3204 64.8792 51.1517 64.8792 53.3689C64.8792 55.5861 63.101 57.4174 60.8659 57.4174C58.6308 57.4174 56.8525 55.5861 56.8525 53.3689Z"
								fill={COLORS.primaryColor}
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M10.7598 1.45265C10.7598 0.650375 11.4101 0 12.2124 0H22.4551C23.1489 0 23.7459 0.490616 23.8803 1.17127L26.2116 12.979H68.5474C68.9797 12.979 69.3895 13.1716 69.6655 13.5043C69.9415 13.8371 70.0549 14.2754 69.975 14.7003L65.8779 36.4791L65.8758 36.4898C65.5771 38.0147 64.758 39.3886 63.5569 40.3687C62.3593 41.346 60.8585 41.8689 59.3157 41.8424H34.4524C32.9095 41.8689 31.4088 41.346 30.2111 40.3687C29.0105 39.3889 28.1915 38.0156 27.8925 36.4914C27.8924 36.4909 27.8923 36.4903 27.8922 36.4898L23.6081 14.7916C23.5952 14.741 23.585 14.6894 23.5775 14.6369L21.2613 2.90531H12.2124C11.4101 2.90531 10.7598 2.25493 10.7598 1.45265ZM26.7852 15.8844L30.7433 35.9312C30.9129 36.7966 31.3765 37.5698 32.048 38.1177C32.719 38.6653 33.5555 38.954 34.4109 38.9374C34.4203 38.9372 34.4297 38.9371 34.4391 38.9371H59.3289C59.3383 38.9371 59.3477 38.9372 59.3571 38.9374C60.2125 38.954 61.049 38.6653 61.7201 38.1177C62.3903 37.5708 62.8534 36.7996 63.0238 35.9362C63.0241 35.9346 63.0244 35.9329 63.0247 35.9312L66.796 15.8844H26.7852Z"
								fill={COLORS.primaryColor}
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M20.0002 24.0434C20.0002 24.5783 19.5667 25.0118 19.0318 25.0118L2.1315 25.0118C1.59665 25.0118 1.16306 24.5783 1.16307 24.0434C1.16307 23.5086 1.59665 23.075 2.1315 23.075L19.0318 23.075C19.5667 23.075 20.0002 23.5086 20.0002 24.0434Z"
								fill={COLORS.primaryColor}
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M20.0002 30.975C20.0002 31.5099 19.5667 31.9435 19.0318 31.9435L5.05721 31.9435C4.52236 31.9435 4.08878 31.5099 4.08878 30.975C4.08878 30.4402 4.52236 30.0066 5.05721 30.0066L19.0318 30.0066C19.5667 30.0066 20.0002 30.4402 20.0002 30.975Z"
								fill={COLORS.primaryColor}
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M20.0002 37.9066C20.0002 38.4414 19.5667 38.875 19.0318 38.875L0.968382 38.875C0.433531 38.875 -5.33679e-05 38.4414 -5.33211e-05 37.9066C-5.32743e-05 37.3717 0.433531 36.9381 0.968382 36.9381L19.0318 36.9381C19.5667 36.9381 20.0002 37.3717 20.0002 37.9066Z"
								fill={COLORS.primaryColor}
							/>
						</svg>
					</div>
					<div className="entrance__title">
						{t('entrance_page_webshop_title')}
					</div>
					<div className="entrance__descr">
						{t('entrance_page_webshop_description')}
					</div>
					<Button primary onClick={() => login()}>
						{t('entrance_page_webshop_button_typo')}
					</Button>
				</EntranceContainerView>

				<EntranceContainerView className="entrance__tile">
					<div className="entrance__icon">
						<svg
							width="53"
							height="58"
							viewBox="0 0 53 58"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M21.2796 38.6835C21.997 38.6835 22.6642 38.9964 23.0465 39.5122L31.8508 51.3895V40.4578C31.8508 39.4779 32.7856 38.6835 33.9389 38.6835H45.032C48.2034 38.6835 50.7743 36.4989 50.7743 33.8041V7.18934C50.7743 4.49454 48.2034 2.30996 45.032 2.30996H7.9679C4.7965 2.30996 2.22557 4.49454 2.22557 7.18934V33.8041C2.22557 36.4989 4.7965 38.6835 7.9679 38.6835H21.2796ZM33.9389 54.2065V57.5356L31.8508 54.7187L21.2796 40.4578H7.9679C3.64326 40.4578 0.137451 37.4789 0.137451 33.8041V7.18934C0.137451 3.51461 3.64326 0.535645 7.9679 0.535645H45.032C49.3566 0.535645 52.8625 3.51461 52.8625 7.18934V33.8041C52.8625 37.4789 49.3566 40.4578 45.032 40.4578H36.027H33.9389V42.2321V54.2065Z"
								fill={COLORS.primaryColor}
							/>
							<path
								d="M27.4469 25.0417V22.4254C28.7176 22.251 29.6645 21.653 30.2874 21.0301C31.1346 20.1829 31.5581 19.1613 31.5581 17.8158C31.5581 16.1464 30.9601 15.05 30.2625 14.3773C29.216 13.3557 27.9203 13.1065 26.6246 13.1065C24.5316 13.1065 23.3605 13.8042 22.6877 14.5019C21.9901 15.2494 21.4419 16.57 21.4419 17.8656H24.6811C24.6811 16.9936 24.9552 16.4952 25.2293 16.246C25.3788 16.0965 25.7774 15.7975 26.5 15.7975C27.1229 15.7975 27.5216 16.0218 27.7957 16.2959C28.0947 16.5949 28.319 17.0683 28.319 17.7411C28.319 18.4138 28.0449 19.0118 27.6711 19.4105C27.1479 19.9337 26.3007 20.2577 25.3289 20.2577C24.9801 20.2577 24.6562 20.2327 24.3572 20.158V25.0417H27.4469ZM23.9585 28.5301C23.9585 29.6264 24.8057 30.4736 25.902 30.4736C26.9984 30.4736 27.8455 29.6264 27.8455 28.5301C27.8455 27.4337 26.9984 26.5865 25.902 26.5865C24.8057 26.5865 23.9585 27.4337 23.9585 28.5301Z"
								fill={COLORS.primaryColor}
							/>
						</svg>
					</div>
					<div className="entrance__title">
						{t('entrance_page_what_is_webshop_title')}
					</div>
					<div className="entrance__descr">
						{t('entrance_page_what_is_webshop_description')}
					</div>
					<Button secondary onClick={handleClick}>
						{t('entrance_page_what_is_webshop_button_typo')}
					</Button>
				</EntranceContainerView>
			</EntranceGroup>
		</EntranceWrapperView>
	);
};
