import { FC } from 'react';
import {
	MVTHeadline2,
	TypoHeadline4,
	TypoHeadline1,
	MVTHeadlinePaddingMixin,
} from '../../../../components/styleguide/typography';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IOfferQuoteMutationResponse } from '../../../../graphql/mutations/offer';

const OverviewPageHeaderContainer = styled.div`
	${MVTHeadlinePaddingMixin}
`;

interface IOverviewPageHeaderProps {
	offerQuote?: IOfferQuoteMutationResponse;
}

export const OverviewPageHeader: FC<IOverviewPageHeaderProps> = ({
	offerQuote,
}) => {
	const { t } = useTranslation();

	const renderItem = (title: string, str?: string): JSX.Element | null =>
		title && str ? (
			<TypoHeadline4>
				{title} {str}
			</TypoHeadline4>
		) : null;

	return (
		<>
			{!offerQuote ? (
				<MVTHeadline2>
					{t('order_overview_page_order_overview_headline')}
				</MVTHeadline2>
			) : (
				<OverviewPageHeaderContainer>
					<TypoHeadline1>
						Angebot nr. {offerQuote.offer_id}
					</TypoHeadline1>

					{renderItem(
						`${t('order_overview_page_offer_payment_terms')}:`,
						offerQuote.payment_terms,
					)}
					{renderItem(
						`${t('order_overview_page_offer_delivery_terms')}:`,
						offerQuote.delivery_terms,
					)}
					{renderItem(
						`${t('order_overview_page_offer_contact_person')}:`,
						offerQuote.contact_person,
					)}
				</OverviewPageHeaderContainer>
			)}
		</>
	);
};
