import { User, UserManager } from 'oidc-client-ts';
import { ROUTER_PATH } from '../router/routes';
import { getLangFromPath } from '../utils/helpers';

const language = getLangFromPath(window.location.pathname);

let user = {} as User | null;

const mgr: UserManager = new UserManager({
	authority: process.env.REACT_APP_MVLOGIN_AUTHORITY as string,
	client_id: process.env.REACT_APP_MVLOGIN_CLIENT_ID as string,
	redirect_uri: (process.env.REACT_APP_BASE_URL +
		language +
		'/mvlogin-redirect') as string,
	scope: 'openid email profile roles groups',
});

mgr.events.addUserLoaded(newUser => {
	user = newUser;
});

mgr.events.addUserUnloaded(() => {
	user = null;
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const login = async () => {
	return await mgr.signinRedirect();
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
async function handleCallback(
	callback?: (access_token?: string, expires_at?: number) => void,
) {
	if (
		window.location.pathname.endsWith(ROUTER_PATH.mvloginRedirect) &&
		callback
	) {
		user = await mgr.signinRedirectCallback();

		callback(user.access_token, user.expires_at);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
async function logout() {
	await mgr.signoutRedirect();
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useAuth() {
	return {
		user,
		login,
		logout,
		handleCallback,
	};
}
