import { FC } from 'react';
import { Spinner } from '../../components/Spinner/Spinner';
import { ContainerView } from '../../components/Container/Container.view';

export const MVLoginRedirectPage: FC = () => {
	return (
		<ContainerView
			style={{
				display: 'grid',
				justifyContent: 'center',
			}}
		>
			<Spinner />
		</ContainerView>
	);
};
