import { FC } from 'react';
import { operationSystems } from '../../models/ICart';
import { Dropdown } from '../Dropdown/Dropdown';
import { Switch } from '../Switch/Switch';
import { useTranslation } from 'react-i18next';

const operationSystemsArray = Object.values(operationSystems);

interface IOperationSystemSelectProps {
	selected: string | null;
	onChange: (os: string) => void;
	invalid?: boolean;
}

export const OperationSystemSelect: FC<IOperationSystemSelectProps> = ({
	selected,
	onChange,
	invalid,
}) => {
	const { t } = useTranslation();

	return (
		<Dropdown
			selected={
				operationSystemsArray.find(os => os.value === selected)?.title
			}
			items={operationSystemsArray}
			description={t('product_detail_page_dongle_operating_system_typo')}
			isInvalid={invalid}
			style={{
				containerStyle: {
					border: 'none',
				},
				titleStyle: {
					fontFamily: 'Arial',
					textTransform: 'none',
					gap: 10,
					width: 'fit-content',
				},
			}}
		>
			{operationSystemsArray.map(os => (
				<Switch
					key={os.value}
					type="radio"
					checked={selected === os.value}
					onChange={() => onChange(os.value)}
					style={{
						flexDirection: 'row-reverse',
					}}
				>
					{os.title}
				</Switch>
			))}
		</Dropdown>
	);
};
