import { gql } from "@apollo/client";
import { ICountry } from "../../components/CountryInput/CountryInput";
import { IAvailablePaymentMethod, ICartPricesResponse, ICustomerCartItemQueryResponse, TShipping } from "../../models/ICart";

export interface IGetCartQueryResponse {
    cart: {
        shipping_addresses: {
            available_shipping_methods: TShipping[];
        }[];
        available_payment_methods: IAvailablePaymentMethod[];
    }
}

export const GET_CART_QUERY = gql`
    query GetCartQuery($cartId: String!) {
    cart(cart_id: $cartId) {
        shipping_addresses {
            available_shipping_methods {
                amount {
                    currency
                    value
                }
                carrier_code
                method_code
                method_title
            }
            # firstname
            # lastname
            # street
            # city
            # region {
            #     code
            #     label
            # }
            # country {
            #     code
            #     label
            # }
            # selected_shipping_method {
            #     amount {
            #         value
            #         currency
            #     }
            #     carrier_code
            #     carrier_title
            #     method_code
            #     method_title
            # }
        }
        available_payment_methods {
            code
            title
        }
        # email
        # billing_address {
        #     city
        #     country {
        #         code
        #         label
        #     }
        #     firstname
        #     company
        #     lastname
        #     postcode
        #     region {
        #         code
        #         label
        #     }
        #     street
        #     telephone
        # }
        # items {
        #     id
        #     product {
        #         name
        #         sku
        #     }
        #     quantity
        #     additional {
        #         dongle_sku
        #         dongle_keys {
        #             mac
        #             licence_keys
        #         }
        #         os
        #     }
        # }
        # selected_payment_method {
        #     code
        #     title
        # }
        # prices {
        #     grand_total {
        #         value
        #         currency
        #     }
        # }
    }
}`;

export interface IGetCustomerCartQueryResponse {
    customerCart: {
            id: string;
            email: string;
            items: ICustomerCartItemQueryResponse[];
            prices: ICartPricesResponse;
            shipping_addresses: {
                available_shipping_methods: TShipping[];
            }[];
            available_payment_methods: IAvailablePaymentMethod[];
            selected_payment_method: IAvailablePaymentMethod;
        };
}

export const GET_CUSTOMER_CART = gql`
    query {
        customerCart {
            id
            email
            items {
                uid
                quantity
                additional {
                    dongle_sku
                    host_id
                    os
                }
                prices {
						price {
							value
							currency
						}
						row_total {
							value
							currency
						}
						tax_percent
						row_total_including_tax {
							value
							currency
						}
                        discount_amount
					}
                product {
                    uid
                    product_type
                    name
                    sku
                    application
                    version
                    exclude_mac_address
                    image {
                        url
                        label
                    }
                    categories {
                        uid
                        url_key
                        position
                    }
                    dongle_sku
                    product_tech_headline
                    product_tech_description
                    product_tech_image
                    product_activation_headline
                    product_activation_description
                    product_pdf
                    info_description
                }
            }
            prices {
                grand_total {
                    currency
                    value
                }
                subtotal_excluding_tax {
                    value
                    currency
                }
                total_tax_amount
            }
            available_payment_methods {
                code
                title
            }
            selected_payment_method {
                code
                title
            }
            shipping_addresses {
                available_shipping_methods {
                    amount {
                        currency
                        value
                    }
                    carrier_code
                    method_code
                    method_title
                }
                # firstname
                # lastname
                # street
                # city
                # region {
                #     code
                #     label
                # }
                # country {
                #     code
                #     label
                # }
            }
        }
    }
`;

export interface IGetCountriesQueryResponse {
    countries: ICountry[];
}

export const GET_COUNTRIES_QUERY = gql`
    query {
        countries {
            id
            two_letter_abbreviation
            three_letter_abbreviation
            full_name_locale
            full_name_english
            # available_regions {
            #     id
            #     code
            #     name
            # }
        }
    }
`;