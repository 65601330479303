export enum COLORS {
	primaryColor = '#062D64',
	disabledPrimaryColor = '#8296B2',
	secondaryColor = '#FBBA00',
	white = '#FFF',
	disabledColor = '#DFDFDF',
	BG_GrayColor = '#F5F5F5',
	BG_Tooltip_GrayColor = '#D9D9D9',
	BG_TileColor = '#E3E1E1',
	textGray = '#9A9A9A',
	errorColor = '#F26326',
	orangeColor = '#F28D26',
	greenColor = '#009688',
	blueColor = '#23A0E9',
}
