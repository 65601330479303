import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../components/Buttons/Buttons';
import { ContainerView } from '../../components/Container/Container.view';
import { COLORS } from '../../components/styleguide/colors';
import {
	TypoHeadline1,
	TypoHeadline2,
} from '../../components/styleguide/typography';
import { GRID_BREAKPOINTS } from '../../components/styleguide/variables';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success-order.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
import { useTranslation } from 'react-i18next';

const MessagePageContainerView = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 60px;

	.message {
		&__container {
			min-width: 60%;
			border: 2px solid ${COLORS.BG_TileColor};
			padding: 30px;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 60px;

			@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
				padding: 60px;
			}
		}
		&__message-container {
			max-width: 550px;
			display: flex;
			align-items: center;
			flex-direction: column;
			gap: 50px;
		}

		&__header {
			line-height: 35px;
			text-transform: uppercase;
			text-align: center;
		}

		&__desription {
			font-family: Arial, Helvetica, sans-serif;
			font-size: 16px;
			font-weight: 400;
			text-align: center;
		}
	}
	a {
		color: ${COLORS.primaryColor};
	}

	button {
		width: fit-content;
		margin: 0 auto;
	}
`;

export enum MESSAGE_TYPE {
	success = 'success',
	error = 'error',
}

export interface IMessagePageParams {
	type: MESSAGE_TYPE;
	title: string;
	descr?: string | null;
	btnDescr?: string | null;
	redirectUrl?: string | null;
}

interface IMessagePageLocation {
	state: IMessagePageParams;
}

export const MessagePage: FC = () => {
	const navigate = useNavigate();

	const { t } = useTranslation();

	const location: IMessagePageLocation = useLocation();

	const getTitle = (title?: string): string | undefined | null => {
		if (title)
			switch (title.toLowerCase()) {
				case "the current customer isn't authorized.":
					return t(
						'modal_messages_failure_the_current_customer_is_not_authorized',
					);

				case '200 something went wrong':
					return t('modal_messages_failure_200_something_went_wrong');

				default:
					return title;
			}
	};

	const content = {
		type: location.state?.type,
		title: getTitle(location.state?.title),
		description: location.state?.descr,
		buttonTitle: location.state?.btnDescr,
		buttonUrl: location.state?.redirectUrl,
	};

	return (
		<ContainerView>
			<MessagePageContainerView className="message">
				<div className="message__container">
					<div className="message__message-container">
						{content.type === MESSAGE_TYPE.success ? (
							<>
								<SuccessIcon fill={COLORS.secondaryColor} />
								{!!content.title && (
									<TypoHeadline1
										className="message__header"
										dangerouslySetInnerHTML={{
											__html: content.title,
										}}
									/>
								)}
							</>
						) : (
							<>
								<ErrorIcon fill={COLORS.errorColor} />
								{!!content.title && (
									<TypoHeadline2
										className="message__header"
										dangerouslySetInnerHTML={{
											__html: content.title,
										}}
									/>
								)}
							</>
						)}

						{!!content.description && (
							<div
								className="message__desription"
								dangerouslySetInnerHTML={{
									__html: content.description,
								}}
							/>
						)}
					</div>
					{!!content.buttonTitle && !!content.buttonUrl && (
						<Button
							primary
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							onClick={() => navigate(content.buttonUrl!)}
						>
							{content.buttonTitle}
						</Button>
					)}
				</div>
			</MessagePageContainerView>
		</ContainerView>
	);
};
