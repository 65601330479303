import { FetchResult, useMutation } from '@apollo/client';
import { TShipping, IAvailablePaymentMethod } from '../models/ICart';
import {
	SET_BILLING_ADDRESS_TO_CART_MUTATION,
	SET_SHIPPING_ADDRESSES_TO_CART_MUTATION,
	SET_SHIPPING_METHOD_TO_CART_MUTATION,
	SET_PAYMENT_METHOD_TO_CART_MUTATION,
	PLACE_ORDER_MUTATION,
	IPlaceOrderMutationResponse,
} from '../graphql/mutations/cart';
import { useContext, useState } from 'react';
import { AppContext } from '../App';
import { MESSAGE_TYPE } from '../pages/MessagePage/MessagePage';
import { ROUTER_PATH } from '../router/routes';
import { useCheckSession } from './useCheckSession';
import { IBillingState } from '../models/IUser';
import { useTranslation } from 'react-i18next';
import { LANG } from '../models/ILang';
import { GET_CUSTOMER_CART } from '../graphql/queries/cart';
import { useCartId } from './useCartId';
import { GET_OFFER_LIST_QUERY } from '../graphql/queries/notifications';

type TAvailableShippingMethods = Pick<
	TShipping,
	'carrier_code' | 'method_code'
>;

interface ICheckoutProps {
	cartId: string | undefined;
	shippable: boolean;
	billing: IBillingState;
	requestedDeliveryDateforOrder?: string;
	isContractOrder?: boolean;
}

interface IBillingPageCheckoutReturnValue {
	addressesCheckout: (callback?: () => void) => void;
	methodsCheckout: (callback?: () => void) => void;
}

type TUseCheckoutReturnValue = {
	checkout: (props: ICheckoutProps) => void;
	billingPageCheckout: (
		props: ICheckoutProps,
	) => IBillingPageCheckoutReturnValue;
	placeOrderOverviewPage: (
		id: string | undefined,
		requestedDeliveryDateforOrder?: string,
	) => void;
	loading: boolean;
	availableShippingMethods: TShipping[];
	availablePaymentMethods: IAvailablePaymentMethod[];
};

let poNumber = '';

export const useCheckout = (): TUseCheckoutReturnValue => {
	const { navigateToMessagePage, requestedDeliveryDate } =
		useContext(AppContext);

	const { t, i18n } = useTranslation();

	const current_lang = i18n.language as LANG;

	const checkSession = useCheckSession();

	const cartIdNumber = useCartId();

	const [loading, setLoading] = useState<boolean>(false);

	const [available_shipping_methods, setAvailableShippingMethods] = useState<
		TShipping[]
	>([]);

	const [available_payment_methods, setAvailablePaymentMethods] = useState<
		IAvailablePaymentMethod[]
	>([]);

	const [setBillingAddressOnCartMutation] = useMutation(
		SET_BILLING_ADDRESS_TO_CART_MUTATION,
	);

	const [setShippingAddressesOnCartMutation] = useMutation(
		SET_SHIPPING_ADDRESSES_TO_CART_MUTATION,
	);

	const [setShippingMethodOnCartMutation] = useMutation(
		SET_SHIPPING_METHOD_TO_CART_MUTATION,
	);

	const [setPaymentMethodOnCartMutation] = useMutation(
		SET_PAYMENT_METHOD_TO_CART_MUTATION,
	);

	const [placeOrderMutation] =
		useMutation<IPlaceOrderMutationResponse>(PLACE_ORDER_MUTATION);

	const setShippingAddress = (
		id: string,
		billing: IBillingState,
	): Promise<FetchResult> => {
		return setShippingAddressesOnCartMutation({
			variables: {
				cartId: id,
				firstname: billing.deliveryAddress.firstname,
				lastname: billing.deliveryAddress.lastname,
				company: billing.deliveryAddress.company,
				department: billing.deliveryAddress.department,
				street: billing.deliveryAddress.street,
				city: billing.deliveryAddress.city,
				postcode: billing.deliveryAddress.postcode,
				country_code: billing.deliveryAddress.country_code,
			},
			onError(error) {
				console.error(
					'setShippingAddressesOnCartMutation error:>>',
					error,
				);

				setLoading(false);

				navigateToMessagePage(current_lang, {
					type: MESSAGE_TYPE.error,
					title: error.message,
					btnDescr: 'Products',
					redirectUrl: current_lang + ROUTER_PATH.products,
				});
			},
		});
	};

	const setBillingAddress = (
		id: string,
		billing: IBillingState,
	): Promise<FetchResult> => {
		return setBillingAddressOnCartMutation({
			variables: {
				cartId: id,
				firstname: billing.billingAddress.firstname,
				lastname: billing.billingAddress.lastname,
				company: billing.billingAddress.company,
				department: billing.billingAddress.department,
				street: billing.billingAddress.street,
				city: billing.billingAddress.city,
				postcode: billing.billingAddress.postcode,
				country_code: billing.billingAddress.country_code,
				billing_email: billing.billingAddress.email,
				licence_email: billing.deliveryAddress.email,
			},
			onError(billing_error) {
				console.error(
					'setBillingAddressOnCartMutation error:>>',
					billing_error,
				);

				setLoading(false);

				navigateToMessagePage(current_lang, {
					type: MESSAGE_TYPE.error,
					title: billing_error.message,
					btnDescr: 'Products',
					redirectUrl: current_lang + ROUTER_PATH.products,
				});
			},
		});
	};

	const setShippingMethod = (
		id: string,
		{ carrier_code, method_code }: TAvailableShippingMethods,
	): Promise<FetchResult> => {
		return setShippingMethodOnCartMutation({
			variables: {
				cartId: id,
				carrier_code,
				method_code,
			},
			onError(set_shipping_error) {
				console.error(
					'setShippingMethodOnCartMutation error:>>',
					set_shipping_error,
				);

				setLoading(false);

				navigateToMessagePage(current_lang, {
					type: MESSAGE_TYPE.error,
					title: set_shipping_error.message,
					btnDescr: 'Products',
					redirectUrl: current_lang + ROUTER_PATH.products,
				});
			},
		});
	};

	const setPaymentMethod = (
		id: string,
		methodCode: string,
	): Promise<FetchResult> => {
		return setPaymentMethodOnCartMutation({
			variables: {
				cartId: id,
				methodCode: methodCode,
			},
			onError(payment_error) {
				console.error(
					'setPaymentMethodOnCartMutation error:>>',
					payment_error,
				);

				setLoading(false);

				navigateToMessagePage(current_lang, {
					type: MESSAGE_TYPE.error,
					title: payment_error.message,
					btnDescr: 'Products',
					redirectUrl: current_lang + ROUTER_PATH.products,
				});
			},
		});
	};

	const placeOrder = (
		id: string,
		requestedDeliveryDateforOrder?: string,
		isContractOrder?: boolean,
	): void => {
		placeOrderMutation({
			variables: {
				cartId: id,
				poNumber,
				requestedFulfillmentPeriodStart: requestedDeliveryDateforOrder,
			},
			refetchQueries: [
				{ query: GET_CUSTOMER_CART },
				{ query: GET_OFFER_LIST_QUERY },
			],
			onCompleted() {
				setLoading(false);

				requestedDeliveryDate.setRequestedDeliveryDate('');

				if (isContractOrder) {
					// The success message after a contract offer was succesfully accepted
					navigateToMessagePage(current_lang, {
						type: MESSAGE_TYPE.success,
						title: t(
							'modal_messages_success_contract_accepted_title',
						),
						descr: t(
							'modal_messages_success_contract_accepted_descr',
						),
						btnDescr: t('products_overview_typo'),
						redirectUrl: current_lang + ROUTER_PATH.products,
					});
				} else {
					// The success message after a product order was succesfully placed
					navigateToMessagePage(current_lang, {
						type: MESSAGE_TYPE.success,
						title: t('modal_messages_success_order_title'),
						descr: t('modal_messages_success_order_descr'),
						btnDescr: t('modal_messages_success_order_button'),
						redirectUrl: current_lang + ROUTER_PATH.orderHistory,
					});
				}
			},
			onError(error) {
				console.error(
					'placeOrderMutation error:>>',
					error.graphQLErrors,
				);

				setLoading(false);

				navigateToMessagePage(current_lang, {
					type: MESSAGE_TYPE.error,
					title:
						error.message.toLowerCase() === 'internal server error'
							? t(
									'modal_messages_failure_internal_server_error_2',
								)
							: error.message,
					btnDescr: t('to_products_typo'),
					redirectUrl: current_lang + ROUTER_PATH.products,
				});
			},
		});
	};

	const billingPageCheckout = ({
		cartId,
		shippable,
		billing,
	}: ICheckoutProps): IBillingPageCheckoutReturnValue => {
		poNumber = billing.poNumber;

		const addressesCheckout = async (
			callback?: () => void,
		): Promise<void> => {
			setLoading(true);

			const addressesCheckoutProcess = (id: string): void => {
				if (shippable) {
					setShippingAddress(id, billing).then(shipping_res => {
						const shipping_methods: TShipping[] =
							shipping_res.data?.setShippingAddressesOnCart.cart
								.shipping_addresses[0]
								.available_shipping_methods;

						setAvailableShippingMethods(shipping_methods);

						setBillingAddress(id, billing).then(billing_res => {
							const payment_methods: IAvailablePaymentMethod[] =
								billing_res.data?.setBillingAddressOnCart.cart
									.available_payment_methods;

							setAvailablePaymentMethods(payment_methods);

							setLoading(false);

							callback && callback();
						});
					});
				} else {
					setBillingAddress(id, billing).then(billing_res => {
						const payment_methods: IAvailablePaymentMethod[] =
							billing_res.data?.setBillingAddressOnCart.cart
								.available_payment_methods;

						setAvailablePaymentMethods(payment_methods);

						setLoading(false);

						callback && callback();
					});
				}
			};

			checkSession(() =>
				cartIdNumber
					.getCartId(cartId)
					.then(id => addressesCheckoutProcess(id)),
			);
		};

		const methodsCheckout = async (
			callback?: () => void,
		): Promise<void> => {
			setLoading(true);

			const methodsCheckoutProcess = (id: string): void => {
				if (shippable) {
					setShippingMethod(id, {
						carrier_code:
							available_shipping_methods[0].carrier_code,
						method_code: available_shipping_methods[0].method_code,
					}).then(() => {
						setPaymentMethod(
							id,
							available_payment_methods[0].code,
						).then(() => {
							setLoading(false);

							callback && callback();
						});
					});
				} else {
					setPaymentMethod(
						id,
						available_payment_methods[0].code,
					).then(() => {
						setLoading(false);

						callback && callback();
					});
				}
			};

			checkSession(() =>
				cartIdNumber
					.getCartId(cartId)
					.then(id => methodsCheckoutProcess(id)),
			);
		};

		return { addressesCheckout, methodsCheckout };
	};

	const placeOrderOverviewPage = async (
		cartId: string | undefined,
		requestedDeliveryDateforOrder?: string,
	): Promise<void> => {
		checkSession(() =>
			cartIdNumber
				.getCartId(cartId)
				.then(id => placeOrder(id, requestedDeliveryDateforOrder)),
		);
	};

	const checkout = async ({
		cartId,
		shippable,
		billing,
		requestedDeliveryDateforOrder,
		isContractOrder,
	}: ICheckoutProps): Promise<void> => {
		const fullCheckoutProcess = (id: string): void => {
			if (shippable) {
				// if material product is in cart, set shippingAddress and billingAddress:
				setLoading(true);

				setShippingAddress(id, billing).then(shipping_res => {
					const shipping_methods: TShipping[] =
						shipping_res.data?.setShippingAddressesOnCart.cart
							.shipping_addresses[0].available_shipping_methods;

					setBillingAddress(id, billing).then(billing_res => {
						const payment_methods: IAvailablePaymentMethod[] =
							billing_res.data?.setBillingAddressOnCart.cart
								.available_payment_methods;

						setShippingMethod(id, {
							carrier_code: shipping_methods[0].carrier_code,
							method_code: shipping_methods[0].method_code,
						}).then(() => {
							setPaymentMethod(id, payment_methods[0].code).then(
								() =>
									placeOrder(
										id,
										requestedDeliveryDateforOrder,
										isContractOrder,
									),
							);
						});
					});
				});
			} else {
				// if no material product in cart, set only billingAddress:
				setLoading(true);

				setBillingAddress(id, billing).then(billing_res => {
					setPaymentMethod(
						id,
						billing_res.data?.setBillingAddressOnCart.cart
							.available_payment_methods[0].code,
					).then(() => placeOrder(id, requestedDeliveryDateforOrder));
				});
			}
		};

		checkSession(() =>
			cartIdNumber.getCartId(cartId).then(id => fullCheckoutProcess(id)),
		);
	};

	return {
		loading: loading || cartIdNumber.loading,
		checkout,
		billingPageCheckout,
		placeOrderOverviewPage,
		availableShippingMethods: available_shipping_methods,
		availablePaymentMethods: available_payment_methods,
	};
};
