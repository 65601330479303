import { gql } from '@apollo/client';

export interface IGenerateCustomerTokenData {
	token: string;
	country_code: string;
	has_catalogs: boolean;
	is_sap_customer: boolean;
	session_lifetime: number;
	has_internal_distributors: boolean;
}

export interface ICreateCustomerTokenResponse {
	generateM2CustomerToken: IGenerateCustomerTokenData;
}

export const CREATE_CUSTOMER_TOKEN_MUTATION = gql`
	mutation CreateCustomerToken($access_token: String!) {
		generateM2CustomerToken(access_token: $access_token) {
			token
			country_code
			has_catalogs
			is_sap_customer
			session_lifetime
			has_internal_distributors
		}
	}
`;
