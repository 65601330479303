import styled, { css } from 'styled-components';
import { COLORS } from './colors';
import { GRID_BREAKPOINTS } from './variables';

export const MVTHeadlinePaddingMixin = css`
	border-bottom: 2px solid ${COLORS.secondaryColor};
	padding: 5px;

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		padding: 10px 5px;
	}
`;

export const MVTHeadline1 = styled.h1`
	font-size: 20px;
	font-weight: 700;
	text-transform: uppercase;

	${MVTHeadlinePaddingMixin}

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		font-size: 25px;
	}

	@media (min-width: ${GRID_BREAKPOINTS.$md}px) {
		font-size: 30px;
	}
`;

export const MVTHeadline2 = styled.h2`
	font-size: 18px;
	font-weight: 700;
	text-transform: uppercase;

	${MVTHeadlinePaddingMixin}

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		font-size: 20px;
	}
`;

export const TypoHeadline1 = styled.h1`
	font-family: 'Futura';
	font-size: 30px;
	font-weight: 700;
	line-height: 40px;
`;

export const TypoHeadline2 = styled.h2`
	font-family: 'Futura';
	font-size: 18px;
	font-weight: 700;
	text-transform: uppercase;

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		font-size: 20px;
	}
`;

export const TypoHeadline3 = styled.h3`
	font-size: 16px;
	font-weight: 700;

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		font-size: 18px;
	}
`;

export const TypoHeadline4 = styled.h4`
	font-size: 14px;
	font-weight: 700;

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		font-size: 17px;
	}
`;

export const TypoHeadline5 = styled.h5`
	font-size: 12px;
	font-weight: 700;

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		font-size: 14px;
	}
`;

export const TypoHeadline6 = styled.h6`
	font-size: 10px;
	font-weight: 700;

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		font-size: 12px;
	}
`;

export const TypoBody = styled.div`
	font-size: 16px;
	font-weight: 700;

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		font-size: 18px;
	}
`;

export const PriceView = styled.div`
	font-family: 'Arial';
	font-size: 20px;
	font-weight: 700;
`;

export const PositionView = styled.div`
	font-family: 'Arial';
	font-size: 20px;
	flex: 1 1 0px;
	font-weight: 700;
	text-align: left;
`;

export const PositionHeadLine = styled.div`
	font-family: 'Arial';
	font-size: 20px;
	flex: 1 1 0px;
	font-weight: 400;
`;
