import {
	createContext,
	Dispatch,
	FC,
	SetStateAction,
	Suspense,
	useState,
} from 'react';
import { Routes, Route } from 'react-router-dom';
import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';
import styled, { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import { notLoggedRoutes, routes } from './router/routes';
import { Spinner } from './components/Spinner/Spinner';
import { IMessagePageParams } from './pages/MessagePage/MessagePage';
import { TAuthorize, useInit } from './hooks/useInit';
import { LANG } from './models/ILang';
import { ShoppingCart } from './components/ShoppingCart/ShoppingCart';
import { StageIndicatorMessage } from './components/StageIndicatorMessage/StageIndicatorMessage';
import { CookieBanner } from './components/CookieBanner/CookieBanner';
import { Modal, TModalState } from './components/Modal/Modal';
import { TypoHeadline2 } from './components/styleguide/typography';
import { IAppSettings } from './graphql/queries/appSettings';
import AppSettingsProvider from './components/AppSettingsProvider/AppSettingsProvider';

const PageView = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
`;

const ContentView = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	padding-bottom: 90px;
`;

export type CookieMap = {
	has_catalogs: boolean;
	country_code: string;
	is_sap_customer: boolean;
	has_internal_distributors: boolean;
};

export enum LOCAL_STORAGE_ENTRIES {
	mvt_auth_token = 'mvt_auth_token',
}

export enum COOKIE_ENTRIES {
	access_token = 'access_token',
	has_catalogs = 'has_catalogs',
	country_code = 'country_code',
	is_sap_customer = 'is_sap_customer',
	session_lifetime = 'session_lifetime',
	has_internal_distributors = 'has_internal_distributors',
}

export enum ALLOWED_COUNTRY_CODES {
	germany = 'DE',
	austria = 'AT',
	switzerland = 'CH',
}

interface IAppContext {
	isLogged: boolean;
	logout: () => void;
	navigateToMessagePage: (lang: LANG, params: IMessagePageParams) => void;
	authorize: TAuthorize;
	openedCart: boolean;
	setOpenedCart: Dispatch<SetStateAction<boolean>>;
	setModal: Dispatch<SetStateAction<TModalState>>;
	requestedDeliveryDate: {
		value: string;
		setRequestedDeliveryDate: Dispatch<SetStateAction<string>>;
	};
}

// used for sharing runtime info
export const AppContext = createContext<IAppContext>({} as IAppContext);

// contains settings that are fetched from the magento backend
export const AppSettingsContext = createContext<IAppSettings | null>(null);

const App: FC = () => {
	const [modal, setModal] = useState<TModalState>({
		type: '',
		messages: [],
	});

	const [openedCart, setOpenedCart] = useState(false);

	const [requestedDeliveryDate, setRequestedDeliveryDate] =
		useState<string>('');

	// inital processes (authorizations, check tokens...):
	const {
		isLoading,
		isLogged,
		authorize,
		logout,
		navigateToMessagePage,
		changeLanguage,
	} = useInit();

	const pageSpinner = (
		<PageView>
			<ContentView>
				<Spinner />
			</ContentView>
		</PageView>
	);

	if (isLoading) return pageSpinner;

	return (
		<StyleSheetManager shouldForwardProp={isPropValid} enableVendorPrefixes>
			<AppContext.Provider
				value={{
					isLogged,
					authorize,
					logout,
					navigateToMessagePage,
					openedCart,
					setOpenedCart,
					setModal,
					requestedDeliveryDate: {
						value: requestedDeliveryDate,
						setRequestedDeliveryDate,
					},
				}}
			>
				<AppSettingsProvider>
					<Suspense fallback={pageSpinner}>
						<PageView className="App">
							<StageIndicatorMessage />

							<Header changeLang={lang => changeLanguage(lang)} />
							<ContentView>
								<Routes>
									{isLogged
										? routes.map(route => (
												<Route
													key={route.path}
													path={route.path}
													element={<route.element />}
												/>
											))
										: notLoggedRoutes.map(route => (
												<Route
													key={route.path}
													path={route.path}
													element={<route.element />}
												/>
											))}
								</Routes>
							</ContentView>
							<Footer />

							<ShoppingCart opened={openedCart} />

							<CookieBanner />

							<Modal
								type={modal.type}
								show={!!modal.messages.length}
								onClose={() =>
									setModal({
										type: '',
										messages: [],
									})
								}
							>
								<ul
									style={{
										paddingLeft: 25,
										paddingBottom: 0,
									}}
								>
									{modal?.messages.map((mm, i) => (
										<li
											key={i}
											style={{ marginBottom: 15 }}
										>
											<TypoHeadline2>{mm}</TypoHeadline2>
										</li>
									))}
								</ul>
							</Modal>
						</PageView>
					</Suspense>
				</AppSettingsProvider>
			</AppContext.Provider>
		</StyleSheetManager>
	);
};

export default App;
