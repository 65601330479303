import { CSSProperties, FC, ReactNode, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ContainerView } from '../Container/Container.view';
import { COLORS } from '../styleguide/colors';
import { GRID_BREAKPOINTS } from '../styleguide/variables';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success-order.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';

interface IModalLayoutViewProps {
	show: boolean;
}

export const ModalLayoutView = styled(ContainerView)<IModalLayoutViewProps>`
	padding-top: 40px;
	max-width: 100vw;
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 20;
	opacity: ${({ show }) => {
		const body = document.body;

		if (show) {
			const getScrollWidth = (): number => {
				const div = document.createElement('div');

				div.style.cssText = `
					width: 50px;
					height: 50px;
					overflow-y: scroll;
					visability: hidden;
				`;

				body.append(div);

				const scrollWidth = div.offsetWidth - div.clientWidth;

				div.remove();

				return scrollWidth;
			};

			const scrollWidth = getScrollWidth();

			body.style.overflow = 'hidden';

			body.style.paddingRight = `${scrollWidth}px`;

			return 1;
		} else {
			body.style.overflow = '';

			body.style.paddingRight = '';

			return 0;
		}
	}};
	pointer-events: ${({ show }) => (show ? 'all' : 'none')};
	overflow: auto;
	animation-duration: 0.3s;
	animation-name: ${({ show }) => (show ? 'fadeIn' : '')};

	&:is(.modal_centered) {
		display: flex;
		align-items: center;
	}

	&:is(.bottomed) {
		display: flex;
		align-items: flex-end;
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

const ModalContainerView = styled.div`
	margin: 0 auto;
	margin-bottom: 40px;
	padding-top: 35px;
	max-width: 100%;
	background-color: ${COLORS.white};
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		width: 85%;
		max-width: 900px;
	}

	@media (min-width: ${GRID_BREAKPOINTS.$md}px) {
		width: 70%;
	}

	@media (min-width: ${GRID_BREAKPOINTS.$lg}px) {
		width: 60%;
	}

	.modal__icon-wrapper {
		margin: 0 auto;
		width: fit-content;
		margin-bottom: 25px;
	}
`;

const ModalCloseView = styled.div`
	margin: 0 15px 15px auto;
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	/* border: 2px solid ${COLORS.primaryColor}; */
	border-radius: 100%;
	cursor: pointer;

	@media (min-width: ${GRID_BREAKPOINTS.$md}px) {
		margin-right: 35px;
	}

	& > svg {
		fill: ${COLORS.primaryColor};
	}
`;

const ModalContentView = styled.div`
	padding: 20px;
	padding-bottom: 50px;
`;

type TModalTypes = 'success' | 'warning' | 'error' | '';

export type TModalState = {
	type: TModalTypes;
	messages: string[];
};

interface IModalProps {
	children: ReactNode;
	type?: TModalTypes;
	show: boolean;
	onClose?: () => void;
	isShoppingCart?: boolean;
	style?: {
		modalLayout?: CSSProperties;
		modalContainer?: CSSProperties;
		modalClose?: CSSProperties;
		modelContentContainer?: CSSProperties;
	};
}

export const Modal: FC<IModalProps> = ({
	children,
	type,
	show = false,
	onClose,
	isShoppingCart,
	style,
}) => {
	const modalRef = useRef<HTMLDivElement>(null);

	const modalContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const modalHeight = modalRef.current?.clientHeight;

		const containerHeight =
			modalContainerRef.current?.getBoundingClientRect().height;

		!isShoppingCart &&
			modalHeight &&
			containerHeight &&
			containerHeight + 35 * 2 <= modalHeight &&
			modalRef.current.classList.add('modal_centered');
	}, [show]);

	const getIcon = (): JSX.Element | null => {
		switch (type) {
			case 'success':
				return <SuccessIcon fill={COLORS.secondaryColor} />;

			case 'warning':
				return (
					<WarningIcon
						fill={COLORS.secondaryColor}
						width="80px"
						height="80px"
					/>
				);

			case 'error':
				return <ErrorIcon fill={COLORS.errorColor} />;

			default:
				return null;
		}
	};

	return (
		<ModalLayoutView
			ref={modalRef}
			className="modal"
			show={show}
			onClick={onClose}
			style={style?.modalLayout}
		>
			<ModalContainerView
				ref={modalContainerRef}
				className="modal__container"
				onClick={e => e.stopPropagation()}
				style={style?.modalContainer}
			>
				<ModalCloseView
					className="modal__close"
					onClick={onClose}
					style={style?.modalClose}
				>
					<CloseIcon />
				</ModalCloseView>
				<ModalContentView
					className="modal__content"
					style={style?.modelContentContainer}
				>
					{type && (
						<div className="modal__icon-wrapper">{getIcon()}</div>
					)}
					{children}
				</ModalContentView>
			</ModalContainerView>
		</ModalLayoutView>
	);
};
