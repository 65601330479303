import { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import loading from '../../assets/images/loading.webp';

const SpinnerContainerView = styled.div`
	min-height: 100%;
	min-width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	& > div {
		& > img {
			max-width: 100%;
			max-height: 100%;
		}
	}
`;

interface ISpinnerProps {
	style?: CSSProperties;
}

export const Spinner: FC<ISpinnerProps> = ({ style }) => {
	return (
		<SpinnerContainerView>
			<div style={{ width: 100, ...style }}>
				<img src={loading} alt="loading..." />
			</div>
		</SpinnerContainerView>
	);
};
