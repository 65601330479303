import { useMutation } from '@apollo/client';
import { MESSAGE_TYPE } from '../pages/MessagePage/MessagePage';
import {
	ICreateEmptyCartMutationResponse,
	CREATE_EMPTY_CART_MUTATION,
} from '../graphql/mutations/cart';
import { useContext, useState } from 'react';
import { AppContext } from '../App';
import { useTranslation } from 'react-i18next';
import { LANG } from '../models/ILang';

interface IUseCartIdReturn {
	loading: boolean;
	getCartId: (
		cartId: string | undefined,
		// callback: (id: string) => void,
	) => Promise<string>;
}

export const useCartId = (): IUseCartIdReturn => {
	const { navigateToMessagePage } = useContext(AppContext);

	const { i18n } = useTranslation();

	const current_lang = i18n.language as LANG;

	const [loading, setLoading] = useState(false);

	const [createEmptyCartMutation] =
		useMutation<ICreateEmptyCartMutationResponse>(
			CREATE_EMPTY_CART_MUTATION,
			{
				onError(error) {
					console.error('createEmptyCart error:>>', error);

					setLoading(false);

					navigateToMessagePage(current_lang, {
						type: MESSAGE_TYPE.error,
						title: error.message,
						btnDescr: 'Product',
						redirectUrl: location.pathname,
					});
				},
			},
		);

	const getCartId = async (cartId: string | undefined): Promise<string> => {
		setLoading(true);

		if (!cartId) {
			const res = await createEmptyCartMutation();

			const id = res.data?.createEmptyCart;

			return new Promise((resolve, reject) => {
				setLoading(false);

				if (id) {
					resolve(id);
				} else {
					const error = new Error('Could not get cart id :(');

					reject(error);

					console.error(error);
				}
			});
		}

		return new Promise(resolve => {
			setLoading(false);

			resolve(cartId);
		});
	};

	return {
		loading,
		getCartId,
	};
};
