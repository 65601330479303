import { FC, useContext, useState } from 'react';
import { Button } from '../../../../../components/Buttons/Buttons';
import {
	CountryInput,
	ICountry,
} from '../../../../../components/CountryInput/CountryInput';
import { Input } from '../../../../../components/Input/Input';
import { ReactComponent as EditIcon } from '../../../../../assets/icons/edit.svg';
import styled from 'styled-components';
import { IBillingForms, TAddressForm } from '../../../../../models/IUser';
import { isInvalidEmail } from '../../../../../utils/helpers';
import { COLORS } from '../../../../../components/styleguide/colors';
import { useTranslation } from 'react-i18next';
import { OverviewPageContext } from '../../../OverviewPage';
// import { useTranslation } from '../../../../../hooks/useTranslation';

const OverviewAddressFormView = styled.div`
	& > div {
		display: grid;
		gap: 10px;
		grid-template-columns: 1fr min-content;

		.address-result {
			font-family: Arial, Helvetica, sans-serif;
			font-weight: 400;
		}

		& > svg {
			cursor: pointer;

			& > path {
				fill: ${COLORS.primaryColor};
			}
		}
	}

	& > form {
		display: grid;
		gap: 10px;

		& > button {
			margin-top: 10px;
		}
	}
`;

type TFormInputs = Record<
	keyof Pick<
		TAddressForm,
		'company' | 'street' | 'postcode' | 'city' | 'country_code' | 'email'
	>,
	string
>;

interface IOverviewOfferFormProps {
	formType: keyof IBillingForms;
	countries: ICountry[];
}

export const OverviewOfferForm: FC<IOverviewOfferFormProps> = ({
	formType,
	countries,
}) => {
	const { t } = useTranslation();

	const { billing, setBilling, email } = useContext(OverviewPageContext);

	const [isEdit, setIsEdit] = useState(false);

	const [form, setForm] = useState<TFormInputs>({
		company: billing[formType].company,
		street: billing[formType].street,
		postcode: billing[formType].postcode,
		city: billing[formType].city,
		country_code: billing[formType].country_code,
		email: email ?? '',
	});

	const [errors, setErrors] = useState<string[]>([]);

	const getInputs = (): string[] => Object.keys(form);

	const handleInput = (value: string, input: keyof TFormInputs): void => {
		if (input) {
			setForm(state => ({ ...state, [input]: value }));

			setErrors(errs => errs.filter(r => r !== input));
		}
	};

	const validateForm = (): string[] => {
		const err: string[] = [];

		const inputs = getInputs();

		inputs.map(input => {
			input !== 'email' &&
				!form[input as keyof TFormInputs] &&
				err.push(input);

			input === 'email' &&
				!!form.email &&
				isInvalidEmail(form[input]) &&
				err.push(input);
		});

		setErrors(err);

		return err;
	};

	const handleSaveButton = (): void => {
		if (!validateForm().length) {
			const newAddress: TAddressForm = { ...billing[formType], ...form };

			setBilling(b => ({
				...b,
				[formType]: newAddress,
			}));

			setIsEdit(edit => !edit);
		}
	};

	return (
		<OverviewAddressFormView className="overview__offer-address">
			{isEdit ? (
				<form>
					<Input
						required
						label={t('billing_form_input_labels_company_typo')}
						value={form.company}
						onChange={value => handleInput(value, 'company')}
						isInvalid={errors.includes('company')}
					/>
					<Input
						required
						label={t('billing_form_input_labels_address_typo')}
						value={form.street}
						onChange={value => handleInput(value, 'street')}
						isInvalid={errors.includes('street')}
					/>
					<Input
						required
						label={t('billing_form_input_labels_postcode_typo')}
						value={form.postcode}
						onChange={value => handleInput(value, 'postcode')}
						isInvalid={errors.includes('postcode')}
					/>
					<Input
						required
						label={t('billing_form_input_labels_city_typo')}
						value={form.city}
						onChange={value => handleInput(value, 'city')}
						isInvalid={errors.includes('city')}
					/>
					<Input
						label={t('Email')}
						value={form.email}
						onChange={value => handleInput(value, 'email')}
						isInvalid={errors.includes('email')}
					/>
					<CountryInput
						required
						label={t('billing_form_input_labels_country_typo')}
						countryList={countries}
						selected={form.country_code}
						onChange={country =>
							handleInput(
								country.two_letter_abbreviation,
								'country_code',
							)
						}
					/>
					<Button primary onClick={() => handleSaveButton()}>
						{t('save_typo')}
					</Button>
				</form>
			) : (
				<div>
					<div className="address-result">
						{billing[formType].company},
						<br />
						{billing[formType].street},
						<br />
						{billing[formType].postcode} {billing[formType].city}
						<br />
						{billing[formType].country_code &&
							countries.find(
								fc =>
									fc.two_letter_abbreviation ===
									billing[formType].country_code,
							)?.full_name_locale}
						<br />
						{billing[formType].email}
					</div>
					<EditIcon onClick={() => setIsEdit(edit => !edit)} />
				</div>
			)}
		</OverviewAddressFormView>
	);
};
