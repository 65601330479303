import { FC } from 'react';
import { COLORS } from '../styleguide/colors';
import { ContainerView } from '../Container/Container.view';

export const StageIndicatorMessage: FC = () => {
	const backgroundColor = process.env.REACT_APP_TOP_MESSAGE_BG_COLOR
		? process.env.REACT_APP_TOP_MESSAGE_BG_COLOR
		: COLORS.primaryColor;

	const color =
		backgroundColor === COLORS.primaryColor
			? COLORS.white
			: COLORS.primaryColor;

	if (process.env.REACT_APP_TOP_MESSAGE)
		return (
			<div
				style={{
					padding: '5px 0',
					backgroundColor,
					fontFamily: 'Arial',
					color,
					textAlign: 'center',
				}}
			>
				<ContainerView>
					{process.env.REACT_APP_TOP_MESSAGE}
				</ContainerView>
			</div>
		);

	return null;
};
