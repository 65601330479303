import { t } from 'i18next';
import { FC, useContext } from 'react';
import { Input } from '../Input/Input';
import { MVTHeadline2 } from '../styleguide/typography';
import { Switch } from '../Switch/Switch';
import { AppContext } from '../../App';

interface IRequestDeliveryDateProps {
	checked: boolean;
	onChange: () => void;
}

export const RequestDeliveryDate: FC<IRequestDeliveryDateProps> = ({
	checked,
	onChange,
}) => {
	const { requestedDeliveryDate } = useContext(AppContext);

	return (
		<div className="requested-delivery-date wunschtermin">
			<MVTHeadline2>
				{t('order_overview_page_offer_desired_date_headline')}
			</MVTHeadline2>
			<Switch checked={checked} onChange={onChange}>
				{t('order_overview_page_offer_desired_date_description')}
			</Switch>
			{checked && (
				<div
					className="datepicker-wrapper"
					style={{ width: 'min-content' }}
				>
					<Input
						className="datepicker__input"
						type="date"
						value={requestedDeliveryDate.value}
						onChange={v =>
							requestedDeliveryDate.setRequestedDeliveryDate(v)
						}
					/>
				</div>
			)}
		</div>
	);
};
