import styled from 'styled-components';
import { GRID_BREAKPOINTS } from '../styleguide/variables';

export const ContainerView = styled.div`
	padding: 0 15px;
	width: 100%;
	max-width: 1290px;
	margin: 0 auto;

	@media (min-width: ${GRID_BREAKPOINTS.$xs}px) {
		padding: 0 20px;
		max-width: 1300px;
	}

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		padding: 0 30px;
		max-width: 1320px;
	}
`;
